/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import './KesariSelectReview.css';
import {
  Avatar,
  Box,
  Button,
  CardHeader,
  Grid,
  Paper,
  Rating,
  styled,
  Typography
} from '@mui/material';
import { Masonry } from '@mui/lab';
import reviwer from '../../../assets/Home/kesari_review/reviwer.webp';
import { useNavigate } from 'react-router-dom';
import { API_URL, BASE_URL } from '../../../APIs/endpoint';
import Loader from '../../Loader/loader';
import { api } from '../../../APIs/api';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'left',
  color: theme.palette.text.secondary
}));

const KesariSelectReview = () => {
  const [reviewData, setReviewData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const getReviewListApi = async () => {
    try {
      setIsLoader(true);
      const reviewRes = await api.get(`${BASE_URL}${API_URL.REVIEW_LIST}`);
      if (reviewRes.status) {
        const updatedReviewData = reviewRes.data.map((item) => ({
          ...item,
          img: reviwer,
          review_rating: parseFloat(item.review_rating)
        }));
        setReviewData(updatedReviewData);
      } else {
        setError(reviewRes.data.message || 'An error occurred');
      }
      setIsLoader(false);
    } catch (err) {
      setError(err.message || 'An error occurred');
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getReviewListApi();
  }, []);

  return (
    <>
      <div className="kesariSelect_main">
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Box
            sx={{
              width: '100%',
              minHeight: 393,
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Grid
              container
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <Grid
                xs={12}
                sm={12}
                md={8}
                lg={11}
                xl={12}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}>
                <p className="kesariSelect_main_heading">Kesari Select Reviews</p>
                <div className="effect-kesari-select-review-container">
                  <div
                    style={{
                      display: 'flex',
                      zIndex: '1',
                      overflow: 'hidden',
                      minWidth: '80vw'
                    }}>
                    {isLoader ? (
                      <Grid container item xs={12} justifyContent="center" sx={{ display: 'flex' }}>
                        <Loader />
                      </Grid>
                    ) : reviewData.length === 0 ? (
                      <Box textAlign="center">
                        <Typography variant="h5" className="erp-no-data-found-text">
                          'Coming Soon...!'
                        </Typography>
                      </Box>
                    ) : (
                      <Masonry
                        columns={3}
                        spacing={2}
                        defaultHeight={'auto'}
                        defaultColumns={3}
                        defaultSpacing={1}
                        sx={{
                          zIndex: '1 !important'
                        }}>
                        {reviewData?.map((item, index) => (
                          <Item
                            key={index}
                            sx={{
                              padding: '33px 44px',
                              borderRadius: '16px',
                              boxShadow: '3px 3px 20px 0px rgba(0, 0, 0, 0.08)'
                            }}>
                            <Rating
                              name={`customized-${index}`}
                              value={item.review_rating}
                              precision={0.5}
                              max={5}
                              readOnly
                              sx={{ textAlign: 'left' }}
                            />
                            <p className="kesariSelect_card" color="text.secondary">
                              {item.review_description}
                            </p>
                            <CardHeader
                              sx={{ padding: '8px 0px' }}
                              avatar={
                                <Avatar
                                  sx={{ width: '100%', textAlign: 'left' }}
                                  aria-label="recipe"
                                  src={item.img}>
                                  R
                                </Avatar>
                              }
                              title={item.review_username}
                              subheader={new Date(item.review_date).toLocaleDateString('en-US', {
                                month: 'short',
                                year: 'numeric'
                              })}
                            />
                          </Item>
                        ))}
                      </Masonry>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            paddingTop: '30px',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <div className="kesariSelect_btn_container">
            <Button className="kesariSelect_typo_btn" onClick={() => navigate('/product-review')}>
              <div
                className="kesariSelect_explore_btn_inner"
                style={{
                  display: 'flex',
                  gap: '12px',
                  alignItems: 'center'
                }}>
                <span className="seeMoreReviews">See More Reviews</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <svg
                    width="13"
                    height="22"
                    viewBox="0 0 13 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.9648 11L2.36408 22L0.464783 20.0292L9.21037 11L0.508952 1.97088L2.40825 4.95911e-05L12.9648 11Z"
                      fill="url(#paint0_linear_1152_47198)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1152_47198"
                        x1="0.464783"
                        y1="11"
                        x2="12.9648"
                        y2="11"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="black" />
                        <stop offset="1" stopColor="black" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <svg
                    width="13"
                    height="22"
                    viewBox="0 0 13 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.9648 11L2.36408 22L0.464783 20.0292L9.21037 11L0.508952 1.97088L2.40825 4.95911e-05L12.9648 11Z"
                      fill="url(#paint0_linear_1152_47198)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1152_47198"
                        x1="0.464783"
                        y1="11"
                        x2="12.9648"
                        y2="11"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="black" />
                        <stop offset="1" stopColor="black" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
};

export default KesariSelectReview;
