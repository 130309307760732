import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';

const PackageCardSkeleton = () => (
  <Box
    width={{ xs: '100%', md: '420px' }}
    boxShadow="10px 10px 30px 0px #37444A29"
    backgroundColor="#FFFFFF"
    borderRadius="10px"
    overflow="hidden">
    <Box width={{ xs: '100%', md: 420 }} height={170} position="relative">
      <Skeleton variant="rectangular" width="100%" height="100%" />
    </Box>
    <Box display="grid">
      <Box px={{ xs: 2.5, lg: '25px' }} pb={{ xs: 2.5, lg: 3 }} pt={{ xs: '18px', md: '13px' }}>
        <Typography
          fontWeight={700}
          fontFamily="inherit"
          fontSize={{ xs: 14, md: 20 }}
          lineHeight={{ xs: '22px', md: '32px' }}>
          <Skeleton variant="text" width="80%" height={30} />
        </Typography>

        <Box display="flex" alignItems="center" gap="30px" pt="22px">
          <Box display="flex" alignItems="center" gap="6px">
            <Skeleton variant="text" width={50} height={20} />
          </Box>
          <Box display="flex" alignItems="center" gap="6px">
            <Skeleton variant="text" width={50} height={20} />
          </Box>
          <Box display="flex" alignItems="center" gap="6px">
            <Skeleton variant="text" width={50} height={20} />
          </Box>
        </Box>
      </Box>

      <Box bgcolor="#f9f9fb" padding={3} minWidth="168px">
        <Skeleton variant="text" width="100%" height={30} />
        <Skeleton variant="text" width="60%" height={30} style={{ marginTop: '10px' }} />
      </Box>

      <Box padding={3}>
        <Typography fontWeight={400} fontSize={14} lineHeight={{ xs: '22px', md: '32px' }}>
          <Skeleton variant="text" width="40%" height={30} />
        </Typography>

        <Typography
          display="flex"
          fontWeight={700}
          fontFamily="inherit"
          fontSize={{ xs: 14, md: 20 }}
          lineHeight={{ xs: '22px', md: '32px' }}
          sx={{ color: '#27306B' }}>
          <Skeleton variant="text" width="80%" height={30} />
        </Typography>
      </Box>

      <Box className="btn-container" gap="10px" mt="20px" display="flex" flexDirection="row">
        <Skeleton variant="rectangular" width={180} height={46} />
        <Skeleton variant="rectangular" width={180} height={46} />
      </Box>
    </Box>
  </Box>
);

export default PackageCardSkeleton;
