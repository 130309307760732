import { configureStore } from '@reduxjs/toolkit';
import ratinCountsReducer from '../slices/ratingCountsSlice';
import packageListReducer from '../slices/packageListingSlice';
import metaDataListReducer from '../slices/metaDataSlice';

export default configureStore({
  reducer: {
    ratingCounts: ratinCountsReducer,
    packageList: packageListReducer,
    metaDataList: metaDataListReducer
  }
});
