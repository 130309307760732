import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Divider, Grid, Skeleton, Typography, useTheme, useMediaQuery } from '@mui/material';
import { FiberManualRecordRounded } from '@mui/icons-material';
import { api } from '../../APIs/api';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import kesariLogo from '../../assets/Home/Footer/Footer_logo-new.webp';
import worldMap from '../../assets/Home/Every_Step/World_Map.webp';
// import QrCodeImg from '../../assets/Home/Footer/qrcode.webp';
// import SubscribeModal from './SubscribeModal';
import { capitalizeFirstLetter } from '../../Helper/global';
import SocialMediaComponent from './SocialMediaComponent';
import FooterUsefulLinksComponent from './FooterLinksComponent';

const Content = ({ title, para, isLoader }) => (
  <Box textAlign={{ md: 'justify' }}>
    <Typography
      color="#FFFFFF"
      fontFamily="inherit"
      fontWeight={700}
      fontSize={{ xs: 15, md: 18 }}
      lineHeight={{ xs: '14px', md: '28px' }}
      mt={1}>
      {isLoader ? <Skeleton variant="text" width="100%" height={30} /> : title}
    </Typography>
    <Typography
      color="#FFFFFFCC"
      fontFamily="inherit"
      fontWeight={300}
      fontSize={{ xs: 12, md: 14 }}
      lineHeight={{ xs: '16px', md: '22px' }}
      mt={{ xs: 1.5, md: '18px' }}>
      {isLoader ? (
        <Skeleton variant="text" width="100%" height={150} />
      ) : (
        <>
          {para.map((item, index) => (
            <React.Fragment key={item.title}>
              <Link
                to={item.url}
                style={{
                  color: '#FFFFFF',
                  textDecoration: 'none'
                }}
                onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}>
                {capitalizeFirstLetter(item.title)}
              </Link>
              {index < para.length - 1 && (
                <FiberManualRecordRounded sx={{ fontSize: '0.30rem', ml: '5px', mr: '5px' }} />
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </Typography>
  </Box>
);

const transformFooterData = (footerData) =>
  Object.keys(footerData).map((key) => ({
    title: key,
    para: footerData[key]
  }));

const divideFooterData = (data, parts) => {
  const partSize = Math.ceil(data.length / parts);
  const result = [];
  for (let i = 0; i < parts; i++) {
    result.push(data.slice(i * partSize, (i + 1) * partSize));
  }
  return result;
};

const BaseFooter = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [footerMenuLength, setFooterMenuLength] = useState(0);
  const [footerData, setFooterData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState('');

  const getFoortMenuApi = async () => {
    try {
      setIsLoader(true);
      const footerMenuRes = await api.get(`${BASE_URL}${API_URL.FOOTER_MENU}`);
      const { data, message, status } = footerMenuRes;
      if (status) {
        setFooterMenuLength(Object.keys(data?.Footer).length);
        const transformedData = transformFooterData(data?.Footer);
        setFooterData(divideFooterData(transformedData, 2));
      } else {
        setError(message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getFoortMenuApi();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: '#27306B',
        pt: { xs: '25px', md: '60px' },
        position: 'relative',
        overflow: 'hidden',
        pb: footerMenuLength > 0 ? { xs: '120px', md: '180px' } : { xs: '80px', md: '100px' }
      }}>
      <Box
        sx={{
          maxWidth: { md: '1100px' },
          mx: 'auto',
          px: { xs: '27px', md: 0 },
          py: { xs: '21px', md: 0 }
        }}>
        <Grid container spacing={2} height="auto" mb={{ xs: 2, md: 4 }} px={{ xs: 5 }}>
          <Grid className="Hiren" item xs={12} md={4}>
            <Typography variant="h6" color="white" fontFamily="gothamBold" mb={2}>
              Get In Touch
            </Typography>
            <Typography variant="body1" fontFamily="inherit" color="white">
              3105, Kohinoor Square,
            </Typography>
            <Typography variant="body1" fontFamily="inherit" color="white">
              31st Floor, N.C.Kelkar Marg,
            </Typography>
            <Typography variant="body1" fontFamily="inherit" color="white">
              Shivaji Park, Mumbai,
            </Typography>
            <Typography variant="body1" fontFamily="inherit" color="white">
              Maharashtra 400028.
            </Typography>
            <Typography
              variant="h6"
              fontFamily="inherit"
              color="white"
              pt={2}
              display="flex"
              alignItems="baseline">
              Phone:
              <Typography
                component="a"
                href="tel:18002669080"
                variant="body1"
                fontFamily="inherit"
                color="white"
                ml={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'color 0.3s ease, textDecoration 0.3s ease',
                  '&:hover': {
                    color: '#FFD700',
                    textDecoration: 'underline'
                  }
                }}>
                1800 266 9080
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              fontFamily="inherit"
              color="white"
              display="flex"
              alignItems="baseline"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}>
              WhatsApp:
              <Typography
                component="a"
                href="https://wa.me/918657758850"
                target="_blank"
                variant="body1"
                fontFamily="inherit"
                color="white"
                ml={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'color 0.3s ease, textDecoration 0.3s ease',
                  '&:hover': {
                    color: '#FFD700',
                    textDecoration: 'underline'
                  },
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}>
                +91 86577 58850
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              fontFamily="inherit"
              color="white"
              display="flex"
              alignItems="baseline">
              Email:
              <Typography
                component="a"
                href="mailto:holiday@kesariselect.in"
                variant="body1"
                fontFamily="inherit"
                color="white"
                ml={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'color 0.3s ease, textDecoration 0.3s ease',
                  '&:hover': {
                    color: '#FFD700',
                    textDecoration: 'underline'
                  }
                }}>
                holiday@kesariselect.in
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent={isMobileScreen ? 'flex-start' : 'center'}
            alignItems="flex-start">
            {/* <SubscribeModal /> */}
            <FooterUsefulLinksComponent />
          </Grid>
          <Grid className="Hiren" item xs={12} md={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              }}>
              <Box component="img" sx={{ width: '258px', height: '113px' }} src={kesariLogo} />
              <Box
                component="img"
                src={worldMap}
                sx={{ position: 'absolute', top: 0, height: '163px' }}
              />
              <SocialMediaComponent />
              {/* <Box
                component="img"
                src={QrCodeImg}
                sx={{ marginTop: 2, width: '20%', height: '15%' }}
              /> */}
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ mt: { xs: 4.5 }, bgcolor: '#FFFFFF1A' }} />
        <Grid container spacing={2} height="auto" mb={{ xs: 2, md: 4 }}>
          <>
            {footerData.length > 0 &&
              footerData.flat().map((data, id) => (
                <Grid item xs={12} md={6} key={id}>
                  <Box display="grid" gap={{ xs: 3.5, md: '58px' }} px={{ md: '20px' }}>
                    <Content para={data.para} title={data.title} isLoader={isLoader} />
                  </Box>
                </Grid>
              ))}
          </>
          {error && (
            <Grid item xs={12} md={6}>
              <Typography>{error}</Typography>
            </Grid>
          )}
        </Grid>

        <Typography
          sx={{
            fontSize: '12px',
            fontFamily: 'inherit',
            color: '#FFFFFF99',
            textAlign: 'center',
            mt: 3
          }}>
          &copy; 1983-{new Date().getFullYear()} Kesari Tours Pvt Ltd. Rights reserved.
        </Typography>

        {/* <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            py: '20px',
            bgcolor: '#27306B',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: '20px',
            zIndex: 4,
            backgroundColor: 'transparent'
          }}>
          <Typography variant="body2" color="white">
            <a
              href="https://www.kesariselect.in/termsncondition.html"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#FFFFFF',
                textDecoration: 'none',
                cursor: 'pointer'
              }}
              onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
              onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}>
              Terms & Conditions
            </a>
          </Typography>
        </Box> */}

        <Box
          sx={{
            position: 'absolute',
            bottom: -3.5,
            left: 0,
            right: 0,
            width: '100%',
            display: 'flex',
            zIndex: 3
          }}>
          {[...Array(3)].map((_, index) => (
            <Box
              key={index}
              component="img"
              src="/images/footerimg-1.svg"
              alt={`footerimage-${index + 1}`}
              sx={{
                width: '100%',
                height: 'auto',
                flex: 1
              }}
              loading="lazy"
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default BaseFooter;
