import NorthAmerica from '../assets/Home/Every_Step/northAmericaIcon.webp';
import SouthAmerica from '../assets/Home/Every_Step/south-america.webp';
import EuropeIcon from '../assets/Home/Every_Step/europe.webp';
import AfricaIcon from '../assets/Home/Every_Step/africa.webp';
import AsiaIcon from '../assets/Home/Every_Step/asia.webp';
import AustraliaIcon from '../assets/Home/Every_Step/australia.webp';
// import AntarcticaIcon from '../assets/Home/Every_Step/antarctica.webp';

export const internationalMapData = [
  { label: 'North America', icon: NorthAmerica, value: 0 },
  { label: 'South America', icon: SouthAmerica, value: 1 },
  { label: 'Europe', icon: EuropeIcon, value: 2 },
  { label: 'Africa', icon: AfricaIcon, value: 3 },
  { label: 'Asia', icon: AsiaIcon, value: 4 },
  { label: 'Australia', icon: AustraliaIcon, value: 5 }
  // { label: 'Antarctica', icon: AntarcticaIcon, value: 6 }
];

export const domesticMapData = [
  { label: 'North India', value: 0 },
  { label: 'East India', value: 1 },
  { label: 'West India', value: 2 },
  { label: 'South India', value: 3 }
];
