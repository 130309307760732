/* eslint-disable no-unused-vars */
import React, { useRef, useEffect } from 'react';
import './HomeSlider.css';
import { useNavigate } from 'react-router-dom';
import { Box, Skeleton } from '@mui/material';
import { PUBLIC_CMS_URL, IMAGE_DIR_PATH } from '../../../APIs/endpoint';
import Slider from 'react-slick';
import { getUrlAfterDomain } from '../../../Helper/global';

const HomeSliderComponent = ({ timeLineSliderData, isLoader }) => {
  let sliderRef1 = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const preloadImage = new Image();
    if (timeLineSliderData && timeLineSliderData.length > 0) {
      preloadImage.src = `${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.TIME_LINE_SLIDER_PATH}${timeLineSliderData[0].timeline_image}.webp`;
    }
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'style';
    link.href = './HomeSlider.css';
    link.onload = () => (link.rel = 'stylesheet');
    document.head.appendChild(link);
  }, [timeLineSliderData]);

  const settings = {
    dots: true,
    fade: false,
    infinite: true,
    arrows: true,
    autoplay: !isLoader,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Box
      className="slider-container"
      sx={{ maxHeight: { xs: 180, sm: 335, md: 382, lg: 650 }, content: '""' }}>
      {isLoader ? (
        <Skeleton
          variant="rectangular"
          sx={{
            width: { xs: '100%', sm: '100%', md: 1000, lg: 1500, xl: 1500 },
            height: { xs: 180, sm: 335, md: 382, lg: 650 }
          }}
        />
      ) : (
        <Slider className="parent_component" ref={(slider) => (sliderRef1 = slider)} {...settings}>
          {timeLineSliderData?.map((item, i) => (
            <Box
              key={i}
              className="img_section"
              onClick={() => navigate(getUrlAfterDomain(item.timeline_url))}
              sx={{ cursor: 'pointer' }}>
              <img
                loading={i === 0 ? 'eager' : 'lazy'}
                width={1500}
                height={570}
                srcSet={`
                ${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.TIME_LINE_SLIDER_PATH}${item.timeline_image}?w=300 300w,
                ${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.TIME_LINE_SLIDER_PATH}${item.timeline_image}?w=768 768w,
                ${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.TIME_LINE_SLIDER_PATH}${item.timeline_image}?w=1024 1024w
              `}
                sizes="(max-width: 600px) 300px, (max-width: 1024px) 768px, 1024px"
                src={`${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.TIME_LINE_SLIDER_PATH}${item.timeline_image}`}
                alt={`Timeline slide ${i + 1}`}
              />
            </Box>
          ))}
        </Slider>
      )}
    </Box>
  );
};

export default React.memo(HomeSliderComponent);
