/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Box,
  Paper,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  styled,
  Skeleton
} from '@mui/material';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IMAGE_DIR_PATH, PUBLIC_CMS_URL } from '../../../APIs/endpoint';
import { getUrlAfterDomain } from '../../../Helper/global';
import { useNavigate } from 'react-router-dom';

const StyledPaper = styled(({ isMobile, ...rest }) => <Paper {...rest} />)(({ isMobile }) => ({
  height: isMobile ? '100%' : 440,
  width: isMobile ? 231 : 280,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'transform 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94), box-shadow 0.8s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)'
  }
}));

const Image = styled(({ isMobile, ...rest }) => <img {...rest} />)(({ isMobile }) => ({
  height: isMobile ? 184 : 350,
  width: isMobile ? 134 : '100%',
  objectFit: 'cover',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  borderRadius: '12px'
}));

const UShapeContainer = styled(Box)(({ isMobile }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  height: isMobile ? 310 : 450
}));

const calculateTransform = (position) => {
  const transforms = [
    { zIndex: 1, transform: 'translateY(-70px) scale(0.85)' },
    { zIndex: 2, transform: 'translateY(-35px) scale(0.9)' },
    { zIndex: 3, transform: 'translateY(0) scale(1)' },
    { zIndex: 2, transform: 'translateY(-35px) scale(0.9)' },
    { zIndex: 1, transform: 'translateY(-70px) scale(0.85)' }
  ];

  return (
    transforms[position % 5] || {
      zIndex: 0,
      transform: 'translateY(0) scale(1)'
    }
  );
};

const UShapeCard = styled(Box)(({ position }) => ({
  ...calculateTransform(position),
  position: 'absolute',
  transition: 'transform 0.8s ease-in-out'
}));

const CustomCarousel = ({ tourData, isLoader }) => {
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const swiperRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleSlideChange = (swiper) => {
    setIsFirstSlide(swiper.isBeginning);
    setIsLastSlide(swiper.isEnd);
  };

  const slideToPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const slideToNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handleImageClick = (url) => {
    const resultUrl = getUrlAfterDomain(url);
    navigate(resultUrl);
  };

  return (
    <Box
      sx={{
        position: 'relative'
      }}>
      {!isMobile && (
        <>
          <Box
            sx={{
              position: 'absolute',
              top: -11,
              right: 8,
              width: 250,
              height: 450,
              zIndex: 20,
              background: 'linear-gradient(270deg, #FFFFFF 13.39%, rgba(255, 255, 255, 0) 100%)'
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: -8,
              left: -33,
              width: 250,
              height: 450,
              zIndex: 20,
              background: 'linear-gradient(90deg, #FFFFFF 13.39%, rgba(255, 255, 255, 0) 100%)'
            }}
          />
        </>
      )}
      {!isMobile && (
        <>
          <Button
            onClick={slideToPrev}
            sx={{
              position: 'absolute',
              top: 120,
              left: 80,
              zIndex: 20,
              transform: 'translateY(-50%)',
              bgcolor: '#0000004D',
              width: 70,
              height: 70,
              padding: 0,
              margin: 0,
              borderRadius: '100%',
              ':hover': {
                bgcolor: '#0000004D'
              }
            }}>
            <ChevronLeft sx={{ width: 40, height: 40, color: '#fff' }} />
          </Button>
          <Button
            onClick={slideToNext}
            sx={{
              position: 'absolute',
              top: 120,
              right: 80,
              zIndex: 999,
              transform: 'translateY(-50%)',
              bgcolor: '#0000004D',
              width: 70,
              height: 70,
              padding: 0,
              margin: 0,
              borderRadius: '100%',
              ':hover': {
                bgcolor: '#0000004D'
              }
            }}>
            <ChevronRight sx={{ width: 40, height: 40, color: '#fff' }} />
          </Button>
        </>
      )}
      {tourData && tourData.length > 0 ? (
        <Box
          component={Swiper}
          slidesPerView={isMobile ? 7 : 5}
          spaceBetween={isMobile ? 8 : 28}
          centeredSlides={true}
          loop={true}
          effect="coverflow"
          position="relative"
          pagination={{ clickable: true }}
          onSlideChange={handleSlideChange}
          ref={swiperRef}
          sx={{
            display: 'flex',
            height: { xs: '340px', md: 'auto' },
            justifyContent: 'center',
            overflow: 'hidden',
            pt: { xs: 0, md: 3.5 },
            '& .swiper-wrapper': {
              bgcolor: 'white',
              width: `${280 * 5}px`
            }
          }}>
          {tourData?.map((item, index) => (
            <SwiperSlide key={index} style={{ maxWidth: '280px' }}>
              {({ isActive, isPrev, isNext }) => {
                const position = isActive ? 2 : isPrev ? 1 : isNext ? 3 : 0;
                return (
                  <UShapeContainer key={index}>
                    <UShapeCard position={position}>
                      <StyledPaper isMobile={isMobile}>
                        {isLoader ? (
                          <Skeleton
                            variant="rectangular"
                            width={135}
                            height={185}
                            sx={{ borderRadius: 'inherit' }}
                          />
                        ) : (
                          <Image
                            isMobile={isMobile}
                            src={`${PUBLIC_CMS_URL}${IMAGE_DIR_PATH.READY_BOOK_TOUR_PATH}${item.readybooktour_image}`}
                            alt={item.destination}
                            onClick={() => handleImageClick(item?.readybooktour_url)}
                          />
                        )}
                        <Typography
                          mt={{ md: '21px' }}
                          fontFamily="gothamBook"
                          fontSize={22}
                          lineHeight={{ md: '30px' }}
                          color="#000000">
                          {isLoader ? (
                            <Skeleton variant="text" width={120} height={40} />
                          ) : (
                            <>{item.destination}</>
                          )}
                        </Typography>
                        <Typography mt={{ md: '6px' }} color="#00000080" fontFamily="gothamBook">
                          {isLoader ? (
                            <Skeleton variant="text" width={120} height={40} />
                          ) : (
                            <>{item.starting_price}</>
                          )}
                        </Typography>
                      </StyledPaper>
                    </UShapeCard>
                  </UShapeContainer>
                );
              }}
            </SwiperSlide>
          ))}
        </Box>
      ) : (
        <Box textAlign="center">
          <Typography variant="h5">Coming Soon...!</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CustomCarousel;
