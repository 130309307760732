import React, { useState } from 'react';
import './ForgotPasswordModal.css';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  TextField,
  Button,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ChevronLeftRounded, CloseRounded } from '@mui/icons-material';
import KesariSelectImg from '../../../../assets/Images/Kesari_Select.webp';

const ForgotPasswordModal = ({ open, handleClose, handleLogin }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = useState(0);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleBack = () => {
    if (value === 1) {
      setValue(0);
    }
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value.length > 50) {
      setError('Email cannot exceed 50 characters');
    } else {
      setError('');
    }
  };

  const handleSendResetLink = () => {
    if (!error) {
      // Implement your password reset logic here
      handleLogin();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        disablebackdropclick="true"
        fullScreen={isMobile}
        aria-labelledby="forgot-pass-dialog-title"
        aria-describedby="forgot-pass-dialog-description"
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : 'auto',
            maxWidth: isMobile ? '100%' : '600px',
            margin: isMobile ? '0' : 'auto',
            marginTop: isMobile ? '90%' : '5%',
            height: isMobile ? 'calc(100% - 20%)' : 'auto',
            maxHeight: isMobile ? 'calc(100% - 40%)' : 'auto'
          }
        }}>
        <form onSubmit={handleSendResetLink}>
          <DialogTitle id="forgot-pass-modal">
            <Box className="forgot-pass-iconAndImageStyles">
              <ChevronLeftRounded
                onClick={handleBack}
                sx={{
                  cursor: value === 1 ? 'pointer' : 'default',
                  color: value === 1 ? 'inherit' : 'grey'
                }}
              />
              <Box mx="auto" display="flex">
                <img className="imageStyles" src={KesariSelectImg} alt="Image" />
              </Box>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ color: (theme) => theme.palette.grey[500] }}>
                <CloseRounded />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent className="forgot-pass-modal-ui">
            <Box mb={2} textAlign="center">
              <Typography className="welcom-text">Welcome</Typography>
              <Typography
                className="sub-title-text"
                mx="auto"
                component="span"
                display="inline-block"
                sx={{ marginLeft: 1 }}>
                Already have an account?
                <span onClick={handleLogin} className="forgot-pass" style={{ marginLeft: 2 }}>
                  Login
                </span>
              </Typography>
            </Box>
            <Box className="box-tab-120">
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email Id"
                type="email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={handleInputChange}
                required
                inputProps={{ maxLength: 50 }}
                helperText={error}
                error={!!error}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Box className="forgot-pass-filed-row">
              <Button
                type="submit"
                color="error"
                variant="contained"
                fullWidth
                sx={{ textTransform: 'none' }}>
                Send Reset Link
                <Box
                  component="img"
                  src="./icons/button-end.svg"
                  sx={{ width: 26, height: 26, ml: 2 }}
                  alt="detail"
                />
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ForgotPasswordModal;
