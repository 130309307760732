import { Box } from '@mui/material';
import React from 'react';
import { accordionData } from '../../constants/reviewAndPay';
import CustomAccordion from './CustomAccordion';

export const AccordionSection = () => (
  <Box
    width="100%"
    bgcolor="#FFFFFF"
    height={{ md: '254px' }}
    borderRadius={{ md: '6px' }}
    boxShadow="3px 3px 20px 0px #00000014"
    display="grid"
    gap="6px">
    {accordionData.map((data, index) => (
      <CustomAccordion key={index} title={data.title} summary={data.summary}></CustomAccordion>
    ))}
  </Box>
);
