import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import privacyPolicyData from '../constants/privacyPolicyData';

const PrivacyPolicy = () => (
  <Container maxWidth="md" sx={{ mt: 4 }}>
    <Box>
      <Typography fontFamily="gothamBold" fontSize="32px" textAlign="center">
        Privacy Policy
      </Typography>
    </Box>
    {privacyPolicyData.map((section, index) => (
      <Box key={index} sx={{ mb: 3 }}>
        <Typography
          fontFamily="gothamBold"
          fontSize="16px"
          align={index === 0 ? 'center' : 'left'}
          gutterBottom>
          {section.title}
        </Typography>
        <Typography fontFamily="gothamBook" fontSize="16px" color="#555555" paragraph>
          {section.content}
        </Typography>
      </Box>
    ))}
  </Container>
);

export default PrivacyPolicy;
