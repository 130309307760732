import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Title } from './Title';
import { formData } from '../../constants/reviewAndPay';

export const FormSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      height={{ lg: '438px' }}
      bgcolor="#FFFFFF"
      borderRadius={{ md: '6px' }}
      boxShadow="3px 3px 20px 0px #00000014"
      px={{ xs: 3, md: '30px' }}
      py={{ xs: '30px', md: '41px' }}>
      <Box display="flex" justifyContent="space-between" width="100%" fontFamily="inherit">
        <Box>
          <Title>Women’s Special Vietnam</Title>
          <Typography fontWeight={400} fontSize={14} mt="6px" color="#000000CC">
            9 days/8 nights, 18 April 2024 - 26 April 2024/Mumbai to Mumbai
          </Typography>
        </Box>
        {!isMobile && (
          <Box display="flex" alignItems="center" gap="10px" sx={{ cursor: 'pointer' }}>
            <Box src="./icons/edit.svg" alt="edit" component="img" width={12} height={12} />
            <Typography
              fontWeight={400}
              fontSize={14}
              lineHeight="22px"
              color="#27306B"
              textTransform="capitalize">
              Edit
            </Typography>
          </Box>
        )}
      </Box>
      {!isMobile && <Divider variant="fullWidth" role="presentation" sx={{ pt: '31px' }} />}
      <Typography
        fontWeight={400}
        fontSize={14}
        mt={!isMobile ? '31px' : '26px'}
        pb="4px"
        color="#00000099">
        We request you to make sure all details are correct to ensure a smooth booking process.
      </Typography>
      {isMobile && <Divider variant="fullWidth" role="presentation" sx={{ pt: '16px' }} />}
      <Box>
        {isMobile && (
          <Box mt="18px" display="flex" justifyContent="space-between" alignItems="center">
            <Typography flex={1}>Lead Travellers Details</Typography>
            <Box src="./icons/edit.svg" alt="edit" component="img" width={12} height={12} mr={1} />
            <Typography>edit</Typography>
          </Box>
        )}
        {formData.map((data) => (
          <Box
            display="flex"
            alignItems="center"
            id={data.name}
            gap={{ xs: 2, md: '44px' }}
            mt="16px"
            key={data.name}>
            <Typography
              fontWeight={400}
              fontSize={14}
              lineHeight="26px"
              color="#000000E5"
              width="107px">
              {data.name}
            </Typography>
            <Typography
              fontWeight={isMobile ? 500 : 700}
              fontSize={isMobile ? 13 : 16}
              lineHeight="26px"
              color="#000000B2">
              {data.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
