import React, { useState } from 'react';
import './LoginModal.css';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  TextField,
  Button,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ChevronLeftRounded, CloseRounded } from '@mui/icons-material';
import KesariSelectImg from '../../../../assets/Images/Kesari_Select.webp';

const LoginModal = ({ open, handleCloseLoginModal, handleSignup, openForgotPassModal }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = useState(0);
  const [formValues, setFormValues] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });

  const handleBack = () => {
    if (value === 1) {
      setValue(0);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }));
    validateInput(id, value);
  };

  const validateInput = (field, value) => {
    let error = '';
    switch (field) {
      case 'email':
        if (value.length > 50) {
          error = 'Email cannot exceed 50 characters';
        }
        break;
      case 'password':
        if (value.length < 8) {
          error = 'Password must be at least 8 characters long';
        } else if (value.length > 50) {
          error = 'Password cannot exceed 50 characters';
        } else if (!/(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(value)) {
          error = 'Password must contain at least 1 letter, 1 number, and 1 symbol';
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error
    }));
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (!errors.email && !errors.password) {
      // eslint-disable-next-line no-console
      console.log('Form submitted:', formValues); // Replace with your logic
      handleCloseLoginModal();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        disablebackdropclick="true"
        fullScreen={isMobile}
        aria-labelledby="login-dialog-title"
        aria-describedby="login-dialog-description"
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : 'auto',
            maxWidth: isMobile ? '100%' : '600px',
            margin: isMobile ? '0' : 'auto',
            marginTop: isMobile ? '90%' : '5%',
            height: isMobile ? 'calc(100% - 20%)' : 'auto',
            maxHeight: isMobile ? 'calc(100% - 40%)' : 'auto'
          }
        }}>
        <form onSubmit={handleLogin}>
          <DialogTitle id="login-modal">
            <Box className="login-iconAndImageStyles">
              <ChevronLeftRounded
                onClick={handleBack}
                sx={{
                  cursor: value === 1 ? 'pointer' : 'default',
                  color: value === 1 ? 'inherit' : 'grey'
                }}
              />
              <Box mx="auto" display="flex">
                <img className="imageStyles" src={KesariSelectImg} alt="Image" />
              </Box>
              <IconButton
                aria-label="close"
                onClick={handleCloseLoginModal}
                sx={{ color: (theme) => theme.palette.grey[500] }}>
                <CloseRounded />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent className="login-modal-ui">
            <Box mb={2} textAlign="center">
              <Typography className="welcom-text">Welcome</Typography>
              <Typography
                className="sub-title-text"
                mx="auto"
                component="span"
                display="inline-block"
                sx={{ marginLeft: 1 }}>
                Don't have an account?
                <span onClick={handleSignup} className="forgot-pass" style={{ marginLeft: 2 }}>
                  Sign up
                </span>
              </Typography>
            </Box>
            <Box className="box-tab-120">
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email Id"
                type="email"
                fullWidth
                variant="outlined"
                value={formValues.email}
                onChange={handleInputChange}
                required
                inputProps={{ maxLength: 50 }}
                helperText={errors.email}
                error={!!errors.email}
              />
              <TextField
                margin="dense"
                id="password"
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                value={formValues.password}
                onChange={handleInputChange}
                required
                inputProps={{ maxLength: 50 }}
                helperText={errors.password}
                error={!!errors.password}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Box className="login-filed-row">
              <Typography className="forgot-pass" mb={2} onClick={openForgotPassModal}>
                Forgot Password?
              </Typography>
              <Button
                type="submit"
                color="error"
                variant="contained"
                fullWidth
                sx={{ textTransform: 'none' }}>
                Login
                <Box
                  component="img"
                  src="./icons/button-end.svg"
                  sx={{ width: 26, height: 26, ml: 2 }}
                  alt="detail"
                />
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default LoginModal;
