import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import { api } from '../../APIs/api';

export const fetchMetaDataList = createAsyncThunk(
  'metaDataList/fetchMetaDataList',
  async ({ slug }) => {
    const response = await api.get(`${BASE_URL}${API_URL.GET_META_DATA_LIST}?slug=${slug}`);
    return response.data;
  }
);

export const metaDataListSlice = createSlice({
  name: 'metaDataList',
  initialState: {
    metaDataList: [],
    status: 'idle',
    error: null
  },
  reducers: {
    getMetaDataListing: {
      reducer: (state, action) => {
        state.metaDataList = [action.payload];
      },
      prepare(value) {
        return {
          payload: {
            key: nanoid(),
            value: value
          }
        };
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetaDataList.pending, (state) => {
        state.error = null;
        state.status = 'loading';
      })

      .addCase(fetchMetaDataList.fulfilled, (state, action) => {
        state.status = true;
        state.metaDataList = [action.payload];
      })

      .addCase(fetchMetaDataList.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      });
  }
});

export const { getMetaDataList } = metaDataListSlice.actions;

export default metaDataListSlice.reducer;
