/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './PackageBanner.css';
import { Box, Skeleton } from '@mui/material';
import { CalendarMonth, PublicSharp, PlaceRounded } from '@mui/icons-material';
// import DefaultPackageBannerImg from '../../../assets/PackageDetails/package_banner.png';
// import PlayIcon from '../../../assets/PackageDetails/play-icon.svg';
import HeartIcon from '../../../assets/PackageDetails/heart.svg';
import StarIcon from '../../../assets/PackageDetails/star.svg';

const PackageBanner = ({ packageDetails, isLoader }) => {
  const [showAllCities, setShowAllCities] = useState(false);
  const { package_image = {}, package_name, number_of_days, places = [] } = packageDetails || {};
  const packageBannerImg = package_image && package_image['Img 1'];
  const numberOfCities = places?.length || 0;
  const encodedPackageBannerImg = packageBannerImg && encodeURI(packageBannerImg);

  const safeEncodeURI = (url) =>
    encodeURI(url).replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/'/g, '%27');

  const reviews = packageDetails?.reviews;
  const totalRating = reviews?.reduce((acc, review) => acc + parseFloat(review.review_rating), 0);
  const averageRating = totalRating / reviews?.length;

  const handleToggleCities = () => {
    setShowAllCities(true);
  };

  return (
    <Box
      className="cmn-banner-sec"
      style={{ backgroundImage: `url(${safeEncodeURI(encodedPackageBannerImg)})` }}>
      {/* TODO: currently, the code commented on the play button */}
      {/* <a href="#" className="playicon">
        <img src={PlayIcon} alt="playicon" />
      </a> */}
      <Box className="pl-pr-120 content-area">
        <Box className="title">
          {isLoader ? (
            <Skeleton
              variant="text"
              width="40%"
              height={20}
              sx={{ background: 'lightslategray' }}
            />
          ) : (
            <>{package_name}</>
          )}
          {/* <Box className="icon">
            <img src={HeartIcon} alt="HeartIcon" />
          </Box> */}
        </Box>
        {isLoader ? (
          <Skeleton variant="text" width="25%" height={20} sx={{ background: 'lightslategray' }} />
        ) : (
          <Box className="day-country-group">
            <ul>
              <li>
                <CalendarMonth />
                <Box ml={1}>{`${number_of_days || 0} Days`}</Box>
              </li>
              <li>
                <PublicSharp />
                <Box ml={1}>1 Country</Box>
              </li>
              <li>
                <PlaceRounded />
                <Box ml={1}>{`${numberOfCities || 0} Cities`}</Box>
              </li>
            </ul>
            {/* <Box className="reviews">
              <img src={StarIcon} alt="rating" />
              <b style={{ marginRight: '10px' }}>
                {reviews && reviews.length > 0 ? averageRating.toFixed(1) : 0}
              </b>{' '}
              (<u> {packageDetails.reviews?.length} Reviews</u> )
            </Box> */}
          </Box>
        )}

        {isLoader ? (
          <Skeleton variant="text" width="30%" height={60} sx={{ background: 'lightslategray' }} />
        ) : (
          <Box className="places-group">
            <ul>
              {places?.slice(0, 5).map((place, index) => (
                <li key={index}>
                  {place.Place} ({place['Number of Nights']}N)
                </li>
              ))}
              {showAllCities &&
                places?.slice(5).map((place, index) => (
                  <li key={index + 5}>
                    {place.Place} ({place['Number of Nights']}N)
                  </li>
                ))}
              {places?.length > 5 && !showAllCities && (
                <li className="hover-city" onClick={handleToggleCities}>
                  +{places.length - 5} Cities
                </li>
              )}
            </ul>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PackageBanner;
