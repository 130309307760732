import React from 'react';
import './PackageSlider.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@mui/material';

// eslint-disable-next-line no-unused-vars
const PackageSlider = ({ images, title }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Box className="slider-container">
      <Slider {...settings}>
        <Box>
          <Box
            className="slider-img"
            sx={{
              height: { xs: '120px', sm: '120px', md: '140px', lg: '160px' },
              width: { xs: '240px', sm: '240px', md: '280px', lg: '320px' }
            }}>
            <img src={images} alt="Louvere Museum" />
            <Box className="slider-title">{title}</Box>
          </Box>
        </Box>
      </Slider>
    </Box>
  );
};

export default PackageSlider;
