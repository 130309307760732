import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const Faq = ({ id, title, content }) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange} disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        sx={{ margin: 0 }}>
        <Typography fontFamily="gothamBold" fontSize={{ xs: 12, md: 18 }} color="#000000">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'grid', gap: { md: '16px' } }}>
        {/* {content &&
          content?.map((paragraph, index) => ( */}
        <Typography
          // key={index}
          fontFamily="gothambook"
          fontSize={14}
          lineHeight="24px"
          color="#00000099">
          {content}
        </Typography>
        {/* ))} */}
      </AccordionDetails>
    </Accordion>
  );
};

export default Faq;
