export const formData = [
  { name: 'Name :', value: 'Akshata Kenjale' },
  { name: 'Gender :', value: 'Female' },
  { name: 'Mobile no :', value: '+91 8879871562' },
  { name: 'Email Id :', value: 'Kenjaleakshu1997@gmail.com' },
  { name: 'Date of Birth :', value: '01 Aug 2024' },
  { name: 'Narionality :', value: 'India' },
  { name: 'State :', value: 'Maharashtra' }
];

export const insightData = [
  { total: '3.2B', title: 'Happy Travellers' },
  { total: '96%', title: 'Repeat Travellers' },
  { total: '5k+', title: 'Locations Covered' },
  { total: '20k+', title: 'Tours Organized' }
];

export const accordionData = [
  {
    title: 'Inclusions & Exclusions',
    summary:
      ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
  },
  {
    title: 'Frequently Asked Questions',
    summary:
      ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
  },
  {
    title: 'Cancellation & Payment Terms',
    summary:
      ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
  },
  {
    title: 'Terms & Conditions',
    summary:
      ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
  }
];

export const reviewPointsData = [
  'After your payment is complete, our Veena World travel advisor will get in touch with you to complete your booking',
  'Complete details of all travellers including a copy of the valid ID proofs.',
  'Post booking confirmation, we are constantly in touch with you through SMS, WhatsApp and email keeping you updated about every step until your departure.',
  'Details of the tour manager and all of the pre departure information will be communicated before the departure.',
  'Once your tour begins, you can sit back and just enjoy! The Veena World tour manager will handle the rest and ensure that you have a great time!'
];
