import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import { reviewPointsData } from '../../constants/reviewAndPay';

export const AboutSection = () => (
  <Box
    boxShadow="3px 3px 20px 0px #00000014"
    bgcolor="#FFFFFF"
    fontFamily="inherit"
    maxWidth={{ lg: '338px' }}
    px={{ xs: 2, md: '35px' }}
    mt={{ length: '20px' }}
    py={{ xs: 2, md: '30px' }}>
    <Typography color="#000000CC" fontWeight={700} fontSize={14} lineHeight="24px">
      Once you book, we’ll handle the rest.
    </Typography>
    <List
      sx={{
        listStyleType: 'disc',
        pl: { xs: 2, md: 4 },
        pb: 0,
        pt: 0
      }}>
      {reviewPointsData.map((review) => (
        <ListItem
          disablePadding
          key={review}
          sx={{
            display: 'list-item',
            fontWeight: 400,
            fontSize: 13,
            lineHeight: '20px',
            color: '#000000B2',
            mt: { xs: 1, md: 2 }
          }}>
          {review}
        </ListItem>
      ))}
    </List>
  </Box>
);
