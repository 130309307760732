import React from 'react';
import { Button, Box } from '@mui/material';

const CustomButton = ({ children, className, showArrow = false, ...rest }) => (
  <>
    <Button
      {...rest}
      sx={{ textTransform: 'none', fontWeight: 700, fontSize: '20px' }}
      className={className}
      endIcon={
        showArrow ? (
          <Box
            component="img"
            src="./icons/button-end.svg"
            sx={{ width: 26, height: 26 }}
            alt="detail"
          />
        ) : null
      }>
      <Box
        className="everyStep_explore_btn_inner"
        style={{
          display: 'flex',
          gap: '20px',
          alignItems: 'center',
          fontSize: '13px'
        }}>
        {children}
        {/* {showArrow && (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <svg
              width="13"
              height="22"
              viewBox="0 0 13 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.9648 11L2.36408 22L0.464783 20.0292L9.21037 11L0.508952 1.97088L2.40825 4.95911e-05L12.9648 11Z"
                fill="url(#paint0_linear_1152_47198)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1152_47198"
                  x1="0.464783"
                  y1="11"
                  x2="12.9648"
                  y2="11"
                  gradientUnits="userSpaceOnUse">
                  <stop stopColor="white" />
                  <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </Box>
        )} */}
      </Box>
    </Button>
  </>
);

export default CustomButton;
