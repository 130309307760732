import React from 'react';
import { ImageList, ImageListItem, Skeleton, useTheme, useMediaQuery } from '@mui/material';
import { desktopRows, mobileRows } from '../../constants/photoGallery';

const RenderSkeletonsInRows = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const rows = isTablet ? mobileRows : desktopRows;
  const totalRows = 5;

  return (
    <>
      {Array.from({ length: totalRows }).map((_, i) => {
        const row = rows[i];
        return (
          <ImageList
            key={`skeleton-${i}`}
            cols={row.count}
            gap={8}
            sx={{
              marginBottom: 2,
              justifyContent: 'space-between',
              display: 'flex',
              height: { xs: 152, sm: 200, md: 260 },
              overflow: 'hidden',
              padding: 0
            }}>
            {Array.from({ length: row.count }).map((_, idx) => (
              <ImageListItem
                key={`skeleton-image-${i}-${idx}`}
                sx={{
                  height: '100%',
                  width: '100%',
                  overflow: 'hidden',
                  flex: isTablet ? 1 : 'auto'
                }}>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        );
      })}
    </>
  );
};

export default RenderSkeletonsInRows;
