import React, { useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { useLocation } from 'react-router-dom';
import Header from './layout/Header/Header';
import BaseFooter from './layout/Footer';
import Routes from './Routes/routes';
import ScrollToTop from './components/home/TopScrollArrow/ScrollToTop';
import Chatbot from './components/ChatBot/Chatbot';
import { storeCampaignDetails, initializeCampaignCleanup } from './Helper/campaignUtils';

const App = () => {
  const location = useLocation();
  const { pathname } = location;
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
    person: {
      id: localStorage.getItem('authId'),
      email: localStorage.getItem('email')
    }
  };

  useEffect(() => {
    storeCampaignDetails();
    const cleanup = initializeCampaignCleanup();
    return () => cleanup();
  }, []);

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <ToastContainer />
        {pathname !== '/' && <Header />}
        <Routes />
        <Chatbot />
        <BaseFooter />
        <ScrollToTop />
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
