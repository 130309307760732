/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Skeleton, Typography } from '@mui/material';
import { Star, PlayArrow, FavoriteBorderRounded, FavoriteRounded } from '@mui/icons-material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses
} from '@mui/lab';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomModal from '../../components/common/CustomModal';
import TripShareMap from '../../components/common/trip_share_map/TripShareMap';
// import ReactPlayer from 'react-player';

// const videoTestUrl = 'https://www.youtube.com/watch?v=QmMWLqR2500';

const CarouselHeader = ({ tourPackage, mode, places, isLoader }) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const timelineItems = places.map((place) => `${place.Place} (${place['Number of Nights']}N)`);
  const [isFavorite, setIsFavorite] = useState(false);

  const handleToggleFavorite = () => {
    setIsFavorite((prev) => !prev);
  };

  const imageUrls = tourPackage?.package_image
    ? Object.keys(tourPackage.package_image)
        .filter((key) => /^Img \d+$/.test(key))
        .map((key) => tourPackage.package_image[key])
    : [];

  const openModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const settings = {
    dots: false,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: imageUrls.length > 1,
    autoplay: imageUrls.length > 1,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    prevArrow: false,
    nextArrow: false
  };

  return (
    <Box
      position="relative"
      sx={{
        '& .slick-dots': { bottom: 10 },
        '&.slick-arrow ': { display: 'none' }
      }}>
      {/* <Box
        position="absolute"
        top={0}
        left={0}
        zIndex={20}
        width={160}
        height={23}
        bgcolor="#000000B2"
        borderRadius="10px 0 10px 0"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Typography
          fontWeight={500}
          fontSize={12}
          lineHeight="14px"
          display="flex"
          alignItems="center"
          color="#FFFFFF">
          <Star sx={{ width: '15px', height: '15px', color: '#F0C419' }} />
          <Typography fontSize="12px" lineHeight="15px" fontFamily="gothamBold" pl="3px">
            {isLoader ? (
              <Skeleton
                variant="text"
                width={40}
                height={25}
                sx={{ background: 'lightslategray' }}
              />
            ) : (
              4.2
            )}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{
              height: '16px',
              backgroundColor: '#fff',
              mx: '12px'
            }}
          />
          <Typography fontSize="12px" lineHeight="15px" fontFamily="poppinsMedium">
            {isLoader ? (
              <Skeleton
                variant="text"
                width={70}
                height={25}
                sx={{ background: 'lightslategray' }}
              />
            ) : (
              <>
                {tourPackage?.theme.length > 12
                  ? tourPackage?.theme.slice(0, 12) + '...'
                  : tourPackage?.theme}
              </>
            )}
          </Typography>
        </Typography>
      </Box> */}
      {/* <Box
        position="absolute"
        right={13}
        top={22}
        display="flex"
        flexDirection="column"
        gap={2}
        zIndex={20}>
        <Box
          height={24}
          width={24}
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="#000000B2"
          borderRadius="100%"
          sx={{ cursor: 'pointer' }}
          onClick={() => openModal('video')}>
          <img src="/icons/video.svg" width={14} height={14} alt="video" />
        </Box>
        <Box
          height={24}
          width={24}
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="#000000B2"
          borderRadius="100%"
          sx={{ cursor: 'pointer' }}
          onClick={() => openModal('map')}>
          <img src="/icons/map.svg" width={14} height={14} alt="map" />
        </Box>
        <Box
          height={24}
          width={24}
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="#000000B2"
          borderRadius="100%"
          sx={{
            cursor: 'pointer',
            border: '1px solid white'
          }}
          onClick={handleToggleFavorite}>
          {isFavorite ? (
            <FavoriteRounded sx={{ fontSize: 'medium', color: '#E41E25' }} />
          ) : (
            <FavoriteBorderRounded sx={{ color: 'white', fontSize: 'medium' }} />
          )}
        </Box>
      </Box> */}
      {isLoader ? (
        <Skeleton
          variant="text"
          width="100%"
          height={200}
          maxWidth={{ xs: '100%', md: mode === 'grid' ? 424 : 424 }}
          maxHeight={mode === 'grid' ? 290 : 170}
        />
      ) : (
        <>
          <Slider {...settings} useTransform>
            {imageUrls.map((url, index) => (
              <Box
                key={index}
                component="img"
                src={url}
                alt={`Image ${index + 1}`}
                maxWidth={{ xs: '100%', md: mode === 'grid' ? 424 : 424 }}
                maxHeight={mode === 'grid' ? 290 : 170}
                width="100%"
                height="100%"
                sx={{ objectPosition: '0 65%', cursor: 'pointer' }}
                onClick={() =>
                  navigate(`/explore/product-details/${tourPackage?.new_item_code}`, {
                    state: { packageId: tourPackage?.new_item_code }
                  })
                }
              />
            ))}
          </Slider>
        </>
      )}

      <CustomModal open={modalOpen} onClose={closeModal} sx={{ m: 'auto' }} closable>
        {modalContent === 'video' && (
          <Box
            sx={{
              width: { xs: '316px', sm: '480px', md: '1000px' },
              height: { xs: '216px', sm: '400px', md: '600px' },
              bgcolor: 'white',
              borderRadius: '10px',
              margin: 'auto',
              backgroundImage: 'url(./images/package.jpeg)',
              // backgroundImage: 'url(videoTestUrl)',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              position: 'relative',
              display: 'grid',
              placeItems: 'center'
            }}>
            <PlayArrow
              sx={{
                position: 'absolute',
                color: '#F5F5F5',
                width: 60,
                height: 72
              }}
            />
            {/* <ReactPlayer url={videoTestUrl} width="100%" height="100%" controls /> */}
          </Box>
        )}
        {modalContent === 'map' && (
          <Box
            sx={{
              bgcolor: 'white',
              borderRadius: '10px',
              margin: 'auto',
              padding: '25px 39px 26px 32px',
              maxWidth: { xs: '360px', md: '440px', lg: '660px' },
              maxHeight: { xs: '300px', md: '400px', lg: '460px' }
            }}>
            <Typography fontWeight={700} fontSize={20} lineHeight="32px" color="#000000E5">
              {tourPackage?.package_name}
            </Typography>
            <Box display="flex" alignItems="start" gap="24px" mt="20px">
              <Box
                className="map-card-sec"
                width={{ xs: 180, md: 320, lg: 400 }}
                height={{ xs: 220, md: 280, lg: 340 }}>
                <TripShareMap packageDetails={tourPackage} shareIcon={false} />
              </Box>
              <Timeline
                sx={{
                  maxWidth: '165px',
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: '0 !important'
                  }
                }}>
                {timelineItems?.map((item, index) => (
                  <TimelineItem key={index} sx={{ minHeight: '38px' }}>
                    <TimelineSeparator>
                      <Box component="img" src="./icons/location.svg" alt="location" mt="3px" />
                      {index !== timelineItems.length - 1 && <CustomTimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent
                      fontFamily="gothamBook"
                      fontSize={14}
                      lineHeight="22px"
                      padding="0 6px!important">
                      {isLoader ? (
                        <Skeleton variant="text" width={150} height={200} />
                      ) : (
                        <> {item}</>
                      )}
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Box>
          </Box>
        )}
      </CustomModal>
    </Box>
  );
};

const CustomTimelineConnector = () => (
  <TimelineConnector
    sx={{
      backgroundColor: 'transparent',
      borderRight: '1px dashed #0000004D'
    }}
  />
);

export default CarouselHeader;
