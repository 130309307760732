import { ChevronRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Radio,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';

export const PaymentSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      width={{ lg: '410px' }}
      height={{ lg: '530px' }}
      borderRadius={{ md: '6px' }}
      bgcolor="white">
      <Box
        border="1px solid #0000000F"
        boxShadow="3px 3px 20px 0px #00000014"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="8px"
        px={{ xs: '16px', md: '28px' }}
        py={{ xs: '20px', md: '15px' }}
        height={{ md: '66px' }}>
        <Typography
          fontWeight={400}
          fontSize={14}
          lineHeight="28px"
          color="#000000CC"
          border={isMobile && '1px solid #0000000F'}
          sx={{ textDecoration: 'underline' }}>
          View Price Breakup
        </Typography>
        <Typography
          fontWeight={700}
          fontSize={isMobile ? 18 : 22}
          lineHeight={isMobile ? '30px' : '40px'}
          color="#000000CC"
          maxHeight="66px">
          ₹1,24,000
        </Typography>
      </Box>
      <Box
        border="1px solid #0000000F"
        boxShadow={{ md: '3px 3px 20px 0px #00000014' }}
        maxHeight={{ md: '443px' }}
        mt={{ md: '20px' }}
        py="30px"
        pl={{ md: '27px' }}
        pr={{ md: '47px' }}
        px={{ xs: 2 }}
        sx={{
          '& .MuiFormControlLabel-label': {
            fontWeight: 400,
            fontSize: 15,
            lineHeight: '22px',
            color: '#000000D9'
          }
        }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <FormControlLabel
            value="registrationAmount"
            name="amount"
            control={
              <Radio
                sx={{
                  '&, &.Mui-checked': {
                    color: '#27306B'
                  }
                }}
                defaultChecked
              />
            }
            label="Pay Registration Amount"
          />
          <Typography
            fontWeight={700}
            fontSize={isMobile ? 14 : 20}
            lineHeight={isMobile ? '20px' : '35px'}>
            ₹25,000
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <FormControlLabel
            value="fullAmount"
            name="amount"
            control={
              <Radio
                sx={{
                  '&, &.Mui-checked': {
                    color: '#27306B'
                  }
                }}
              />
            }
            color=""
            label="Pay Full Amount"
            sx={{ ':checked': { color: 'red' } }}
          />
          <Typography
            fontWeight={700}
            fontSize={isMobile ? 14 : 20}
            lineHeight={isMobile ? '20px' : '35px'}>
            ₹1,24,000
          </Typography>
        </Box>
        <Divider variant="fullWidth" role="presentation" sx={{ mt: isMobile ? '22px' : '25px' }} />
        <Box
          mt={{ xs: '22px', md: '26px' }}
          fontFamily="inherit"
          sx={{
            '& .MuiFormControlLabel-label ': {
              fontWeight: 400,
              fontSize: 12,
              lineHeight: '20px',
              color: '#00000099'
            }
          }}>
          <Box
            display="flex"
            gap={{ md: '74px' }}
            sx={{ alignItmes: 'start', justifyContent: 'space-between' }}
            pb={isMobile && 2}>
            <Typography fontWeight={400} fontSize={12} lineHeight="20px">
              Convience Charges (1.8%)
              {isMobile && <br />}
              Balance Payment due by 18 Jul 2024
            </Typography>
            <Typography fontWeight={500} fontSize={16} lineHeight="20px">
              +500
            </Typography>
          </Box>
          <FormControlLabel
            required
            sx={{ mt: { md: 3 } }}
            control={
              <Checkbox
                sx={{
                  color: '#00000099',
                  '&, &.Mui-checked': {
                    color: '#27306B'
                  }
                }}
              />
            }
            label=" I agree to the Terms & Conditions & have reviewed the Policies & Cancellation rules."
          />
          <FormControlLabel
            required
            sx={{ mt: { md: 2 } }}
            control={
              <Checkbox
                sx={{
                  color: '#00000099',
                  '&, &.Mui-checked': {
                    color: '#27306B'
                  }
                }}
              />
            }
            label="I consent to receiving updates regarding my trip on SMS, email & Whatsapp"
          />
        </Box>
        <Button
          fullWidth
          sx={{
            mt: { md: 3.5 },
            width: { xs: '100%', md: '350px' },
            bgcolor: '#E41E25',
            color: '#fff',
            py: '10px',
            ':hover': {
              bgcolor: '#E41E25',
              color: '#fff'
            },
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '28px',
            position: isMobile && 'fixed',
            bottom: isMobile && 0,
            left: isMobile && 0,
            zIndex: 20
          }}
          endIcon={
            isMobile && (
              <Box display="flex" alignItems="center">
                <ChevronRight sx={{ width: 16, height: 16 }} />
                <ChevronRight sx={{ width: 16, height: 16 }} />
              </Box>
            )
          }>
          Proceed to pay & Book Seats
        </Button>
      </Box>
    </Box>
  );
};
