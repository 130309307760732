import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import NotFoundImage from '../assets/Images/404.webp';

const NotFound404 = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
      textAlign="center"
      sx={{ padding: 4 }}>
      <Box
        component="img"
        src={NotFoundImage}
        alt="Page not found"
        sx={{
          width: '100%',
          maxWidth: 400,
          height: 'auto',
          mb: 2
        }}
      />
      <Button
        variant="contained"
        sx={{ fontFamily: 'gothamBold', background: '#27306B', textTransform: 'capitalize', mt: 2 }}
        onClick={() => navigate('/')}>
        Go to Home
        <Box ml={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <svg
            width="13"
            height="22"
            viewBox="0 0 13 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.9648 11L2.36408 22L0.464783 20.0292L9.21037 11L0.508952 1.97088L2.40825 4.95911e-05L12.9648 11Z"
              fill="url(#paint0_linear_1152_47198)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1152_47198"
                x1="0.464783"
                y1="11"
                x2="12.9648"
                y2="11"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="white" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            width="13"
            height="22"
            viewBox="0 0 13 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.9648 11L2.36408 22L0.464783 20.0292L9.21037 11L0.508952 1.97088L2.40825 4.95911e-05L12.9648 11Z"
              fill="url(#paint0_linear_1152_47198)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1152_47198"
                x1="0.464783"
                y1="11"
                x2="12.9648"
                y2="11"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="white" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
      </Button>
    </Box>
  );
};

export default NotFound404;
