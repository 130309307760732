import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { AccessTimeFilled, Call, Email, CloseRounded, WhatsApp } from '@mui/icons-material';
import KesariSelectImg from '../../assets/Images/Kesari_Select.webp';

const ContactUsModal = ({ open, handleClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      fullScreen={isMobile}
      aria-labelledby="contact-us-dialog-title"
      aria-describedby="contact-us-dialog-description"
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : 'auto',
          maxWidth: isMobile ? '100%' : '500px',
          margin: isMobile ? '0' : 'auto',
          marginTop: isMobile ? '90%' : '15%',
          height: isMobile ? 'calc(100% - 20%)' : 'auto',
          maxHeight: isMobile ? 'calc(100% - 40%)' : 'auto'
        }
      }}>
      <DialogTitle id="contact-us-dialog-title">
        <Box className="login-iconAndImageStyles">
          <Box mx="auto" display="flex">
            <img src={KesariSelectImg} alt="Image" width="100px" height="50px" />
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ color: (theme) => theme.palette.grey[500] }}>
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box mb={2} textAlign="center">
          <Typography
            sx={{
              fontFamily: 'gothamBold',
              fontSize: '26px',
              textAlign: 'center'
            }}>
            Contact Us
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Call fontSize="small" sx={{ marginRight: '8px' }} />
            <Typography variant="body2" sx={{ fontSize: '16px', fontFamily: 'inherit' }}>
              Tailormade Holidays:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              component="a"
              href="tel:18002669080"
              sx={{
                fontSize: '16px',
                fontFamily: 'gothamBold',
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                  color: theme.palette.primary.main,
                  cursor: 'pointer'
                }
              }}>
              1800 266 9080
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <WhatsApp fontSize="small" sx={{ marginRight: '8px' }} />
            <Typography variant="body2" sx={{ fontSize: '16px', fontFamily: 'inherit' }}>
              WhatsApp no:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              component="a"
              href="https://wa.me/918657758850"
              target="_blank"
              sx={{
                fontSize: '16px',
                fontFamily: 'gothamBold',
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                  color: theme.palette.primary.main,
                  cursor: 'pointer'
                }
              }}>
              +91 86577 58850
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Email fontSize="small" sx={{ marginRight: '8px' }} />
            <Typography variant="body2" sx={{ fontSize: '16px', fontFamily: 'inherit' }}>
              Email:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              component="a"
              href="mailto:holiday@kesariselect.in"
              sx={{
                fontSize: '16px',
                fontFamily: 'gothamBold',
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                  color: theme.palette.primary.main,
                  cursor: 'pointer'
                }
              }}>
              holiday@kesariselect.in
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <AccessTimeFilled fontSize="small" sx={{ marginRight: '8px' }} />
            <Typography variant="body2" sx={{ fontSize: '16px', fontFamily: 'inherit' }}>
              Office Time:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: 'gothamBold',
                textDecoration: 'none',
                '&:hover': {
                  color: theme.palette.primary.main,
                  cursor: 'pointer'
                }
              }}>
              10am to 6pm
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ContactUsModal;
