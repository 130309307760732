import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './TailoredAdventures.css';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Skeleton,
  styled,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { TabContext } from '@mui/lab';
import CustomButton from '../../common/Button';
import { API_URL, BASE_URL } from '../../../APIs/endpoint';
import { api } from '../../../APIs/api';
import { convertToHyphenatedLowerCase, determineZone } from '../../../Helper/global';
import { AdventuresTabMenu } from '../../../constants/tailoredAdventures';
import DefaultImage from '../../../assets/Home/Tailored_Adventures/Beach.webp';

const StyledCard = styled(Card)`
  box-shadow: none !important;
  &:hover .MuiCardActionArea-focusHighlight {
    opacity: 0 !important;
  }
`;

const StyledTabs = styled(Tabs)`
  display: flex;
  justify-content: center;
`;

const StyledTab = styled(Tab)`
  display: flex;
  justify-content: center;
  &.TailoredAdventures_tab_text_1.Mui-selected .TailoredAdventures_tab_text {
    color: rgba(39, 48, 107, 1);
    font-family: gothamBold;
    filter: grayscale(0);
  }
  &.TailoredAdventures_tab_text_1.Mui-selected .TailoredAdventures_tab_img_active {
    filter: grayscale(0);
  }
  & .MuiTabs-flexContainer {
    flex-wrap: wrap;
  }
`;

const TailoredAdventures = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [activatTab, setActivatTab] = useState(0);
  const [adventureData, setAdventureData] = useState();
  const [error, setError] = useState(null);
  const [selectedTag, setSelectedTag] = useState('Desert');
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const getAdventureListApi = async (tag) => {
    try {
      setAdventureData(null);
      setError(null);
      const payload = {
        adventure_tags: tag.replace(/&/g, 'and')
      };
      const adventureRes = await api.get(
        `${BASE_URL}${API_URL.GET_ADVENTURES_LIST}?adventure_tags=${payload.adventure_tags}`
      );
      const { data, message, status } = adventureRes;
      if (status) {
        const uniqueAdventures = getUniqueAdventures(data);
        setAdventureData(uniqueAdventures);
      } else {
        setError(message);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const getUniqueAdventures = (adventures) => {
    const uniqueRecords = new Map();
    adventures.forEach((adventure) => {
      const { country, state } = adventure;
      if (country === 'India') {
        if (state && !uniqueRecords.has(state)) {
          uniqueRecords.set(state, adventure);
        }
      } else {
        if (country && !uniqueRecords.has(country)) {
          uniqueRecords.set(country, adventure);
        }
      }
    });
    return Array.from(uniqueRecords.values()).slice(0, 10);
  };

  useEffect(() => {
    getAdventureListApi(selectedTag);
  }, [selectedTag]);

  const transformData = (data) =>
    data &&
    data.map((item) => ({
      ...item,
      img: item.package_image,
      place: item.country === 'India' ? item.state : item.country
    }));

  // Transform and manage the data
  let cardMenu = transformData(adventureData);
  if (cardMenu && cardMenu.length > 10) {
    cardMenu = cardMenu.sort(() => 0.5 - Math.random()).slice(0, 10);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActivatTab(newValue);
    setSelectedTag(AdventuresTabMenu[newValue].tab_txt);
  };
  const NoScrollButtons = () => null;

  const firstRow = cardMenu && cardMenu.slice(0, 5);
  const secondRow = cardMenu && cardMenu.slice(5, 10);

  const handleCardClick = (data, place) => {
    let url = '';
    const zone = determineZone(data?.state);
    if (zone === 'world') {
      url = `/explore/${zone}/${convertToHyphenatedLowerCase(
        data?.continent
      )}/${convertToHyphenatedLowerCase(data?.country)}/${convertToHyphenatedLowerCase(
        data.state
      )}?adventure=${data?.adventure_theme_tags.toLowerCase()}`;
    } else {
      url = `/explore/${convertToHyphenatedLowerCase(
        data?.country
      )}/${zone}/${convertToHyphenatedLowerCase(
        data?.state
      )}?adventure=${data?.adventure_theme_tags.toLowerCase()}`;
    }
    navigate(url, {
      state: {
        selectedAdventure: selectedTag,
        selectedAdventurePlace: place
      }
    });
  };

  const renderRow = (items) => {
    if (items && items.length > 0) {
      return items.map((item, i) => (
        <Box
          key={i}
          className="tailor-card-list-item"
          onClick={() => handleCardClick(item, item.place)}
          sx={{
            cursor: 'pointer',
            '&:hover .shadow': {
              transform: 'skew(0deg, 0deg)'
            },
            '&:hover .original': {
              opacity: 1,
              boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.16)'
            }
          }}>
          <StyledCard sx={{ display: { xs: 'block' }, marginTop: 5, overflow: 'unset' }}>
            <CardActionArea
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
              <CardMedia
                sx={{
                  width: { xs: 100, sm: 174, md: 195, lg: 187 },
                  height: { xs: 100, sm: 174, md: 200, lg: 253 },
                  borderRadius: '12px',
                  opacity: 1,
                  transition: 'all 0.3s ease-out'
                }}
                image={item.img || DefaultImage}
                title={item.place}
                className="original"
              />
              <CardMedia
                className="shadow"
                sx={{
                  width: { xs: 100, sm: 174, md: 195, lg: 187 },
                  height: { xs: 100, sm: 174, md: 200, lg: 253 },
                  borderRadius: '12px',
                  opacity: 0,
                  transition: 'all 0.3s ease-out'
                }}
                image={item.img || DefaultImage}
                title={item.place}
              />
            </CardActionArea>
            <CardContent>
              <Typography
                className="TailoredAdventures_card_placeName"
                gutterBottom
                fontFamily="gothamBook"
                textAlign="center"
                sx={{
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                    md: '16px',
                    lg: '16px',
                    xl: '20px'
                  },
                  color: '#000000'
                }}
                component="div">
                {item.place}
              </Typography>
            </CardContent>
          </StyledCard>
        </Box>
      ));
    } else {
      return Array.from(new Array(5)).map((_, i) => (
        <Box key={i} className="tailor-card-list-item">
          <StyledCard sx={{ display: { xs: 'block' }, marginTop: 5, overflow: 'unset' }}>
            <CardActionArea
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
              <Skeleton
                variant="rectangular"
                sx={{
                  width: { xs: 100, sm: 174, md: 195, lg: 187 },
                  height: { xs: 100, sm: 174, md: 200, lg: 253 },
                  borderRadius: '12px',
                  marginBottom: '16px'
                }}
              />
              <Skeleton
                variant="text"
                width={120}
                height={40}
                sx={{
                  textAlign: 'center'
                }}
              />
            </CardActionArea>
          </StyledCard>
        </Box>
      ));
    }
  };

  return (
    <Box
      className="TailoredAdventures_main"
      sx={{ paddingTop: { xs: '120px', sm: '0px', md: '0px' } }}>
      <Box className="TailoredAdventures_heading">
        <Box sx={{ height: '100%' }}>
          <Grid container sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Grid item xs={12} sm={12} md={8} lg={12} xl={12}>
              {/* for 2 lines change lg& xl = 8 */}
              <Box className="TailoredAdventures_heading_title">
                <Typography>Tailored Adventures for Every Explorer</Typography>
              </Box>
              <Typography className="TailoredAdventures_heading_desc" sx={{ fontSize: '1.25rem' }}>
                Your dream vacation is just a map away!
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Container>
        <Box className="TailoredAdventures_tabs_main">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                  display: 'flex',
                  justifyContent: 'center',
                  width: { xs: '100%' },
                  overflowX: { xs: 'auto' }
                }}>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  ScrollButtonComponent={NoScrollButtons}
                  allowScrollButtonsMobile={false}
                  aria-label="scrollable auto tabs example">
                  {AdventuresTabMenu.map((item, i) => (
                    <StyledTab
                      key={i}
                      value={i}
                      onMouseEnter={() => setHoveredIndex(i)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            '&:hover img': {
                              transform: 'scale(1.1)',
                              transition: 'transform 0.3s ease'
                            },
                            '&:hover .TailoredAdventures_tab_text': {
                              color: '#27306B',
                              transition: 'color 0.3s ease'
                            }
                          }}>
                          <img
                            src={item.tab_img}
                            alt={item.tab_txt}
                            className={
                              hoveredIndex === i
                                ? 'TailoredAdventures_tab_img_active'
                                : i === activatTab
                                ? 'TailoredAdventures_tab_img_active'
                                : 'TailoredAdventures_tab_img_inactive'
                            }
                          />
                          <span className="TailoredAdventures_tab_text">{item.tab_txt}</span>
                        </Box>
                      }
                      className="TailoredAdventures_tab_text_1"
                    />
                  ))}
                </StyledTabs>
              </Box>
            </TabContext>
          </Box>
        </Box>
      </Container>

      <Container>
        <Box className="tailor-scrollbar">
          <Box className="tailor-card-list">
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2} sx={{ flexWrap: 'nowrap' }}>
                  {renderRow(firstRow)}
                </Grid>
              </Grid>
              {secondRow?.length > 0 && (
                <Grid item xs={12}>
                  <Grid container spacing={2} sx={{ flexWrap: 'nowrap' }}>
                    {renderRow(secondRow)}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Container>
      {adventureData?.length === 0 && (
        <Typography sx={{ marginTop: '30px' }} variant="h5" align="center" color="error">
          Coming Soon...!
        </Typography>
      )}
      {error && (
        <Typography sx={{ marginTop: '30px' }} variant="h5" align="center" color="error">
          {error}
        </Typography>
      )}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Box className="TailoredAdventures_btn_container">
          <CustomButton
            className="TailoredAdventures_typo_btn"
            onClick={() =>
              navigate('/explore', {
                state: {
                  selectedAdventure: selectedTag
                }
              })
            }
            showArrow={true}>
            <Typography fontFamily="gothamBold">Begin Your Adventure</Typography>
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default TailoredAdventures;
