import React from 'react';
import './WomensDetails.css';
import { Card, CardContent, CardHeader } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';

const WomensDetails = () => (
  <>
    <div className="womens-detail-container">
      <Card className="cmn-cards">
        <CardHeader
          className="card-header"
          title="Women’s Special Vietnam"
          subheader="9 days/8 nights, 18 April 2024 - 26 April 2024/Mumbai to Mumbai"
          action={
            <a>
              <EditOutlined />
              <span>Edit</span>
            </a>
          }></CardHeader>
        <CardContent>
          <div className="woman-content-box">
            <p>
              We request you to make sure all details are correct to ensure a smooth booking
              process.
            </p>
            <div className="label-with-value-list">
              <div className="label-with-value-list-item">
                <label>Name :</label>
                <span>Akshata Kenjale</span>
              </div>
              <div className="label-with-value-list-item">
                <label>Gender :</label>
                <span>Female</span>
              </div>
              <div className="label-with-value-list-item">
                <label>Mobile no :</label>
                <span>+91 8879871562</span>
              </div>
              <div className="label-with-value-list-item">
                <label>Email Id :</label>
                <span>Kenjaleakshu1997@gmail.com</span>
              </div>
              <div className="label-with-value-list-item">
                <label>Date of Birth :</label>
                <span>01 Aug 2024</span>
              </div>
              <div className="label-with-value-list-item">
                <label>Narionality :</label>
                <span>India</span>
              </div>
              <div className="label-with-value-list-item">
                <label>State :</label>
                <span>Maharashtra</span>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  </>
);

export default WomensDetails;
