import { Box, Grid, Typography } from '@mui/material';
import { MonthsWether } from '../../../constants/SelectedVacationSpotConstant';

const MonthTravelling = ({ handleMonthClick, handleTabChange }) => (
  <Grid container spacing={2}>
    {MonthsWether.map((month, index) => (
      <Grid item xs={4} sm={3} md={3} key={index}>
        <Box
          sx={{
            borderRadius: '8px',
            position: 'relative',
            width: '100%',
            paddingTop: '100%',
            opacity: '0px',
            border: '1px solid #0000001A',
            boxShadow: '2px 2px 14px 0px #00000014',
            display: 'flex',
            background: '#FFFFFF',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'border 0.2s ease, box-shadow 0.2s ease',
            '&:hover': {
              border: '1px solid #E41E25 !important'
            },
            cursor: 'pointer'
          }}
          onClick={() => {
            handleMonthClick(month);
            handleTabChange(1);
          }}>
          {month.seasonal && (
            <Box className="seasonal-text" sx={{ px: 1, py: 0.5 }}>
              SEASONAL
            </Box>
          )}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Box className="season-icon">{month.icon}</Box>
            <Typography className="month-name">{month.name}</Typography>
            {/* <Typography className="temprature-text">({month.temperature})</Typography> */}
          </Box>
        </Box>
      </Grid>
    ))}
  </Grid>
);

export default MonthTravelling;
