import React from 'react';
import ReviewDetailsSection1 from '../components/review_details/review_details_section1/ReviewDetailsSection1';

const ReviewDetails = () => (
  <>
    <ReviewDetailsSection1 />
  </>
);

export default ReviewDetails;
