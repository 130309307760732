import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from '@mui/material';
import { placesToExplore } from '../../../constants/SelectedVacationSpotConstant';
import SelectChip from '../SelectChip';

const PlacesToExplore = ({ handleSelectPlace, isSmallScreen, selectedPlace, handleEditTrip }) => (
  <>
    <Grid container spacing={1} sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
      {placesToExplore.map((item, index) => (
        <Grid item xs={6} sm={4} md={3} key={index}>
          <Card
            className="holiday-card"
            sx={{
              position: 'relative',
              overflow: 'visible',
              '&:hover .MuiChip-root': {
                opacity: isSmallScreen ? 0 : 1
              },
              '&:hover .holiday-card-image': {
                transform: !isSmallScreen ? 'scale(1.05)' : 'none',
                transition: 'transform 0.3s ease'
              }
            }}>
            <SelectChip
              className="select-chip"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1,
                opacity: 0,
                transition: '0.3s all ease-out'
              }}
            />
            <CardActionArea onClick={() => handleSelectPlace(item.name)}>
              <CardMedia
                component="img"
                height={isSmallScreen ? 140 : 200}
                image={item.image}
                alt={item.name}
                sx={{
                  objectFit: 'fill',
                  borderRadius: '8px 8px 0px 0px',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: !isSmallScreen ? 'scale(1.05)' : 'none'
                  }
                }}
              />
              <CardContent className="holiday-card-content">
                <Typography className="holiday-card-content-title">{item.name}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
    {selectedPlace && (
      <Box
        mx="auto"
        style={{
          display: 'flex',
          alignItems: 'center',
          height: isSmallScreen ? '45px' : '70px',
          width: !isSmallScreen && '550px',
          marginTop: 20,
          padding: 10,
          border: '1px solid #FFFFFF',
          borderRadius: 8,
          backgroundColor: '#000000'
        }}>
        <CardMedia
          component="img"
          image={placesToExplore.find((place) => place.name === selectedPlace).image}
          alt={selectedPlace}
          className={`${isSmallScreen ? 'small-card-media' : 'regular-card-media'}`}
        />
        <Typography
          className={`${isSmallScreen && 'selected-place-typo-small'}`}
          sx={{
            flexGrow: 1,
            fontFamily: 'inherit',
            fontSize: 15,
            fontWeight: 700,
            lineHeight: '20px',
            textAlign: 'left',
            backgroundColor: '#000000',
            padding: '8px',
            borderRadius: '4px',
            color: '#FFFFFF'
          }}>
          {selectedPlace}! Great Choice! Keep Adding
        </Typography>
        <Button
          variant="outlined"
          className={`${isSmallScreen && 'small-edit-btn'}`}
          style={{
            marginRight: 10,
            color: '#fff',
            borderColor: '#fff',
            boxShadow: '0px 4px 4px 0px #58565659',
            backgroundColor: 'grey'
          }}
          onClick={handleEditTrip}>
          Edit
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: '#f44336',
            color: '#fff',
            width: isSmallScreen && '115.37px',
            height: isSmallScreen && '32.42px'
          }}>
          Continue
        </Button>
      </Box>
    )}
  </>
);

export default PlacesToExplore;
