import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import { api } from '../../APIs/api';

export const fetchRatingCounts = createAsyncThunk('ratingCount/fetchRatingCounts', async () => {
  const response = await api.get(`${BASE_URL}${API_URL.RATING_COUNT}`);
  return response.data.ratingCountsList;
});

export const ratinCountsSlice = createSlice({
  name: 'ratingCounts',
  initialState: {
    ratingCountsList: [],
    status: 'idle',
    error: null
  },
  reducers: {
    getRatingCounts: {
      reducer: (state, action) => {
        state.ratingCountsList.push(action.payload);
        state.ratingCountsList.push(action.payload);
      },
      prepare(value) {
        return {
          payload: {
            key: nanoid(),
            value: value
          }
        };
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRatingCounts.pending, (state) => {
        state.error = null;
        state.status = 'loading';
      })

      .addCase(fetchRatingCounts.fulfilled, (state, action) => {
        state.status = true;
        state.ratingCountsList.push(...action.payload);
      })

      .addCase(fetchRatingCounts.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      });
  }
});

export const { getRatingCounts } = ratinCountsSlice.actions;

export default ratinCountsSlice.reducer;
