import React from 'react';
import './SelectAccomodation.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

const SelectAccomodation = () => (
  <>
    <div className="select-accomodation-container">
      <Card className="cmn-cards">
        <CardHeader className="card-header" title="3. Select Accomodation" />
        <CardContent></CardContent>
      </Card>
    </div>
  </>
);

export default SelectAccomodation;
