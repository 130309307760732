import React from 'react';
import { Box, Checkbox, TextField, Grid, Typography } from '@mui/material';
import ButtonComponnet from './Common/Button';
import './payment.css';

const Payment = () => {
  const itemStyle = {
    border: '0.1px solid #dddddd !important'
  };

  const label = { inputProps: { 'aria-label': 'terms&condition' } };

  return (
    <Box
      component="form"
      className="form-container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mx: 'auto',
        my: 4,
        p: 2,
        backgroundColor: 'white',
        maxWidth: '800px',
        borderRadius: 1,
        boxShadow: 3
      }}>
      <Grid container spacing={1} sx={{ padding: '10px' }}>
        <Grid item xs={12} sm={12} sx={itemStyle}>
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontFamily: 'Gotham',
                margin: '20px',
                padding: '10px',
                border: '5px solid #dddddd',
                borderBottom: '5px solid #ff0000',
                maxWidth: { sm: '30%', xs: '100%', md: '30%', lg: '30%' }
              }}>
              DEBIT/ CREDIT CARD
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          sx={(itemStyle, { background: '#ff0000', color: 'white', fontWeight: '700' })}>
          <Typography
            variant="h6"
            gutterBottom
            className="form-filed-label"
            sx={{ fontFamily: 'inherit' }}>
            Request Details
          </Typography>
        </Grid>

        <Grid item xs={12} sm={5} sx={itemStyle}>
          <Typography
            variant="h6"
            gutterBottom
            className="form-filed-label required-form-field"
            sx={{ fontFamily: 'inherit' }}>
            Customer Name
          </Typography>
        </Grid>

        <Grid item xs={12} sm={7} sx={itemStyle}>
          <TextField
            name="customerName"
            variant="outlined"
            placeholder="Customer Name"
            className="form-filed-grid-item"
          />
        </Grid>

        <Grid item xs={12} sm={5} sx={itemStyle}>
          <Typography
            variant="h6"
            gutterBottom
            className="form-filed-label required-form-field"
            sx={{ fontFamily: 'inherit' }}>
            Mobile Number
          </Typography>
        </Grid>

        <Grid item xs={12} sm={7} sx={itemStyle}>
          <Grid container spacing={1} sx={{ padding: '10px' }}>
            <Grid item xs={3} sm={3}>
              <TextField
                name="countryCode"
                variant="outlined"
                placeholder="91"
                sx={{ width: '90%', padding: '10px', marginRight: '10px' }}
              />
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                name="mobileNumber"
                variant="outlined"
                required
                placeholder="1234567890"
                sx={{ padding: '10px', width: '96%' }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={5} sx={itemStyle}>
          <Typography
            variant="h6"
            gutterBottom
            className="form-filed-label required-form-field"
            sx={{ fontFamily: 'inherit' }}>
            Email
          </Typography>
        </Grid>

        <Grid item xs={12} sm={7} sx={itemStyle}>
          <TextField
            name="email"
            variant="outlined"
            required
            placeholder="your email"
            className="form-filed-grid-item"
          />
        </Grid>

        <Grid item xs={12} sm={5} sx={itemStyle}>
          <Typography
            variant="h6"
            gutterBottom
            className="form-filed-label required-form-field"
            sx={{ fontFamily: 'inherit' }}>
            Tour Code
          </Typography>
        </Grid>

        <Grid item xs={12} sm={7} sx={itemStyle}>
          <TextField
            name="tourCode"
            variant="outlined"
            placeholder="Tour Code"
            className="form-filed-grid-item"
          />
        </Grid>

        <Grid item xs={12} sm={5} sx={itemStyle}>
          <Typography
            variant="h6"
            gutterBottom
            className="form-filed-label required-form-field"
            sx={{ fontFamily: 'inherit' }}>
            Currency Code
          </Typography>
        </Grid>

        <Grid item xs={12} sm={7} sx={itemStyle}>
          <TextField
            name="currencyCode"
            variant="outlined"
            placeholder="Currency Code "
            className="form-filed-grid-item"
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={5} sx={itemStyle}>
          <Typography
            variant="h6"
            gutterBottom
            className="form-filed-label required-form-field"
            sx={{ fontFamily: 'inherit' }}>
            Amount
          </Typography>
        </Grid>

        <Grid item xs={12} sm={7} sx={itemStyle}>
          <TextField
            name="amount"
            variant="outlined"
            placeholder="Amount"
            className="form-filed-grid-item"
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: { xs: 'none', md: 'block', sm: 'block' },
            ...itemStyle
          }}></Grid>

        <Grid item xs={12} sm={7} sx={itemStyle}>
          <Box sx={{ padding: '10px' }}>
            <Typography component="h2" sx={{ fontFamily: 'inherit' }}>
              1. Lorem Ipsum is simply dummy text of the printing
            </Typography>
            <Typography component="h2" sx={{ fontFamily: 'inherit' }}>
              2. Lorem Ipsum is simply dummy text of the printing
            </Typography>
            <Typography component="h2" sx={{ fontFamily: 'inherit' }}>
              3. Lorem Ipsum is simply dummy text of the printing
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={5} sx={itemStyle}>
          <Typography
            variant="h6"
            gutterBottom
            className="form-filed-label"
            sx={{ fontFamily: 'inherit' }}>
            Gross Amt
          </Typography>
        </Grid>

        <Grid item xs={12} sm={7} sx={itemStyle}>
          <TextField
            name="grossAmt"
            variant="outlined"
            placeholder="grossAmt"
            disabled
            className="form-filed-grid-item"
          />
        </Grid>

        <Grid item xs={12} sm={5} sx={itemStyle}>
          <Typography
            variant="h6"
            gutterBottom
            className="form-filed-label required-form-field"
            sx={{ fontFamily: 'inherit' }}>
            Refered By
          </Typography>
        </Grid>

        <Grid item xs={12} sm={7} sx={itemStyle}>
          <TextField
            fullWidth
            name="referedBy"
            variant="outlined"
            className="form-filed-grid-item"
          />
        </Grid>

        <Grid item xs={12} sm={5} sx={itemStyle} className="form-filed-grid-item">
          <Typography
            variant="h6"
            gutterBottom
            className="form-filed-label"
            sx={{ fontFamily: 'inherit' }}>
            Remarks
          </Typography>
        </Grid>

        <Grid item xs={12} sm={7} sx={itemStyle}>
          <TextField name="remarks" variant="outlined" className="form-filed-grid-item" />
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: { xs: 'none', md: 'block', sm: 'block' },
            ...itemStyle
          }}></Grid>

        <Grid item xs={12} sm={7} sx={itemStyle}>
          <Checkbox {...label} />{' '}
          <Typography component="span" color="primary" sx={{ fontFamily: 'inherit' }}>
            I agree to Terms & Conditions{' '}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: { xs: 'none', md: 'block', sm: 'block' },
            ...itemStyle
          }}></Grid>

        <Grid item xs={12} sm={7} sx={itemStyle}>
          <ButtonComponnet />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Payment;
