import React, { useState } from 'react';
import './ReviewAddGuest.css';
import { Card, CardContent, CardHeader, Grid, MenuItem, SvgIcon, TextField } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

const ReviewAddGuest = () => {
  const [adults, setAdults] = useState(4);
  const [children, setChildren] = useState(2);
  const [infants, setInfants] = useState(1);

  const handleIncrement = (type) => {
    switch (type) {
      case 'adults':
        setAdults(adults + 1);
        break;
      case 'children':
        setChildren(children + 1);
        break;
      case 'infants':
        setInfants(infants + 1);
        break;
      default:
        break;
    }
  };

  const handleDecrement = (type) => {
    switch (type) {
      case 'adults':
        if (adults > 0) setAdults(adults - 1);
        break;
      case 'children':
        if (children > 0) setChildren(children - 1);
        break;
      case 'infants':
        if (infants > 0) setInfants(infants - 1);
        break;
      default:
        break;
    }
  };

  const state = [
    { value: 'Andhra Pradesh' },
    { value: 'Bihar' },
    { value: 'Goa' },
    { value: 'Karnataka' },
    { value: 'Madhya Pradesh' }
  ];

  return (
    <>
      <div className="review-add-guest-container">
        <Card className="cmn-cards">
          <CardHeader className="card-header" title="2. Add Guests" />
          <CardContent>
            <div className="plus-minus-list">
              <div className="plus-minus-list-item">
                <div className="detail-box">
                  <h4>Adults</h4>
                  <span>Above 12 yrs</span>
                </div>
                <div className="plus-minus-content-box">
                  <a onClick={() => handleDecrement('adults')}>
                    <SvgIcon>
                      <Remove />
                    </SvgIcon>
                  </a>
                  <span>{adults}</span>
                  <a onClick={() => handleIncrement('adults')}>
                    <SvgIcon>
                      <Add />
                    </SvgIcon>
                  </a>
                </div>
              </div>
              <div className="plus-minus-list-item">
                <div className="detail-box">
                  <h4>Child</h4>
                  <span>Age 2 - 11 yrs</span>
                </div>
                <div className="plus-minus-content-box">
                  <a onClick={() => handleDecrement('children')}>
                    <SvgIcon>
                      <Remove />
                    </SvgIcon>
                  </a>
                  <span>{children}</span>
                  <a onClick={() => handleIncrement('children')}>
                    <SvgIcon>
                      <Add />
                    </SvgIcon>
                  </a>
                </div>
              </div>
              <div className="plus-minus-list-item">
                <div className="detail-box">
                  <h4>Infant</h4>
                  <span>Age 0 - 1 yr</span>
                </div>
                <div className="plus-minus-content-box">
                  <a onClick={() => handleDecrement('infants')}>
                    <SvgIcon>
                      <Remove />
                    </SvgIcon>
                  </a>
                  <span>{infants}</span>
                  <a onClick={() => handleIncrement('infants')}>
                    <SvgIcon>
                      <Add />
                    </SvgIcon>
                  </a>
                </div>
              </div>
            </div>
            <div className="form-content-box">
              <div className="form-content-heading">
                <h5>Lead Traveller Details</h5>
                <p>Enter Details as registered in your Aadhar Card</p>
              </div>
              <Grid container spacing={2}>
                <Grid container item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div className="form-group">
                    <TextField id="outlined-basic" label="First Name" variant="outlined" />
                  </div>
                </Grid>
                <Grid container item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div className="form-group">
                    <TextField id="outlined-basic" label="Last Name" variant="outlined" />
                  </div>
                </Grid>
                <Grid container item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div className="form-group">
                    <TextField
                      id="outlined-basic"
                      type="number"
                      label="Contact No."
                      variant="outlined"
                    />
                  </div>
                </Grid>
                <Grid container item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div className="form-group">
                    <TextField
                      id="outlined-basic"
                      type="email"
                      label="Email Id"
                      variant="outlined"
                    />
                  </div>
                </Grid>
                <Grid container item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div className="form-group">
                    <TextField id="outlined-basic" label="Nationality" variant="outlined" />
                  </div>
                </Grid>
                <Grid container item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div className="form-group">
                    <TextField id="outlined-select-currency" select label="State">
                      {state.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </Grid>
                <Grid container item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div className="form-group">
                    <TextField
                      className="date-picker"
                      id="outlined-basic"
                      type="date"
                      label="Date of Birth"
                      variant="outlined"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default ReviewAddGuest;
