import React from 'react';

import './ReviewPay.css';

import BackArrowIcon from '../../assets/common/back_arrow.svg';
import { Grid, IconButton, Typography } from '@mui/material';
import WomensDetails from './WomensDetails/WomensDetails';
import WhyKesariSelect from '../review_details/why_kesari_select/WhyKesariSelect';
import ReviewCollapsePanel from '../review_details/review_collapse_panel/ReviewCollapsePanel';
import KesariSelectReview from '../home/KesariSelectReview/KesariSelectReview';
import ViewPrice from './ViewPrice/ViewPrice';
import ProceedToPay from './ProceedToPay/ProceedToPay';

const ReviewPay = () => (
  <>
    <div className="review-pay-section-container">
      <Grid container spacing={2} className="pl-pr-120 review-details-sec-1">
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="d-flex align-items-center review-page-title-sec">
            <IconButton className="icon" aria-label="goback" color="secondary">
              <img src={BackArrowIcon} alt="Not Available Now" />
            </IconButton>
            <div className="page-title-group">
              <Typography variant="h1" className="title">
                Review & Pay
              </Typography>
              <div className="description">You can review your tour details & make payment.</div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="two-column-design pl-pr-120">
        <Grid container item xs={12} sm={12} md={7} lg={8} xl={9}>
          <div className="left-part component-list">
            <div className="womens-special-part component-list-item">
              <WomensDetails />
            </div>
            <div className="why-kesari-select-part component-list-item">
              <WhyKesariSelect />
            </div>
            <div className="faqs-part component-list-item">
              <ReviewCollapsePanel />
            </div>
          </div>
        </Grid>
        <Grid container item xs={12} sm={12} md={5} lg={4} xl={3}>
          <div className="right-part component-list">
            <div className="view-price-part component-list-item">
              <ViewPrice />
            </div>
            <div className="proceed-to-pay-part component-list-item">
              <ProceedToPay />
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="kesari-select-review-container">
        <Grid container spacing={2} className="two-column-design pl-pr-120">
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="kesari-select-review-part component-list-item">
              <KesariSelectReview />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  </>
);

export default ReviewPay;
