import React, { useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  styled
} from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import './SelectDepartureCityDate.css';

const CustomRadioButtonUncheckedIcon = styled(RadioButtonUnchecked)(({ theme }) => ({
  color: theme.palette.grey[400]
}));

const SelectDepartureCityDate = () => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Card className="cmn-cards">
      <CardHeader className="card-header" title="1. Select Departure City & Date" />
      <CardContent>
        <div className="radio-chip-group">
          <FormControl component="fieldset">
            <RadioGroup row value={value} onChange={handleChange}>
              <FormControlLabel
                value="All Departures (10)"
                control={
                  <Radio icon={<CustomRadioButtonUncheckedIcon />} checkedIcon={<CheckCircle />} />
                }
                label="All Departures (10)"
              />
              <FormControlLabel
                value="Mumbai (4)"
                control={
                  <Radio icon={<CustomRadioButtonUncheckedIcon />} checkedIcon={<CheckCircle />} />
                }
                label="Mumbai (4)"
              />
              <FormControlLabel
                value="Pune (4)"
                control={
                  <Radio icon={<CustomRadioButtonUncheckedIcon />} checkedIcon={<CheckCircle />} />
                }
                label="Pune (4)"
              />
              <FormControlLabel
                value="New Delhi (4)"
                control={
                  <Radio icon={<CustomRadioButtonUncheckedIcon />} checkedIcon={<CheckCircle />} />
                }
                label="New Delhi (4)"
              />
              <FormControlLabel
                value="Banglore (4)"
                control={
                  <Radio icon={<CustomRadioButtonUncheckedIcon />} checkedIcon={<CheckCircle />} />
                }
                label="Banglore (4)"
              />
              <FormControlLabel
                value="Kolkata (4)"
                control={
                  <Radio icon={<CustomRadioButtonUncheckedIcon />} checkedIcon={<CheckCircle />} />
                }
                label="Kolkata (4)"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="custom-calendar-part">
          <div className="seat-remark">Only 2 Seats left, lock in today!</div>
          <div className="custom-calendar row">
            <Box component="button" className="month-card-sec">
              <Box className="month-card">
                <div className="month">Mar</div>
                <div className="year">2024</div>
              </Box>
            </Box>
            <Box component="button" className="day-card-sec disabled">
              <Box className="day-card">
                <div className="week">Mon</div>
                <div className="day-amount">
                  <div className="date">25</div>
                  <div className="amount">₹2,00,000</div>
                </div>
              </Box>
            </Box>
            <Box component="button" className="day-card-sec disabled">
              <Box className="day-card">
                <div className="week">Tues</div>
                <div className="day-amount">
                  <div className="date">26</div>
                  <div className="amount">₹2,05,000</div>
                </div>
              </Box>
            </Box>
            <Box component="button" className="day-card-sec disabled">
              <Box className="day-card">
                <div className="week">Wed</div>
                <div className="day-amount">
                  <div className="date">27</div>
                  <div className="amount">₹2,05,000</div>
                </div>
              </Box>
            </Box>
            <Box component="button" className="day-card-sec disabled">
              <Box className="day-card">
                <div className="week">Fri</div>
                <div className="day-amount">
                  <div className="date">28</div>
                  <div className="amount">₹2,10,000</div>
                </div>
              </Box>
            </Box>
            <Box component="button" className="day-card-sec disabled">
              <Box className="day-card">
                <div className="week">Sat</div>
                <div className="day-amount">
                  <div className="date">29</div>
                  <div className="amount">₹2,10,000</div>
                </div>
              </Box>
            </Box>
            <Box component="button" className="day-card-sec active">
              <Box className="day-card">
                <div className="seats">2 Seats</div>
                <div className="week">Sun</div>
                <div className="day-amount">
                  <div className="date">30</div>
                  <div className="amount">₹2,15,000</div>
                </div>
              </Box>
            </Box>
            <Box component="button" className="month-card-sec">
              <Box className="month-card">
                <div className="month">Apr</div>
                <div className="year">2024</div>
              </Box>
            </Box>
            <Box component="button" className="day-card-sec">
              <Box className="day-card">
                <div className="week">Mon</div>
                <div className="day-amount">
                  <div className="date">01</div>
                  <div className="amount">₹2,00,000</div>
                </div>
              </Box>
            </Box>
            <Box component="button" className="day-card-sec">
              <Box className="day-card">
                <div className="week">Tus</div>
                <div className="day-amount">
                  <div className="date">02</div>
                  <div className="amount">₹2,05,000</div>
                </div>
              </Box>
            </Box>
            <Box component="button" className="day-card-sec">
              <Box className="day-card">
                <div className="week">Wed</div>
                <div className="day-amount">
                  <div className="date">03</div>
                  <div className="amount">₹2,05,000</div>
                </div>
              </Box>
            </Box>
            <Box component="button" className="day-card-sec">
              <Box className="day-card">
                <div className="week">Fri</div>
                <div className="day-amount">
                  <div className="date">04</div>
                  <div className="amount">₹2,05,000</div>
                </div>
              </Box>
            </Box>
          </div>
        </div>
      </CardContent>
      <CardActions className="card-footer">
        <ul>
          <li>Terms & Conditions apply.</li>
          <li>5% GST is applicable on given tour price.</li>
          <li>Mentioned tour prices are Per Person for Indian Nationals only.</li>
        </ul>
      </CardActions>
    </Card>
  );
};

export default SelectDepartureCityDate;
