import { Box, Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';

const CustomBreadcrumbs = ({ breadcrumbsData, onBreadcrumbClick }) => (
  <Breadcrumbs
    separator={<ChevronRight />}
    aria-label="breadcrumb"
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      '& .MuiBreadcrumbs-separator': { margin: 0 }
    }}>
    {breadcrumbsData.map((breadcrumb, index) => {
      const isLast = index === breadcrumbsData.length - 1;
      const breadcrumbContent = (
        <Box
          component="span"
          sx={{ display: 'flex', alignItems: 'center', fontFamily: 'gothamBook' }}>
          {breadcrumb.label}
        </Box>
      );

      const handleClick = () => {
        if (index === 1) {
          onBreadcrumbClick();
        }
      };

      if (isLast) {
        return (
          <Typography
            key={index}
            fontSize={14}
            lineHeight={'24px'}
            fontFamily="gothamBook"
            color={'#000000CC'}>
            {breadcrumbContent}
          </Typography>
        );
      } else {
        return (
          <Typography
            component={Link}
            key={index}
            to={breadcrumb.path}
            color="#000000CC"
            fontSize={14}
            lineHeight={'24px'}
            fontFamily="gothamBook"
            sx={{ textDecoration: 'none !important' }}
            onClick={handleClick}>
            {breadcrumbContent}
          </Typography>
        );
      }
    })}
  </Breadcrumbs>
);

export default CustomBreadcrumbs;
