import { Close } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';

const Pill = ({ title, onClick }) => {
  const handleRemoveClick = (event) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      border="1px solid #0000001F !important"
      width="fit-content"
      height="fit-content"
      px="15px"
      py="7px"
      gap={2}
      bgcolor="#00000005"
      onClick={onClick}
      sx={{ borderRadius: '22px !important' }}>
      <Typography color="#000000D9" fontSize={13} fontFamily="poppinsRegular" fontStyle="13px">
        {title}
      </Typography>
      <Close
        sx={{ width: '16px', height: '16px', cursor: 'pointer' }}
        onClick={handleRemoveClick}
      />
    </Box>
  );
};

export default Pill;
