import axios from 'axios';
import { BASE_URL } from './endpoint';
import { getCampaignDetails } from '../Helper/campaignUtils';

export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  (config) => {
    const campaignDetails = getCampaignDetails();
    let isAuthRoute = config.url.includes('login') || config.url.includes('singup');

    config.params = { ...config.params, ...campaignDetails };
    if (!isAuthRoute) {
      const token = localStorage.getItem('access_token');
      if (token && token !== 'undefined') {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    if (response.data?.error) {
      return Promise.reject(response.data);
    }
    return response.data;
  },
  (error) => Promise.reject(error)
);
