// Static Side Filters for package list page
export const TourBudgetFilterINR = ['Below ₹1.2L', '₹1.2L - ₹3.2L', '₹3.2L - ₹5.2L', 'Above ₹5.2L'];
export const TourBudgetFilterUSD = [
  'Below $1,000',
  '$1,000 - $3,000',
  '$3,000 - $5,000',
  'Above $5,000'
];
export const TourBudgetFilterEUR = [
  'Below €1,000',
  '€1,000 - €3,000',
  '€3,000 - €5,000',
  'Above €5,000'
];
export const TourBudgetFilterAED = [
  'Below د.إ1,000',
  'د.إ3,000 - د.إ1,000',
  'د.إ5,000 - د.إ3,000',
  'Above د.إ5,000'
];
export const TourBudgetFilterAUD = [
  'Below A$1,000',
  'A$1,000 - A$3,000',
  'A$3,000 - A$5,000',
  'Above A$5,000'
];
export const TourBudgetFilterGBP = [
  'Below £1,000',
  '£1,000 - £3,000',
  '£3,000 - £5,000',
  'Above £5,000'
];
export const TourBudgetFilterSGD = [
  'Below S$1,000',
  'S$1,000 - S$3,000',
  'S$3,000 - S$5,000',
  'Above S$5,000'
];
export const TourBudgetFilterNZD = [
  'Below NZ$1,000',
  'NZ$1,000 - NZ$3,000',
  'NZ$3,000 - NZ$5,000',
  'Above NZ$5,000'
];

export const TourDurationFilter = ['3 - 7 Days', '7 - 11 Days', '11 - 15 Days', 'Above 15 Days'];

export const AdventuresFilter = [
  'Beach',
  'Surfing',
  'Sailing',
  'Hiking & Trekking',
  'Desert',
  'Hill Stations',
  'River Cruise'
];

//Sort By Filter Optiops for Package-listing screen
export const sortByFilterArray = [
  'Price Low to High',
  'Price High to Low',
  'New Packages',
  'Popular'
];

export const faqAccordionData = [
  {
    title: 'When is the best time to visit Italy?',
    content: [
      'Avoid the summer crowds and enjoy the pleasant spring and fall weather when traveling to Europe from mid-April through May, September, and early October. These shoulder season months are some of the best times to visit Europe, particularly countries in the Mediterranean.',
      'Winter’s off-season months, particularly mid-December through early January, are also great options for European Christmas tours, winter sports, and seeing the snow. Whether you want to spend your summer on the Greek islands, explore Paris in the springtime or in the fall, or visit Italy’s Christmas markets in winter, we can help you find the perfect time to travel to Europe.'
    ]
  },
  {
    title: 'Where should I go on my first trip to Europe?',
    content: [
      'Avoid the summer crowds and enjoy the pleasant spring and fall weather when traveling to Europe from mid-April through May, September, and early October. These shoulder season months are some of the best times to visit Europe, particularly countries in the Mediterranean.',
      'Winter’s off-season months, particularly mid-December through early January, are also great options for European Christmas tours, winter sports, and seeing the snow. Whether you want to spend your summer on the Greek islands, explore Paris in the springtime or in the fall, or visit Italy’s Christmas markets in winter, we can help you find the perfect time to travel to Europe.'
    ]
  },
  {
    title: 'Where should I go on my first trip to Europe?',
    content: [
      'Avoid the summer crowds and enjoy the pleasant spring and fall weather when traveling to Europe from mid-April through May, September, and early October. These shoulder season months are some of the best times to visit Europe, particularly countries in the Mediterranean.',
      'Winter’s off-season months, particularly mid-December through early January, are also great options for European Christmas tours, winter sports, and seeing the snow. Whether you want to spend your summer on the Greek islands, explore Paris in the springtime or in the fall, or visit Italy’s Christmas markets in winter, we can help you find the perfect time to travel to Europe.'
    ]
  },
  {
    title: 'Where should I go on my first trip to Europe?',
    content: [
      'Avoid the summer crowds and enjoy the pleasant spring and fall weather when traveling to Europe from mid-April through May, September, and early October. These shoulder season months are some of the best times to visit Europe, particularly countries in the Mediterranean.',
      'Winter’s off-season months, particularly mid-December through early January, are also great options for European Christmas tours, winter sports, and seeing the snow. Whether you want to spend your summer on the Greek islands, explore Paris in the springtime or in the fall, or visit Italy’s Christmas markets in winter, we can help you find the perfect time to travel to Europe.'
    ]
  },
  {
    title: 'Where should I go on my first trip to Europe?',
    content: [
      'Avoid the summer crowds and enjoy the pleasant spring and fall weather when traveling to Europe from mid-April through May, September, and early October. These shoulder season months are some of the best times to visit Europe, particularly countries in the Mediterranean.',
      'Winter’s off-season months, particularly mid-December through early January, are also great options for European Christmas tours, winter sports, and seeing the snow. Whether you want to spend your summer on the Greek islands, explore Paris in the springtime or in the fall, or visit Italy’s Christmas markets in winter, we can help you find the perfect time to travel to Europe.'
    ]
  }
];
