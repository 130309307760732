import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { CloseRounded, SearchRounded } from '@mui/icons-material';
import '../components/conversational/conversational.css';
import LocationTabs from '../components/conversational/LocationTabs/LocationTabs';

const Conversational = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTourPlace = location?.state?.selectedTour;
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Card className="conversational_main">
      <Container maxWidth="lg">
        <Box textAlign="end">
          <IconButton
            aria-label="close"
            onClick={() => navigate(-1)}
            sx={{ color: (theme) => theme.palette.grey[500] }}>
            <CloseRounded />
          </IconButton>
        </Box>
        <Grid container spacing={2} style={{ alignItems: 'center', flexDirection: 'column' }}>
          <Grid item xs={12}>
            <Typography className="heading-text">Select next vacation spot?</Typography>
            <Typography className="heanding-text2">
              Not sure where to go?
              <span className="heanding-text2 link-like">We can help you decide</span>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ width: '100%', maxWidth: '400px' }}>
            <TextField
              className="pick-destination"
              fullWidth
              placeholder="Pick your Destination"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRounded className="search-icon" />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <LocationTabs searchQuery={searchQuery} selectedTourPlace={selectedTourPlace} />
        </Grid>
      </Container>
    </Card>
  );
};

export default Conversational;
