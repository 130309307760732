import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import { api } from '../../APIs/api';

export const fetchPackageListing = createAsyncThunk(
  'packageListing/fetchPackageListing',
  async () => {
    const response = await api.get(`${BASE_URL}${API_URL.GET_PACKAGE_LIST}`);
    return response.data;
  }
);

const filterUniquePackages = (existingPackages, newPackages) => {
  const packageNames = existingPackages.map((pkg) => pkg.package_name || '');
  return newPackages.filter((pkg) => !packageNames.includes(pkg.package_name || ''));
};

export const packageListingSlice = createSlice({
  name: 'packageList',
  initialState: {
    packageList: [],
    status: 'idle',
    error: null
  },
  reducers: {
    getPackageListing: {
      reducer: (state, action) => {
        const uniquePackages = filterUniquePackages(state.packageList, [action.payload]);
        state.packageList.push(...uniquePackages);
      },
      prepare(value) {
        return {
          payload: {
            key: nanoid(),
            value: value
          }
        };
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackageListing.pending, (state) => {
        state.error = null;
        state.status = 'loading';
      })
      .addCase(fetchPackageListing.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          const uniquePackages = filterUniquePackages(state.packageList, action.payload);
          state.status = 'succeeded';
          state.packageList.push(...uniquePackages);
        } else {
          state.status = 'failed';
          state.error = 'Invalid data format';
        }
      })
      .addCase(fetchPackageListing.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { getPackageListing } = packageListingSlice.actions;

export default packageListingSlice.reducer;
