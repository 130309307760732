import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import KesariDivision from '../assets/Images/kesari-division.webp';
import KesariTnC from '../assets/Images/kesari-tnc.webp';

const TermsAndCondition = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const rows = [
    { days: '45 - 30 working days', charge: 'Booking Deposit' },
    { days: '29 - 15 working days', charge: '50% of Tour cost' },
    { days: '15 - 8 working days', charge: '75% of Tour cost' },
    { days: '7 or lesser working days', charge: '100% of Tour cost' }
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ borderBottom: '2px solid #A52A2A', padding: '20px 0' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={KesariDivision} alt="Kesari Logo" style={{ width: '100px' }} />
          </Box>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: '#9c1c24',
              textAlign: 'center',
              flexGrow: 1
            }}>
            Terms and Conditions of Kesari Select
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={KesariTnC} alt="Kesari Select Logo" style={{ width: '120px' }} />
          </Box>
        </Box>
        <Typography
          sx={{ fontSize: '30px', fontWeight: 'bold', color: '#9c1c24', textAlign: 'center' }}>
          Division of KESARI TOURS PVT. LTD.
        </Typography>
      </Box>

      <Box sx={{ marginTop: 4 }}>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Dear Guests,
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Thank you for choosing Kesari Select as your holiday planner!
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          FIT No:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Kesari Select is a division of KESARI TOURS PVT. LTD. offering premium customized holiday
          packages. We make every effort to provide you with the best services in a pleasant and
          friendly atmosphere at all times. We strive for complete satisfaction and endeavor to
          provide a safe & secure journey to the best of our ability without any misunderstandings
          between your good selves & Kesari Select. We believe in forgiveness, gratitude, positive
          attitude, and innovation and at times even go beyond legal formats to ensure that all the
          issues of guests are resolved in a timely and friendly manner. The Terms and Conditions -
          hereinafter referred to as Terms and Conditions given hereunder and on our website, the
          tour registration form, any other printed material, and Brochures, together form the basis
          of our agreement and understanding with you. You are requested to read, understand and
          sign in acceptance of all the Terms and Conditions and the Brochure before you register
          yourself/family/friends for any of the packages and/or our services. It is imperative that
          all the guests who are availing our services abide by and strictly comply and observe all
          the terms and conditions laid down herein.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          B. General Explanations:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          i. Each provision Term of these Terms and Conditions shall be severable and if any
          provision thereof is declared invalid, illegal or unenforceable, then remaining provisions
          nevertheless shall have full force & effect.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          ii. The masculine gender shall also include the feminine gender and vice versa; the
          singular shall include plural and vice versa and shall include grammatical variations.
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          1. Short Title, Extent and Commencement:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          i. These Terms and Conditions shall be called Kesari Select Terms and Conditions.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          ii. Terms and Conditions extend to the whole of India and World Tours organized by the
          Kesari Select division of Kesari Tours Private Limited - hereinafter referred to as Kesari
          Select.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          iii. Terms and Conditions shall come into force from April 1, 2018 & it will remain in
          force till further modification or amendment.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          A. Definition:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          In these Terms and Conditions unless the context otherwise requires:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          i.{' '}
          <Typography component="span" variant="body1" sx={{ fontFamily: 'gothamBold' }}>
            'Brochure'
          </Typography>{' '}
          for the respective years brought out/published by Kesari Select.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          ii. ‘First day of the tour’ means the day on which your tour starts.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          iii.{' '}
          <Typography component="span" variant="body1" sx={{ fontFamily: 'gothamBold' }}>
            'Force Majeure’{' '}
          </Typography>
          means an event or circumstance not within the reasonable control, directly or indirectly,
          of the Company in its ability to perform its obligations/responsibilities regarding the
          booking of services including (a) war, hostilities, invasion, act of foreign enemies; (b)
          rebellion, revolution, insurrection or military or usurped power or civil war; (c)
          epidemic, explosion, fire, flood, earthquake or other exceptional natural calamity and act
          of God; (d) ionizing radiation or contamination by radioactivity from any nuclear fuel, or
          from any nuclear waste from the combustion of nuclear fuel, radioactive toxic explosive,
          or other hazardous properties of any explosive nuclear assembly or nuclear component of
          such assembly; (e) riot, commotion or disorder; (f) any act or omission of any Government
          instrumentality; (g) a Change in Legal Requirements; (h) strikes, lockouts or other
          industrial disturbances; and (i) abnormal weather or sea conditions including tempest,
          lightning, hurricane, typhoon, rain and temperatures.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          iv.{' '}
          <Typography component="span" variant="body1" sx={{ fontFamily: 'gothamBold' }}>
            'Guests'
          </Typography>{' '}
          means the person in whose Name or on whose behalf booking and/or registration is made for
          any of the FIT services organized by Kesari Select.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          v.{' '}
          <Typography component="span" variant="body1" sx={{ fontFamily: 'gothamBold' }}>
            ‘Independent Contractor’{' '}
          </Typography>
          means a person and/or organization selected by Kesari Select to render services including
          transport, hotel, restaurant, sightseeing, VISA, tour assistant, Insurance, etc., to the
          guests and/or to Kesari Select. Independent Contractor is not an employee, partner or
          joint venture of Kesari Select.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          vi.{' '}
          <Typography component="span" variant="body1" sx={{ fontFamily: 'gothamBold' }}>
            {' '}
            ‘Maal’
          </Typography>{' '}
          means breakfast or lunch or dinner or any other snacks supplied.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          vii.{' '}
          <Typography component="span" variant="body1" sx={{ fontFamily: 'gothamBold' }}>
            {' '}
            ‘Tour Escort / Leader / Assistant’
          </Typography>{' '}
          by whatever designation called means a person designated by the Company on request of
          guest/s to help/guide/assist the guests in or during guests travel within India.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          B. General Explanations:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          ii. The masculine gender, shall also include feminine gender and vice versa, the singular
          shall include plural and vice versa and shall include grammatical variations.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          ii. The masculine gender, shall also include feminine gender and vice versa, the singular
          shall include plural and vice versa and shall include grammatical variations.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          2. Scope of Activity:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Kesari Select coordinates all the services of the travel to help the guests undertake the
          travel. Kesari Select takes utmost care in selecting all the services and/or Independent
          Contractors who provide their services, to make our guests comfortable during travel.
          Kesari Select selects various aspects of the travel such as hotel, restaurant, airline,
          coach, shipping company etc. and does not have any control over operating of the same.
          Though Kesari Select takes utmost care of our guests, Kesari Select shall not be held
          responsible for any loss, harm, injury, death, damage caused by default, omission or act
          of employees or management of any such agencies or Independent Contractors. All the guests
          booking & or registering for travel with our organized/proposed to be organized by Kesari
          Select shall read, understand and abide by and strictly comply and observe all the terms
          and conditions laid down herein or in any/all other publications by Kesari Select. We
          endeavor to update our guests from time to time by sending the guests various travel
          related instructions through sms/emails/telephone/or by any other mode of communication,
          solely for the convenience of the guests. However, guests are advised to visit the website
          of Kesari Select www.kesariselect.in to keep yourself updated with the Terms and
          Conditions.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          3. Brochure Information:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Brochure along with tour tariff is published by Kesari Select from time to time. All
          previous Brochures, letters and any other modes of circulation printed and /or published
          by Kesari Select shall stand cancelled on publication of Latest Brochure. Every effort is
          made to ensure Brochure accuracy, based on the available information at the time of
          publication of the brochure. If the time table, tour conditons and the situations requires
          then Kesari Select requires to change any brochure information, without assigning any
          reasons. Kesari Select reserves the right to change any brochure information including
          tour itinerary, products, services displayed on brochure / website etc. Kesari Select
          shall endeavor to inform the guest/s of such changes as soon as possible.Photos, meals,
          sight seeing properties are for reference only and may differ from actual.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          4. Booking:
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          i. Guests signature on the FIT Booking / Registration form:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          shall mean acceptance of the ‘Terms and Conditions’ contained herein by the guest/s in
          totality of the Terms and Conditions contained herein. In case of one or more, but not all
          guests signing the ‘Booking Form’, it shall be deemed that the others have duly authorised
          the concerned signing guests. There is no contract between the Kesari Select and guests
          until the Kesari Select has received the initial registration deposit in accordance with
          the company procedures. The initial deposit only ensures participation for travel services
          but does not entitle any services such as air tickets, visas, hotel accommodations, until
          the full payment has been received.The full payment must be received in accordance with
          the procedures laid down in this brochure. If full payment is not paid in time, the
          Company reserves the right to cancel the booking with subsequent loss of deposit and apply
          the cancellation charges as mentioned herein.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          ii. Third party Operations:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          In the event you are booking through us a tour/travel service of any 3rd party operators
          like insight vacations, star cruise etc. The terms and conditions of such third party
          operators including their payment schedule, cancellation, refund shall be applicable to
          guests in addition to Kesari Select terms and conditions.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          iii. Non Payment of Package Price:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          As per above mentioned the terms and conditions, on failure of full Package Price, we
          shall forfeit the advance received and in such cases refund shall not be entertained for
          advances/ deposits, if any and we reserves the right to take appropriate legal actions
          against you/guests.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          iv. Health and Insurance:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          It is advisable & highly recommended to acquire adequate Domestic/ Overseas Travel
          Insurance cover for protection from any expenses such as medical, legal, hospitalization,
          accident etc. For most of the European countries, it is mandatory to obtain an Overseas
          Travel Insurance policy for the tour.Insurance claim is at the sole discretion of the
          Insurance Company. Strathe right to ask guest to provide written certification of his
          medical fitness before departure. In the event that a medical condition has not been
          disclosed, the Company will not be able to provide any assistance or money back and no
          further claims in this regard will be entertained. Guests are responsible for their own
          baggage. The Package price does not includes Travel insurance cost.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          5. Payment by Guest:
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          i. Package Price:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Please refer to your Package Cost for the Inclusions & Exclusions. The price quoted in our
          proposal or brochure has been calculated as per the prevailing rate. Kesari Select
          reserves the right to amend/alter the price published in the proposal / brochure in case
          of supplier rate fluctuations,currency fluctuations due to changes in the various cross
          rates of exchange, changes in various taxes,visa fees and/or fuel costs, before the date
          of departure. Any such increase in price must be paid in full before tour departure by the
          guest. In exceptional cases any increase in price on the tour must be paid in full by the
          guest on tour. Cancellation or Amendment by the Company -We reserve our right to amend or
          cancel the tour booked by you, without assigning any reason. Such amendment or
          cancellation may be due to circumstances beyond our control. In such cases,we will offer
          you alternative feasible tour options. However, we shall not not be responsible or liable
          to pay any compensation or damages consequential loss or to refund any other expense
          incurred by you. If guests /you fails to pay Package Price timely, We reserved the right
          to cancel the booking/ registration. In case of billing errors, Company reserves the right
          to re-invoice. We reserves right to take necessary legal action in respect of dishonored
          cheques issued by the guest/s in favour of us and charges for dishonour of cheque shall be
          paid by guests only. If the tour is bocked within 45 days from the date of departure, then
          guests are liable to make all tour payment tariff by demand draft/cash within 3 working
          days from the date of booking. All tour tariff inclusive or exclusive of Taxes (GST to be
          paid as applicable by government)
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          ii. Foreign Exchange:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          With a view to provide single window facility to the guest/s,Company is an authorized
          Money Changer of Reserve Bank of India to make arrangement for the foreign exchange
          requirements of the guests.To avail the foreign exchange required by guest/s for the tour,
          the quest/s shall have to submit at least 45 working days before the departure of the
          tour. “Applications cum Declaration for release of Foreign Exchange”, Passport Copy,
          Address Proof and other required document along with amount equivalent at the rate of
          exchange prevailing on the day. When the payment equivalent in Rupees of foreign exchange
          prevailing on the day. When the payment equivalent in Rupees of foreign exchange component
          of the package price of the tour and additional forex for their personal requirements for
          the tour, if any, by Demand Draft / Local clearing cheques in favour of “Kesari SelectD/O
          Kesari Tours Private Limited” is credited in account of Kesari, the foreign exchange shall
          be released. Other service charges shall be applicable as per Company Policy. All World
          Tours are subject to RBI / Government of India approval. Such Foreign Exchange shall be
          subject to availability in India and acceptance of the same in other countries.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          iii. Airlines
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          We shall in no circumstances whatscever be liable to you or any person travelling with
          you, for loss of baggage by the airline, failure to provide meal of your choice by the
          airline, denied boarding or down-gradation due to overbooking or any other reason, failure
          on the part of the airline to accommodate you despite having confirmed tickets, quality or
          quantity of meals offered by the airlines, flight delays or rescheduling, flight
          cancellation, changes of flight schedule or routing, change of airline mentioned at the
          time of booking etc. In these circumstances, we will not be liable for the injury, loss or
          inconvenience suffered by you but you will be free to pursue your remedies against the
          concerned airline.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          iv. Reimbursement to Kesari Select{' '}
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            of payment due and payable by the guest but made on his behalf by Kesari Select :Over
            and above the tour tariff paid by the guests if Kesari Select pays any money for and on
            behalf of the guest/s as per the requirement under law or otherwise, the same shall be
            reimbursed by the concerned guests to Kesari Select forth with on demand by Kesari
            Select.
          </Typography>
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          v. Direct payment to Kesari Select bank account:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Guest should inform Kesari Select for any payment made by them to Kesari Select bank
          account directly or through payment gateway within 24 hours with valid proof of payment by
          hardcopy /email. Payment receipt will be issued only after intimation by the guest to
          Kesari Select.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          6. Document:
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          i. Visa (World tours):
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          It is the responsibility of the guest/you/Guest to hold valid travel documents, such as
          passport and valid visa and to furnish all documents required by the company for the
          application of the same within the period stipulated. It is at the sole discretion of the
          Consulate/Authorities to demand for original documents/personal interview of the guest/s
          at any time. However, as granting or rejecting visa is the sole prerogative of the
          concerned Consulate/Authorities even after submitting all relevant documents, the Company
          will not be held responsible for the same. In case the Visa application is rejected by the
          concerned embassy/consulate or authorities, due to incomplete / delayed documents
          furnished by the guests or due to any other reason whatsoever, or there are errors in the
          date of visa granted or scanned photographs on the visa, the Company shall not be liable
          or responsible for the same. All costs & charges in respect of the said application for
          the visa, shall be borne by the guest.If the guest needs to cancel a tour due to rejection
          of visa or rejection of entry into the country, the cancellation charges of the Company
          will be applicable and shall be payable by the guest. There will be no refund if the
          guest, or any member of his party, is unable to travel clue to the personal reasons.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          ii. Guests Responsibility:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          It shall be sole responsibility of the guests to hold required valid travel documents and
          statutory Clearances, including passports, visas, confirmed travel tickets, insurance
          including medical insurance certificates, any other document used to confirm the services
          of tour including an arrangement with independent contractor and other statutory
          certificates, to enable him/her to undertake tour. All travel documents are
          non-transferable and must be issued in the name of the passport/photo identity holder,
          otherwise the booking may be cancelled. Passport must be valid from the start of the tour
          to at least 180 days subsequent to the scheduled arrival date of the tour - subject to
          rules of the specific visa embassy or consulate. The guests shall ensure timely collection
          of all such documents enabling them to undertake tour and be present in time for
          departure. Guests should keep certified true copies and photocopies of all documents
          including valid travel documents and statutory Clearances, including passports, visas,
          confirmed travel tickets, insurance including medical insurance certificates,any other
          document used to confirm the services of tour including an arrangement with independent
          contractor and other statutory certificates, used by him/her while on tour.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          iii. Photo identification:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          All guests travelling by air for Indian / domestic sectors are advised to carry the photo
          identification in the form of driving license, election ID card, Aadhar Card, Pan card. At
          certain sightseeing places/hotel, the identification proof is required for which Kesari
          Select has no control and is mandatory. Guests are required to carry photo identification
          as required by the rules & regulations of that concern country. Such rules varies from
          time to time and country to country and available on official website of the countries.In
          case of Nepal and Bhutan Tours following Photo identification documents are required :
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          a.{' '}
          <Typography component="span" variant="body1" sx={{ fontFamily: 'gothamBold' }}>
            For Adult:
          </Typography>{' '}
          Voting Card (Election Card) in ORIGINAL OR Valid Passport with 6 months validity from the
          date of return in ORIGINAL subject to Government Laws.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          b.{' '}
          <Typography component="span" variant="body1" sx={{ fontFamily: 'gothamBold' }}>
            For Child:
          </Typography>{' '}
          Valid Passport with 6 months validity from the date of return in ORIGINAL OR School Photo
          ID with Birth certificate in ORIGINAL and it is mandatory that an adult to accompany a
          child for Nepal and Bhutan.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          c.{' '}
          <Typography component="span" variant="body1" sx={{ fontFamily: 'gothamBold' }}>
            For Infant:
          </Typography>{' '}
          Valid Passport with 6 months validity from the date of return in ORIGINAL OR Birth
          Certificate in ORIGINAL with atleast 1 Parent (father or mother) carrying all valid Photo
          identification documents mentioned above.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          <Typography component="span" variant="body1" sx={{ fontFamily: 'gothamBold' }}>
            Note:
          </Typography>{' '}
          For Bhutan Original Passport OR Original Birth Certificate only in English Language.If the
          Birth certificate is in regional language then affidavit in English is mandatory.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          7. Itinerary Changes:
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          i. The suggested itinerary:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          The suggested itinery is based on information & guidance from local agents / hotels. The
          Itinerary is subject to change due to unavoidable circumstances. guest/s are required to
          co-ordinate with the driver for Indian Holidays / Ground handling agents for World
          Holidays and plan your next day's visit and sightseeing program. The car / coach are based
          on time limits. YOUR TRAVEL AGENT CAN SIGN
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          ii. Force measure situation:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          In case of any natural calamities or any situation under act of God Company / We / Us /
          Kesari Select D/O Kesari Tours Pvt. Ltd. Will not be responsible for any changes in
          itinerary. and not entitled for any refund for any particular services. All the additional
          expenses may occur will be borne by you.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          Il. Amendment:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          After the initial booking on a particular package and related services in the event of any
          amendment you wish to make such as change of destination, departure dates, addition of
          deletion of services, change in guest numbers / name or such other change you will need to
          make a written request and obtain an acknowledgment from our sales executive. Amendment
          charges shall be applicable as the case maybe. The amendment may invite further cost which
          shall be borne by you further such requests are accepted by us subject to availability
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          8. Vehicle/Coach:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          We take utmost care in selecting the service providers. However, we do not own or control
          any vehicles and do not employ any chauffer. Any additional usage of vehicle other than
          specified in the itinerary will attract an additional cost and must be settled on tour
          directly. And we shall not be responsible for any additional cost. Any damages caused by
          you to the vehicle/coach during the travel shall be payable by you and the Kesari Select
          will not be liable for the same.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          9. Hotels/Room:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          I. We take utmost care in selecting the hotels keeping your comfort in mind. Most of the
          rooms have a private bath or shower and they may be located in the city centre or away
          from the city centre. Air-conditioning, central heating and other facilities are solely at
          the hotelier's discretion, dependent upon factors such as weather and local conditions
          etc. Triple rooms are not larger than twin rooms and the third bed is often a roll-away
          mattress put in a twin bedded room. guest/s will have to abide by the check-in and
          check-out time of the hotels. For Indian tours, the check-in time is after 12 noon and
          check-out time is 10 a.m. and for world tours, the check after-in time for the hotels is 3
          p.m. and check-out time for the hotel is at 12 noon or earlier depending on the
          destination. Early arrivals and late departures are subjected to availability and may
          attract an additional cost.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          Il. Accommodation for children below 12 years
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Child below 12 years of age paying special rate without a bed will not be provided with a
          bed through out the tour.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          10. Meals:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Any meals included in the package will be indicated in the service vouchers. In case the
          guest misses any meal due to whatsoever reason no refund will be given for the same. We
          have no control over the nature of meals provided by the concerned independent
          supplier(s).The hotels reserved the rights to change the meal type. As per hotel
          occupancy. Eg. In case your package is booked for buffet meals & if the hotel occupancy is
          below 50% hotel can change meal type to al-a carte instead of buffet. As per the itinerary
          if you are checking out early at breakfast time. hotel will give you packed breakfast
          subject to policy of the Hotel .We shall no be responsible for any change / modification
          alteration in the given menu arrangements.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          11. Cancellation Charges:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          If circumstances make you cancel your holiday, the cancellation must be intimated to us in
          writing. As per the booking condition of the holiday, we are constrained to levy the
          following cancellation charges.The remaining amount will be kept with Us / Kesari Select
          D/O Kesari Tours Pvt. Ltd. and can be utilised for your future tour with us. Term and
          conditions may vary as the case may be.
        </Typography>

        <Box
          sx={{
            padding: 2,
            maxWidth: isMobile ? '100%' : 600,
            margin: 'auto'
          }}>
          <TableContainer sx={{ borderRadius: 2 }}>
            <Table size={isMobile ? 'small' : 'medium'}>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#f49d41' }}>
                  <TableCell
                    sx={{
                      color: 'red',
                      fontFamily: 'gothamBold',
                      fontSize: isMobile ? '12px' : 'inherit',
                      border: '1px solid #000000',
                      textAlign: 'center'
                    }}>
                    When a Cancellation is Made
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'red',
                      fontFamily: 'gothamBold',
                      fontSize: isMobile ? '12px' : 'inherit',
                      border: '1px solid #000000',
                      textAlign: 'center'
                    }}>
                    Charges Per Person
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        fontFamily: 'gothamBold',
                        fontSize: isMobile ? '12px' : 'inherit',
                        backgroundColor: index % 2 === 0 ? '#fee29b' : '#fddc7a',
                        border: '1px solid #000000',
                        textAlign: 'center'
                      }}>
                      {row.days}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: 'gothamBold',
                        fontSize: isMobile ? '12px' : 'inherit',
                        backgroundColor: index % 2 === 0 ? '#fee29b' : '#fddc7a',
                        border: '1px solid #000000',
                        textAlign: 'center'
                      }}>
                      {row.charge}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Typography variant="body1" mt={2} sx={{ marginBottom: 2 }}>
          Additional cancellation charges other than those specified above may be applicable as per
          the policies of airlines / railway / cruise / hotel and other service providers.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          12. Miscellaneous:
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          i. Suggestions or Grievances:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Any claim or complaint by the guest in respect of services provided by the independent
          contractorsmust be notified to the Company / Kesari Select in writing within 7 days after
          the tour completion date. No claim notified to the Company / Kesari Select outside this
          period will be entertained and the Company shall incur no liability in respect there of.
          It is hereby declared that the immunities provided under the contract shall be available
          to the Company's managers including tour escorts, employees, servants and agents but not
          to the independent contractors selected by Company. Kesari Select reserves the
          unconditional right to refuse a booking/terminate a guest on tour in event of unreasonable
          behaviour at any time. Airline confirmation and re-confirmation due to overbook seats, the
          airline may offload even confirmed guest/s and may accommodate them on a subsequent
          flight, in such cases Kesari Select shall not be responsible for any circumstances.Hotel
          confirmation and re-confirmation due to overbook rooms, the hotel may accommodate them on
          a subsequent hotel, for any such circumstances, Kesari Select shall not be responsible for
          that.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          ii. Privacy of Information:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          We treat all information furnished by you as confidential and will share only the
          necessary information with airline, hotels & service providers who will provide services
          to you during your tour. However, we may be constrained to disclose the information
          furnished by you, if such disclosure is required by law or by an order by court or the
          rules, regulations & enquiry by any govt. / statutory agency having regulatory authority
          over the Company.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2 }}>
          iii. Jurisdiction:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          All disputes pertaining to the tour and travel, travel related services conducted by the
          Company and any claim arising there-under shall be subjected to Mumbai jurisdiction only.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          13. Refund:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Refunds if any, for amendments and/or cancellations will be paid directly to the guests
          strictly by ‘AC payee’ cheque, in Indian Rupees only at the prevailing rate of exchange
          within 45 days, as per Reserve Bank of India Rules and Regulations, irrespective of
          whether the tour payments were in part or whole in foreign currency. For cases where an
          airline ticket is issued it will take at least 60 days to process the refund(if any). In
          case of refund of foreign currency component, the said refund will be made in ndian Rupees
          only at the prevailing rate on the date of refund as per existing rules and regulations.No
          refund will be payable for any un-utilized services (e.g.: meals, entrance fees, optional
          holidays,hotels, sight-seeing etc) if guest misses any due to whatsoever reason. We shall
          not be liable to makeany refunds or pay any compensation whatsoever for any changes in the
          accommodation made by guest while on tour. Any changes can be made subject to a
          availability and must be settled by the guest directly with the hotel. Laundry, telephone,
          mini bar, alcohol, beverages, room service,recreational activities and excursions are all
          items of a personal nature and must be settled by the guests directly before leaving the
          hotel, ship/cruise, restaurant or the concerned authority
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          14. Liability:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Liability of the Kesari Select is restricted to the making of reservation in accordance
          with the guests requirements.The liability shall cease on the issue of a ticket,
          reservation and travel document to the guest. Kesari Select shall not be responsible for
          train / flight delays, rescheduling /cancellation of train / flights, mis-connecticn
          occured due to airline problem and any change in train/flight schedule or meals not being
          served. We are not responsible for any alternate arrangements and the guest has no right
          to claim any loss or consequences a rising due to the same. Kesari Select is not liable
          for any loss of person,property or personal effects caused or damaged or suffered by the
          guest on account of deficiency in services by any independent contractor. Any gesture of
          courtesy extended by the Kesari Select to the guest to minimize such loss or damage will
          not constitute on admission of such liability or a waiver.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          15. Payment Policy
        </Typography>
        <Box component="ul" sx={{ pl: 2 }}>
          <Typography component="li" sx={{ mb: 1 }}>
            Guest shall pay 50% amount of total tour cost as an advance payment of Land Package in
            addition to Air and Visa Full payment.
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            Guest shall pay full payment of total tour cost in cash or online prior to 15 days of
            tour departure date or days less than that as intimated by Kesari Select.
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            Cheque shall not be accepted 15 days prior to the date of departure. Instead, guests can
            make payment by way of Cash/Online payment/IMPS or any other digital payment platform.
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            Service charges shall be applicable on 3rd change either on itinerary change or on
            destination/any kind of change.
          </Typography>
        </Box>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          16. Inquiry Service Charges
        </Typography>
        <Box component="ul" sx={{ pl: 2 }}>
          <Typography component="li" sx={{ mb: 1 }}>
            Guest shall pay Inquiry Service Charges on every inquiry for Domestic Tour- Rs. 1,000/-
            P.P. & for World Tour– Rs.2000/- P.P which not refundable & non transfereable.
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            Guest shall pay service charges by way of cash or digital and such charges are non
            refundable and transferable.
          </Typography>
          <Typography component="li" sx={{ mb: 1 }}>
            In case inquiry converted into booking, service charges shall be adjusted against the
            tour cost, guest need to pay remaining amount.
          </Typography>
        </Box>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          17. Booking Amount
        </Typography>
        <Box component="ul" sx={{ pl: 2 }}>
          <Typography component="li" sx={{ mb: 1 }}>
            Booking process shall be initiated only once the guest paid 50% payment of total tour
            cost as an advance payment of Land Package in addition to Air and Visa Full payment.
          </Typography>
        </Box>

        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold', marginTop: 4, marginBottom: 2, color: '#9c1c24' }}>
          Note:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Above terms and conditions shall be inclusive of all terms and conditions of Kesari Select
          d/d Kesari Tours Pvt. Ltd. in Kesari Select general brochure & on website and may be
          amended from time to time, as the case may be. Each provision of the terms and conditions
          is severable, and if any provisions thereof are declared illegal, invalid, or
          unenforceable, the remaining provisions nevertheless shall have full force and effect. We
          reserve the right to change/alter/modify any information given in the Terms and Conditions
          before or after the tour due to any circumstances.
        </Typography>

        <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
          Signature in acceptance:-------------------------------------------
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
          Name:-------------------------------------------
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
          Date:-------------------------------------------
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
          Place:-------------------------------------------
        </Typography>
        <Typography variant="body2" sx={{ mt: 3, mb: 3 }}>
          I, ________________________________________________, have read the above rules and
          regulations Serial No 1 to 14 and abide by it and accept the Terms and Conditions with
          unconditional consent, without modification of the terms, conditions, and notices
          contained herein.
        </Typography>

        <Box sx={{ padding: '20px 0' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 2
            }}>
            {/* Left Image */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={KesariDivision} alt="Kesari Logo" style={{ width: '100px' }} />
            </Box>

            {/* Address Text in the Center */}
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography fontFamily="gothamBold" fontSize="18px">
                KESARI SELECT,
              </Typography>
              <Typography variant="body1">
                A Division of Kesari Tours Pvt. Ltd. <br />
                Kohinoor Square, 31st floor, <br />
                N.C. Kelkar Marg, <br />
                Shivaji Park, Mumbai - 400 028
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={KesariTnC} alt="Kesari Select Logo" style={{ width: '120px' }} />
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center', marginTop: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: 'red', display: 'inline', marginRight: 4 }}>
              Toll Free No. 1800 266 9080
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: 'red', display: 'inline', marginRight: 4 }}>
              www.kesariselect.com
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', color: 'red', display: 'inline' }}>
              holiday@kesariselect.in
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default TermsAndCondition;
