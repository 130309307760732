import React, { useState } from 'react';
import './SignUpModal.css';
import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ChevronLeftRounded, CloseRounded } from '@mui/icons-material';
import GoogleIcon from '../../../../assets/Images/google.webp';

const SignupModal = ({ open, handleClose, handleLogin }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = useState(0);
  const [formValues, setFormValues] = useState({
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: ''
  });

  const handleBack = () => {
    if (value === 1) {
      setValue(0);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }));
    validateInput(id, value);
  };

  const validateInput = (field, value) => {
    let error = '';
    switch (field) {
      case 'email':
        if (value.length > 50) {
          error = 'Email cannot exceed 50 characters';
        }
        break;
      case 'phoneNumber':
        if (!/^(\+?\d{1,15})$/.test(value)) {
          error = 'Phone number must be between 6 and 15 characters and only contain numbers and +';
        } else if (value.length < 6 || value.length > 15) {
          error = 'Phone number must be between 6 and 15 characters';
        }
        break;
      case 'password':
        if (value.length < 8) {
          error = 'Password must be at least 8 characters long';
        } else if (value.length > 50) {
          error = 'Password cannot exceed 50 characters';
        } else if (!/(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(value)) {
          error = 'Password must contain at least 1 letter, 1 number, and 1 symbol';
        }
        break;
      case 'confirmPassword':
        if (value !== formValues.password) {
          error = 'Passwords do not match';
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!Object.values(errors).some((error) => error !== '')) {
      // eslint-disable-next-line no-console
      console.log('Form submitted:', formValues); // Replace with your logic
      handleClose(); // Close the dialog after successful submission
    }
  };

  const handleGoogleSignup = () => {
    window.open('https://accounts.google.com/signup', '_blank');
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xs"
        disablebackdropclick="true"
        fullScreen={isMobile}
        aria-labelledby="signup-dialog-title"
        aria-describedby="signup-dialog-description"
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : 'auto',
            maxWidth: isMobile ? '100%' : '600px',
            margin: isMobile ? '0' : 'auto',
            marginTop: isMobile ? '40%' : '5%',
            height: isMobile ? 'calc(100% - 20%)' : 'auto',
            maxHeight: isMobile ? 'calc(100% - 20%)' : 'auto'
          }
        }}>
        <form onSubmit={handleSubmit}>
          <DialogTitle id="signupModal" pb={0}>
            <Box
              className="signup-title"
              sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
              <ChevronLeftRounded
                onClick={handleBack}
                sx={{
                  cursor: value === 1 ? 'pointer' : 'default',
                  color: value === 1 ? 'inherit' : 'grey'
                }}
              />
              <Typography
                className="signup-text"
                display="flex"
                flexDirection="column"
                textAlign="center">
                Sign Up
                <Typography className="signup-span-text" component="span" sx={{ marginLeft: 0 }}>
                  Already have an account?
                  <span onClick={handleLogin} className="forgot-pass" style={{ marginLeft: 2 }}>
                    Log in
                  </span>
                </Typography>
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ color: (theme) => theme.palette.grey[500] }}>
                <CloseRounded />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent className="signup-modal-ui" sx={{ paddingBottom: '0px !important' }}>
            <Grid container pb={2} justifyContent="center">
              <Box className="box-tab-1">
                <TextField
                  autoFocus
                  margin="dense"
                  id="email"
                  label="Email Id"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={formValues.email}
                  onChange={handleInputChange}
                  required
                  inputProps={{ maxLength: 50 }}
                  helperText={errors.email}
                  error={!!errors.email}
                />
                <TextField
                  margin="dense"
                  id="phoneNumber"
                  label="Phone Number"
                  type="tel"
                  fullWidth
                  variant="outlined"
                  value={formValues.phoneNumber}
                  onChange={handleInputChange}
                  required
                  inputProps={{ minLength: 6, maxLength: 15 }}
                  helperText={errors.phoneNumber}
                  error={!!errors.phoneNumber}
                />
                <TextField
                  margin="dense"
                  id="password"
                  label="Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  value={formValues.password}
                  onChange={handleInputChange}
                  required
                  inputProps={{ maxLength: 50 }}
                  helperText={errors.password}
                  error={!!errors.password}
                />
                <TextField
                  margin="dense"
                  id="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  value={formValues.confirmPassword}
                  onChange={handleInputChange}
                  required
                  helperText={errors.confirmPassword}
                  error={!!errors.confirmPassword}
                />
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }} pb={3}>
            <Box className="signup-filed-row">
              <Button
                type="submit"
                color="error"
                variant="contained"
                fullWidth
                sx={{ textTransform: 'none' }}>
                Create Account
                <Box
                  component="img"
                  src="./icons/button-end.svg"
                  sx={{ width: 26, height: 26, ml: 2 }}
                  alt="detail"
                />
              </Button>
              <Typography variant="body2" sx={{ textAlign: 'center', my: 1 }}>
                --------- OR ---------
              </Typography>
              <Button
                className="google-text"
                type="button"
                variant="outlined"
                fullWidth
                onClick={handleGoogleSignup}>
                <img className="google-img" src={GoogleIcon} alt="googleIcon" />
                Sign up with Google
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default SignupModal;
