/* eslint-disable no-console */
import { useEffect } from 'react';

const Chatbot = () => {
  const chatbotKey = process.env.REACT_APP_CHATBOT_KEY;
  const chatbotServerUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    window.onerror = function (message, source, lineno, colno, error) {
      console.error('Global error caught: ', message, source, error);
    };
    return () => {
      window.onerror = null;
    };
  }, []);

  useEffect(() => {
    if (!chatbotKey || !chatbotServerUrl) {
      console.error(
        'Error: chatbotKey or chatbotServerUrl is undefined. Please check your .env file and ensure these variables are defined.'
      );
      return;
    }

    const scriptId = 'engati-chat-script';
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.async = true;
      script.defer = true;
      script.type = 'text/javascript';
      script.src =
        `${chatbotServerUrl}/static/js/widget.js?config=` +
        encodeURIComponent(
          JSON.stringify({
            bot_key: chatbotKey,
            welcome_msg: true,
            branding_key: 'default',
            server: chatbotServerUrl,
            e: 'p'
          })
        );
      document.head.appendChild(script);
    }
    return () => {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, [chatbotKey, chatbotServerUrl]);

  return null;
};

export default Chatbot;
