export const privacyPolicyData = [
  {
    title: '',
    content: `At Kesari Select, We take your privacy seriously and are committed to protecting your personal information. 
    This Privacy Policy explains what personal information we collect from you, how we use it, and with whom we may share it. 
    It also explains your rights and choices regarding your personal information, and how you can contact us with any questions or concerns.`
  },
  {
    title: 'Information We Collect',
    content: `We collect personal information from you when you use our website, such as your name, email address, and other contact information. 
    We may also collect other information, such as your IP address, browsing history, and information about your device.`
  },
  {
    title: 'How We Use Your Information',
    content: `We use your personal information to provide you with the services and information you request, to improve our website and services, 
    and to communicate with you. We may also use your information for research, analytics, and marketing purposes.`
  },
  {
    title: 'Sharing Your Information',
    content: `We will not share your personal information with any third parties except as described in this Privacy Policy or as required by law. 
    For example, we may share your information with service providers who assist us with providing our services or with authorities if we are required by law to do so.`
  },
  {
    title: 'Your Rights and Choices',
    content: `You have the right to access and update your personal information, and to request that we delete it. 
    You also have the right to opt-out of receiving communications from us. You can exercise these rights by contacting us using the contact information provided below.`
  },
  {
    title: 'Data Storage & Security',
    content: `We are committed to protecting your personal information from unauthorized access or use. 
    We use a combination of technical and organizational measures to protect your personal information, including encryption and secure servers.`
  },
  {
    title: 'Compliance with Laws and Regulations',
    content: `We comply with all relevant laws and regulations, including the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).`
  },
  {
    title: 'Contact Us',
    content: (
      <>
        If you have any questions or concerns about this Privacy Policy or our use of your personal
        information, please contact us by email at
        <span
          style={{
            fontWeight: 'bold',
            color: '#27306B',
            cursor: 'pointer',
            marginLeft: '2px',
            marginRight: '2px'
          }}>
          holiday@kesariselect.in
        </span>
        or by phone at
        <span
          style={{ fontWeight: 'bold', color: '#1976d2', marginLeft: '2px', marginRight: '2px' }}>
          +91 1800 266 9080
        </span>
        .
      </>
    )
  },
  {
    title: 'Changes to Our Privacy Policy',
    content: `We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated Privacy Policy on our website. 
    By using our website and services, you agree to this Privacy Policy and our use of your personal information as described herein. 
    This privacy policy was last updated on 8th March 2023.`
  }
];

export default privacyPolicyData;
