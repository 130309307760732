import React, { useEffect, useState } from 'react';
import './OtpModal.css';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Typography,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ChevronLeftRounded, CloseRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
//import { api } from '../../../../APIs/api';
//import { API_URL, BASE_URL } from '../../../../APIs/endpoint';
import ReCAPTCHA from 'react-google-recaptcha';

const OtpModal = ({ open, handleClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  //const [otp, setOtp] = useState(Array(4).fill(''));
  const [captchaValue, setCaptchaValue] = useState(null);
  // const [errors, setErrors] = useState({ otp: '' });
  const [isLoader, setIsLoader] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    const tripDetailsString = sessionStorage.getItem('tripDetails');
    if (tripDetailsString) {
      try {
        const tripDetails = JSON.parse(tripDetailsString);
        if (tripDetails?.userDetails?.email) {
          setEmail(tripDetails.userDetails.email);
        }
      } catch (error) {
        setEmail(error);
      }
    }
  }, [open]);

  // const handleInputChange = (e, index) => {
  //   const value = e.target.value;
  //   if (/^\d$/.test(value) || value === '') {
  //     const newOtp = [...otp];
  //     newOtp[index] = value;
  //     setOtp(newOtp);

  //     if (value && index < 3) {
  //       const nextInput = document.getElementById(`otp-${index + 1}`);
  //       if (nextInput) {
  //         nextInput.focus();
  //       }
  //     }
  //   }
  // };

  // const validateInput = (value) => {
  //   let error = '';
  //   if (value.length !== 4) {
  //     error = 'OTP must be 4 digits';
  //   }
  //   setErrors({ otp: error });
  //   return error === '';
  // };

  //OTP handleSubmit
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const otpValue = otp.join('');
  //   setIsLoader(true);
  //   const payload = {
  //     email: email
  //     otp: Number(otpValue)
  //   };
  //   try {
  //     if (validateInput(otpValue)) {
  //     const otpResponse = await api.post(`${BASE_URL}${API_URL.ENQUIRY_OTP_VERIFY}`, payload);
  //     const { status, message, data } = otpResponse;
  //     if (status) {
  //       toast.success(data, { autoClose: 3000 });
  //       handleCloseWithReset();
  //       navigate('/trip-confirmation');
  //     } else {
  //       toast.error(message, { autoClose: 3000 });
  //       setOtp(Array(4).fill(''));
  //     }
  //      }
  //   } catch (error) {
  //     toast.error(error.message, { autoClose: 3000 });
  //   } finally {
  //     setIsLoader(false);
  //   }
  // };

  //reCAPTCHA handleSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      toast.error('Please verify that you are not a robot!', { autoClose: 3000 });
      return;
    }
    setIsLoader(true);
    try {
      navigate('/trip-confirmation');
    } catch (error) {
      toast.error('An error occurred, please try again.', { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  const handleCloseWithReset = () => {
    setEmail('');
    //setOtp(Array(4).fill(''));
    // setErrors({ otp: '' });
    setCaptchaValue(null);
    setIsButtonEnabled(false);
    handleClose();
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
    setIsButtonEnabled(!!value);
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullScreen={isMobile}
      aria-labelledby="otp-dialog-title"
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : '400px',
          margin: 'auto',
          marginTop: isMobile ? '40%' : '15%',
          height: isMobile ? 'calc(100% - 20%)' : 'auto',
          maxHeight: isMobile ? 'calc(100% - 20%)' : 'auto'
        }
      }}>
      <DialogTitle id="otpModal" pb={0}>
        <Box
          className="otp-title"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative'
          }}>
          <ChevronLeftRounded
            onClick={handleCloseWithReset}
            sx={{
              cursor: 'pointer',
              visibility: 'hidden'
            }}
          />
          <Typography
            sx={{
              flexGrow: 1,
              textAlign: 'center'
            }}>
            Enter OTP
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500]
            }}>
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent
          sx={{
            paddingBottom: '0px !important',
            overflowY: 'hidden'
          }}>
          <Grid container justifyContent="center">
            <Box sx={{ width: '100%' }}>
              <TextField
                margin="dense"
                id="email"
                label="Email Id"
                type="email"
                fullWidth
                variant="outlined"
                value={email}
                disabled
                required
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: 'black',
                    color: 'black',
                    fontFamily: 'inherit'
                  },
                  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.23)'
                  },
                  '& label.Mui-disabled': {
                    color: 'rgba(0, 0, 0, 0.6)'
                  }
                }}
              />
              <Box sx={{ width: '100%', marginTop: 2 }}>
                <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={onCaptchaChange} />
              </Box>
            </Box>
            {/* <Typography
              sx={{
                mt: 2,
                mb: 1,
                textAlign: 'center',
                fontFamily: 'inherit'
              }}>
              Enter the 4-digit OTP sent to your email
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 1,
                mt: 2
              }}>
              {otp.map((digit, index) => (
                <TextField
                  key={index}
                  id={`otp-${index}`}
                  value={digit}
                  onChange={(e) => handleInputChange(e, index)}
                  variant="outlined"
                  inputProps={{
                    maxLength: 1,
                    style: {
                      textAlign: 'center'
                    },
                    autoFocus: index === 0
                  }}
                  sx={{
                    width: '40px'
                  }}
                  error={!!errors.otp}
                />
              ))}
            </Box>
            {errors.otp && (
              <Typography color="error" textAlign="center" mt={1}>
                {errors.otp}
              </Typography>
            )} */}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center'
          }}
          pb={3}>
          <Button
            type="submit"
            variant="contained"
            color="error"
            fullWidth
            sx={{
              textTransform: 'none',
              backgroundColor: '#d32f2f',
              color: '#fff',
              '&:disabled': {
                backgroundColor: '#d32f2f',
                color: '#fff',
                opacity: 0.5
              }
            }}
            //disabled={isLoader || otp.some((digit) => digit === '') || otp.join('').length > 4}
            disabled={!isButtonEnabled || isLoader}
            onClick={(e) => {
              if (!isLoader) {
                handleSubmit(e);
              }
            }}
            style={{ minHeight: '40px', minWidth: '160px' }}>
            {isLoader ? <CircularProgress size={24} style={{ color: '#fff' }} /> : 'Verify Captcha'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OtpModal;
