import { Box, Typography } from '@mui/material';
import { ChevronRightOutlined } from '@mui/icons-material';
import { FooterUsefulLinks } from '../../constants/footer';
import { capitalizeFirstLetter } from '../../Helper/global';

const FooterUsefulLinksComponent = () => (
  <Box textAlign={{ md: 'justify' }}>
    <Typography
      color="#FFFFFF"
      fontFamily="gothamBold"
      fontSize={{ xs: 15, md: 18 }}
      lineHeight={{ xs: '60px', md: '30px' }}>
      Useful Links
    </Typography>
    <Box mt={2}>
      {FooterUsefulLinks.map((item) => (
        <Box key={item.title} display="flex" alignItems="center" mb={1.5}>
          <ChevronRightOutlined sx={{ color: '#FFFFFF', fontSize: '18px', mr: 1 }} />
          {/* <a
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#FFFFFF',
              textDecoration: 'none',
              fontSize: '14px'
            }}
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}> */}
          <a
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#FFFFFF',
              textDecoration: 'none',
              fontSize: '14px',
              transition: 'color 0.3s ease, transform 0.3s ease' // Add transition for color and transform
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.textDecoration = 'underline';
              e.currentTarget.style.color = '#FFD700'; // Change to a highlighted color on hover
              e.currentTarget.style.transform = 'scale(1.05)'; // Slightly scale up the link
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.textDecoration = 'none';
              e.currentTarget.style.color = '#FFFFFF'; // Revert color on mouse leave
              e.currentTarget.style.transform = 'scale(1)'; // Revert scale
            }}>
            {capitalizeFirstLetter(item.title)}
          </a>
        </Box>
      ))}
    </Box>
  </Box>
);

export default FooterUsefulLinksComponent;
