import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Title } from './Title';
import { ChevronLeft } from '@mui/icons-material';
import { FormSection } from './FormSection';
import { WorldMapSection } from './WorldMapSection';
import { AccordionSection } from './AccordionSection';
import { PaymentSection } from './PaymentSection';
import { AboutSection } from './AboutSection';
import ReviewSection from '../../components/common/Review';

const ReviewAndPay = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box width="100%" height="100%" bgcolor="#F5F5F5">
      <Box maxWidth="1200px" pt={{ lg: '30px' }} position="relative" mx="auto" fontFamily="inherit">
        <Box
          display={isMobile && 'flex'}
          bgcolor={{ xs: 'white', lg: 'transparent' }}
          flexDirection="row-reverse"
          justifyContent="start"
          gap="12px"
          px={{ xs: 2, lg: 0 }}
          py={{ xs: 2, lg: 0 }}>
          <Title sx={{ color: '#000000CC', position: { md: 'relative' } }}>
            Review & Pay
            <ChevronLeft
              sx={{
                color: '#000000',
                position: 'absolute',
                top: 0,
                left: '-37px',
                cursor: 'pointer',
                display: { xs: 'none', md: 'block' }
              }}
            />
          </Title>
          {isMobile ? (
            <ChevronLeft
              sx={{
                color: '#000000',
                cursor: 'pointer'
              }}
            />
          ) : (
            <Typography fontSize={{ md: '13px' }} lineHeight={{ md: '13px' }} color="#00000099">
              You can review your tour details & make payment.
            </Typography>
          )}
        </Box>
        <Box
          mt={{ md: 4 }}
          display="flex"
          columnGap="27px"
          flexWrap="wrap"
          flexDirection={isMobile ? 'column' : 'row'}>
          <Box
            width={{ xs: '100%', lg: '760px' }}
            display="grid"
            gap={{ xs: '4px', md: '12px', lg: '20px' }}>
            {isMobile ? (
              <>
                <FormSection />
                <PaymentSection />
                <AboutSection />
                <WorldMapSection />
                <AccordionSection />
              </>
            ) : (
              <>
                <FormSection />
                <WorldMapSection />
                <AccordionSection />
              </>
            )}
          </Box>
          {!isMobile && (
            <Box width={{ xs: '100%', lg: '410px' }} display="grid" gap="20px">
              <PaymentSection />
              <AboutSection />
            </Box>
          )}
          <ReviewSection sx={{ pb: { xs: '42px', md: '120px' } }} />
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewAndPay;
