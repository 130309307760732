import React from 'react';
import './PackageDetialsSection2.css';
import { Box, Grid } from '@mui/material';
import Breadcrumbs from '../../common/breadcrumbs/BreadCrumbs';
import TripShareMap from '../../common/trip_share_map/TripShareMap';
import PackageTabs from '../package_tabs/PackageTabs';
import DreamVacationCard from '../dream_vacation_card/DreamVacationCard';

const PackageDetailsSection2 = ({ packageDetails, isLoader }) => (
  <Box className="card-main-column pl-pr-120">
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
        <Box className="left-part">
          <Box className="breadcrupt-part">
            <Breadcrumbs packageDetails={packageDetails} />
          </Box>
          <Box className="tabspart">
            <PackageTabs packageDetails={packageDetails} isLoader={isLoader} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
        <Box className="right-part">
          <Box className="dream-vacation-card-sec">
            <DreamVacationCard packageDetails={packageDetails} />
          </Box>
          <Box className="map-card-sec">
            <TripShareMap packageDetails={packageDetails} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

export default PackageDetailsSection2;
