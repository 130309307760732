import React, { useState } from 'react';
import './IdealTripModal.css';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  TextField,
  Button,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ChevronLeftRounded, CloseRounded } from '@mui/icons-material';
import KesariSelectImg from '../../../../assets/Images/Kesari_Select.webp';

const IdealTripModal = ({ open, handleClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = useState(0);
  const [formValues, setFormValues] = useState({
    destination: '',
    travel: '',
    days: '',
    experience: '',
    fullName: '',
    email: '',
    contactNo: '',
    time: ''
  });

  const handleBack = () => {
    if (value === 1) {
      setValue(0);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value === 0) {
      setValue(1);
    } else {
      // Handle form validation or submission logic here
      // eslint-disable-next-line no-console
      console.log('Form submitted:', formValues); // Replace with your logic
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      disablebackdropclick="true"
      fullScreen={isMobile}
      aria-labelledby="country-dialog-title"
      aria-describedby="country-dialog-description"
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : 'auto',
          maxWidth: isMobile ? '100%' : '600px',
          margin: isMobile ? '0' : 'auto',
          marginTop: isMobile ? '40%' : '5%',
          height: isMobile ? 'calc(100% - 20%)' : 'auto',
          maxHeight: isMobile ? 'calc(100% - 20%)' : 'auto'
        }
      }}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id="country-dialog-title">
          <Box className="centeredStyles" value={value}>
            <Box mx="auto" display="flex">
              <Box className="modal-selected-tab" />
              <Box className={value === 0 ? 'modal-unSelected-tab2' : 'modal-selected-tab'} />
            </Box>
            <Box>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ color: (theme) => theme.palette.grey[500] }}>
                <CloseRounded />
              </IconButton>
            </Box>
          </Box>
          <Box className="iconAndImageStyles">
            <ChevronLeftRounded
              onClick={handleBack}
              sx={{
                cursor: value === 1 ? 'pointer' : 'default',
                color: value === 1 ? 'inherit' : 'grey'
              }}
            />
            <Box mx="auto" display="flex">
              <img className="imageStyles" src={KesariSelectImg} alt="Image" />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {value === 0 && (
            <>
              <Box className="modal-body" mb={2}>
                <Typography variant="h6">Describe your ideal trip.</Typography>
                <Typography variant="body2" color="textSecondary">
                  Tell us what you're considering
                </Typography>
              </Box>
              <Box className="box-tab-1">
                <TextField
                  autoFocus
                  margin="dense"
                  id="destination"
                  label="What destination are you considering?"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValues.destination}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  id="travel"
                  label="When do you plan to travel?"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValues.travel}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  id="days"
                  label="Planning for how many days?"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={formValues.days}
                  onChange={handleInputChange}
                  inputProps={{ min: 0, style: { MozAppearance: 'textfield' } }}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) => {
                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                      e.preventDefault();
                    }
                  }}
                  sx={{
                    '& input[type=number]': {
                      MozAppearance: 'textfield' // Firefox
                    },
                    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
                      {
                        WebkitAppearance: 'none',
                        margin: 0 // Chrome, Safari, Edge, Opera
                      }
                  }}
                />
                <TextField
                  margin="dense"
                  id="experience"
                  label="What experience are you seeking?"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValues.experience}
                  onChange={handleInputChange}
                />
              </Box>
            </>
          )}
          {value === 1 && (
            <>
              <Box className="modal-body" mb={2}>
                <Typography variant="h6">Tell us about yourself.</Typography>
                <Typography variant="body2" color="textSecondary">
                  We'll discuss our ideas with you.
                </Typography>
              </Box>
              <Box className="box-tab-1">
                <TextField
                  autoFocus
                  margin="dense"
                  id="fullName"
                  label="Full Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValues.fullName}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  id="email"
                  label="Email Id"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={formValues.email}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  id="contactNo"
                  label="Contact Number"
                  type="tel"
                  fullWidth
                  variant="outlined"
                  value={formValues.contactNo}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  id="time"
                  label="Best time to call? (Optional)"
                  placeholder="e.g 3-5 EST"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValues.time}
                  onChange={handleInputChange}
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Box width="360px" mr={2} mb={2}>
            <Button type="submit" color="error" variant="contained" fullWidth>
              {value === 0 ? 'Continue' : 'Submit'}
              <Box
                component="img"
                src="./icons/button-end.svg"
                sx={{ width: 26, height: 26, ml: 2 }}
                alt="detail"
              />
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default IdealTripModal;
