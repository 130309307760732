/* eslint-disable no-unused-vars */
import React from 'react';
import './TripShareMap.css';
import { Box } from '@mui/material';
import WhatsAppIcon from '../../../assets/PackageDetails/whats_app_icon.png';
import EmailIcon from '../../../assets/PackageDetails/email_icon.svg';
import ShareIcon from '../../../assets/PackageDetails/share_icon.svg';

const TripShareMap = ({ packageDetails, shareIcon = true }) => {
  const { places, state, country } = packageDetails;
  const location =
    places?.length > 0 ? `${places[0].Place}, ${state}, ${country}` : `Unknown Location`;
  const mapSrc = `https://maps.google.com/maps?q=${encodeURIComponent(location)}&output=embed`;

  const handleNavigation = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Box className="tripsharemap">
      <Box className="ifram-sec">
        <iframe
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src={mapSrc}></iframe>
      </Box>
      {/* {shareIcon === true && (
        <Box className="share-icon">
          <ul>
            <li>
              <Box
                onClick={() => handleNavigation('https://web.whatsapp.com/')}
                sx={{ cursor: 'pointer' }}>
                <img src={WhatsAppIcon} alt="Whats-App" />
                <Box className="text">Whatsapp Itinerary</Box>
              </Box>
            </li>
            <li>
              <Box onClick={() => handleNavigation('mailto:')} sx={{ cursor: 'pointer' }}>
                <img src={EmailIcon} alt="Email" />
                <Box className="text">Email Itinerary</Box>
              </Box>
            </li>
            <li>
              <Box
                onClick={() => handleNavigation('https://www.example.com/share')}
                sx={{ cursor: 'pointer' }}>
                <img src={ShareIcon} alt="Share" />
                <Box className="text">Share Itinerary</Box>
              </Box>
            </li>
          </ul>
        </Box>
      )} */}
    </Box>
  );
};

export default TripShareMap;
