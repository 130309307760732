import React, { useEffect, useState } from 'react';
import './CustomizeTripModal.css';
import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormControl,
  Icon,
  IconButton,
  Typography,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {
  // ChevronLeftRounded,
  CloseRounded,
  PeopleAltOutlined,
  Remove,
  Add,
  ApartmentRounded,
  FlightTakeoffOutlined
} from '@mui/icons-material';
import StarVector from '../../../../assets/Images/Start_Vector.jpg';

const CustomizeTripModal = ({ open, handleClose, setTotalPax, handleUserDetailsModal }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const [value, setValue] = useState(0);
  const [adults, setAdults] = useState(0);
  const [infants, setInfants] = useState(0);
  const [children, setChildren] = useState(0);
  const [hotelRating, setHotelRating] = useState('5');
  const [includeFlights, setIncludeFlights] = useState(true);
  const [formValues, setFormValues] = useState({
    adults: 0,
    infants: 0,
    children: 0,
    hotelRating: 0,
    includFlight: false
  });

  useEffect(() => {
    setFormValues({
      adults: adults,
      infants: infants,
      children: children,
      hotelRating: hotelRating,
      includeFlights: includeFlights
    });
  }, [adults, infants, children, hotelRating, includeFlights]);

  // const handleBack = () => {
  //   if (value === 1) {
  //     setValue(0);
  //   }
  // };
  const handleIncrementAdult = () => {
    setAdults((prev) => {
      const newValue = parseInt(prev || '0', 10) + 1;
      return newValue <= 999 ? newValue.toString() : prev;
    });
  };

  const handleDecrementAdult = () => {
    setAdults((prev) => {
      const newValue = parseInt(prev || '0', 10) - 1;
      return newValue >= 0 ? newValue.toString() : prev;
    });
  };

  const handleIncrementInfants = () => {
    setInfants((prev) => {
      const newValue = parseInt(prev || '0', 10) + 1;
      return newValue <= 999 ? newValue.toString() : prev;
    });
  };

  const handleDecrementInfants = () => {
    setInfants((prev) => {
      const newValue = parseInt(prev || '0', 10) - 1;
      return newValue >= 0 ? newValue.toString() : prev;
    });
  };

  const handleIncrementChildren = () => {
    setChildren((prev) => {
      const newValue = parseInt(prev || '0', 10) + 1;
      return newValue <= 999 ? newValue.toString() : prev;
    });
  };

  const handleDecrementChildren = () => {
    setChildren((prev) => {
      const newValue = parseInt(prev || '0', 10) - 1;
      return newValue >= 0 ? newValue.toString() : prev;
    });
  };

  const handleSubmit = () => {
    const existingTripDetails = JSON.parse(sessionStorage.getItem('tripDetails')) || {};
    const updatedTripDetails = {
      ...existingTripDetails,
      tripMembers: formValues
    };
    sessionStorage.setItem('tripDetails', JSON.stringify(updatedTripDetails));
    const totalPax =
      parseInt(formValues.adults) + parseInt(formValues.seniors) + parseInt(formValues.children);
    setTotalPax(totalPax, handleClose);
    handleUserDetailsModal();
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullScreen={isMobile}
      disablebackdropclick="true"
      aria-labelledby="customize-trip-dialog-title"
      aria-describedby="customize-trip-dialog-description"
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : 'auto',
          maxWidth: isMobile ? '100%' : 'auto',
          margin: isMobile ? '0' : 'auto',
          marginTop: isMobile ? '40%' : '5%',
          height: isMobile ? 'calc(100% - 20%)' : 'auto',
          maxHeight: isMobile ? 'calc(100% - 20%)' : 'auto'
        }
      }}>
      <form
        onSubmit={handleSubmit}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <DialogTitle
          id="custom-trip-dialog-title"
          pb={0}
          sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
          <Box className="custom-trip-title">
            {/* <ChevronLeftRounded
              onClick={handleBack}
              sx={{
                cursor: value === 1 ? 'pointer' : 'default',
                color: value === 1 ? 'inherit' : 'grey'
              }}
            /> */}
            <Typography mx="auto" display="flex" sx={{ fontWeight: '700' }}>
              Customize Your Trip!
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ color: (theme) => theme.palette.grey[500] }}>
              <CloseRounded />
            </IconButton>
          </Box>
        </DialogTitle>
        <hr className="hr-line1" />
        <DialogContent sx={{ paddingLeft: '3%', paddingRight: '3%', overflowY: 'auto' }}>
          <Grid container pb={3} justifyContent="center">
            <Grid className="filed-row" item display="flex" sx={{ color: '#27306B' }}>
              <Icon>
                <PeopleAltOutlined />
              </Icon>
              <Typography className="lable-title" mx={1}>
                No. of members
              </Typography>
            </Grid>
            <Grid className="filed-row" item display="flex" justifyContent="space-between">
              <Typography className="lable-title-2nd">
                Adults
                <Typography className="label-sub-title">Above 12 years</Typography>
              </Typography>
              <Box className="count-box" sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => handleDecrementAdult(setAdults, adults)}>
                  <Remove />
                </IconButton>
                <Typography className="count-box-number">{adults}</Typography>
                <IconButton onClick={() => handleIncrementAdult(setAdults, adults)}>
                  <Add />
                </IconButton>
              </Box>
            </Grid>
            <Grid className="filed-row" item display="flex" pt={2} justifyContent="space-between">
              <Typography className="lable-title-2nd">
                Children
                <Typography className="label-sub-title">In between 2-12 years</Typography>
              </Typography>
              <Box className="count-box" sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => handleDecrementChildren(setChildren, children)}>
                  <Remove />
                </IconButton>
                <Typography className="count-box-number">{children}</Typography>
                <IconButton onClick={() => handleIncrementChildren(setChildren, children)}>
                  <Add />
                </IconButton>
              </Box>
            </Grid>
            <Grid className="filed-row" item display="flex" pt={4} justifyContent="space-between">
              <Typography className="lable-title-2nd">
                Infants
                <Typography className="label-sub-title">Below 2 years</Typography>
              </Typography>
              <Box className="count-box" sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => handleDecrementInfants(setInfants, infants)}>
                  <Remove />
                </IconButton>
                <Typography className="count-box-number">{infants}</Typography>
                <IconButton onClick={() => handleIncrementInfants(setInfants, infants)}>
                  <Add />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <hr className="hr-line" />
          <Grid container justifyContent="center">
            <Grid className="filed-row" item display="flex" sx={{ color: '#27306B' }}>
              <Icon>
                <ApartmentRounded />
              </Icon>
              <Typography className="lable-title" mx={1}>
                Prefered Hotel rating
              </Typography>
            </Grid>
            <Grid className="filed-row" item display="flex" sx={{ color: '#27306B' }}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={hotelRating}
                  onChange={(e) => setHotelRating(e.target.value)}>
                  <FormControlLabel
                    control={<Radio />}
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="rating-text">5</span>
                        <img src={StarVector} alt="star" />
                      </div>
                    }
                    value="5"
                    checked={hotelRating === '5'}
                    onChange={(e) => setHotelRating(e.target.value)}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="rating-text">4</span>
                        <img src={StarVector} alt="star" />
                      </div>
                    }
                    value="4"
                    checked={hotelRating === '4'}
                    onChange={(e) => setHotelRating(e.target.value)}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="rating-text">3</span>
                        <img src={StarVector} alt="star" />
                      </div>
                    }
                    value="3"
                    checked={hotelRating === '3'}
                    onChange={(e) => setHotelRating(e.target.value)}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="rating-text">2</span>
                        <img src={StarVector} alt="star" />
                      </div>
                    }
                    value="2"
                    checked={hotelRating === '2'}
                    onChange={(e) => setHotelRating(e.target.value)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <hr className="hr-line" />

          <Grid container justifyContent="center">
            <Grid className="filed-row" item display="flex" sx={{ color: '#27306B' }}>
              <Icon>
                <FlightTakeoffOutlined />
              </Icon>
              <Typography className="lable-title" mx={1}>
                Include Flights
              </Typography>
            </Grid>
            <Grid className="filed-row" item display="flex" sx={{ color: '#27306B' }}>
              <FormControlLabel
                control={
                  <Radio
                    checked={includeFlights}
                    onChange={(e) => setIncludeFlights(e.target.checked)}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={!includeFlights}
                    onChange={(e) => setIncludeFlights(!e.target.checked)}
                  />
                }
                label="No"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            zIndex: 1
          }}>
          <Box className="filed-row">
            <Button
              type="submit"
              variant="contained"
              color="error"
              fullWidth
              disabled={formValues.adults === 0 || formValues.adults === '0'}
              sx={{
                backgroundColor: '#d32f2f',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#c62828'
                },
                '&:disabled': {
                  backgroundColor: '#d32f2f',
                  color: '#fff',
                  opacity: 0.5
                }
              }}>
              Continue
              <Box
                component="img"
                src="./icons/button-end.svg"
                sx={{ width: 26, height: 26, ml: 2 }}
                alt="detail"
              />
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CustomizeTripModal;
