import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Title } from './Title';
import { insightData } from '../../constants/reviewAndPay';

export const WorldMapSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      height={{ xs: '290px', md: '128px' }}
      borderRadius={{ md: '6px' }}
      bgcolor="#FFFFFF"
      sx={{
        backgroundImage: 'url(./images/worldMap.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '8px',
        justifyContent: 'center',
        alignItems: 'start'
      }}
      display={isMobile && 'flex'}
      flexDirection={isMobile && 'column'}
      px={{ xs: '24px', md: '44px' }}
      pt={{ xs: '36px', md: '40px' }}
      pb={{ md: '52px' }}
      boxShadow="3px 3px 20px 0px #00000014">
      <Title>Why Kesari Select ?</Title>
      <Box
        display="flex"
        justifyContent={isMobile ? 'center' : 'space-between'}
        mt={{ xs: '36px', md: '39px' }}
        flexWrap="wrap"
        gap={isMobile && 4}>
        {insightData.map((data) => (
          <Box
            key={data.title}
            display="grid"
            gap="6px"
            sx={{ placeItems: 'center' }}
            width={isMobile ? '40%' : 'fit-content'}
            fontFamily="inherit">
            <Typography
              color="#27306B"
              fontWeight={700}
              fontSize={isMobile ? 28 : 34}
              lineHeight={isMobile ? '26px' : '32px'}>
              {data.total}
            </Typography>
            <Typography fontWeight={400} fontSize={13} lineHeight={isMobile ? '20px' : '36px'}>
              {data.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
