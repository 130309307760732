import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  Skeleton,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';

// eslint-disable-next-line no-unused-vars
const RootCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none !important',
  '&:hover .MuiCardActionArea-focusHighlight': {
    opacity: '0 !important'
  }
}));
const ThemedCardSkeleton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      spacing={isMobile ? 0 : 6}
      gap={isMobile ? 2 : 0}
      justifyContent={{ xs: 'left', md: 'center' }}
      sx={{
        overflowY: { xs: 'hidden', lg: 'unset' },
        paddingLeft: { xs: '5px' },
        width: '100%'
      }}>
      {[...Array(5)].map((_, i) => (
        <Grid
          item
          xs={2}
          sm={4}
          md={4}
          lg={2.4}
          key={i}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}>
          <RootCard
            sx={{
              display: { xs: 'block' },
              width: { xs: 114, sm: 200, md: 250, lg: 280 },
              marginTop: 5,
              overflow: 'unset'
            }}>
            <CardActionArea
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}>
              <Skeleton variant="circular" width={175} height={175} />
              <CardContent>
                <Typography
                  className="themeEscape_card_placeName"
                  fontFamily="inherit"
                  color="dark"
                  textAlign="center"
                  sx={{
                    fontWeight: 300,
                    fontSize: {
                      xs: '12px',
                      sm: '14px',
                      md: '20px',
                      lg: '22px',
                      xl: '24px'
                    },
                    color: '#000000'
                  }}>
                  <Skeleton variant="text" width={100} height={50} />
                </Typography>
              </CardContent>
            </CardActionArea>
          </RootCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default ThemedCardSkeleton;
