import React, { useState } from 'react';
import './package-list.css';
import { Box, Select, MenuItem, FormControl, Typography } from '@mui/material';

const CustomFilter = ({ sortByFilterOptions, onChange, isFullWidth }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    onChange(value);
  };

  return (
    <Box className="custom-filter-content-box">
      <FormControl
        sx={{
          minWidth: 120,
          mt: { xs: '20px', md: 0 }
        }}>
        <Select
          fullWidth={isFullWidth}
          value={selectedOption}
          onChange={handleChange}
          displayEmpty
          sx={{
            width: { md: '280px' },
            '& .MuiSelect-select': { px: '18px', py: '9px' },
            '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
              { minHeight: '20px', maxHeight: '20px' }
          }}>
          <MenuItem value="" sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box component="img" src="./icons/filter-icon.svg" width={16} height={10} />
            <Typography
              component="span"
              fontFamily="gothamBook"
              fontSize={12}
              lineHeight="20px"
              ml="14px"
              color="#000000CC">
              Sort by : Select Option From List
            </Typography>
          </MenuItem>
          {sortByFilterOptions?.length > 0 &&
            sortByFilterOptions.map((option, index) => (
              <MenuItem key={index} value={option.value} sx={{ fontFamily: 'gothamBook' }}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomFilter;
