import React from 'react';
import './button.css';
import { Box } from '@mui/material';

const ButtonComponnet = () => (
  <Box className="button-container">
    <button type="button"> SUBMIT </button>
    <button type="button"> RESET </button>
  </Box>
);

export default ButtonComponnet;
