import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

const CustomAccordion = ({ title, summary }) => (
  <Accordion
    disableGutters
    sx={{
      boxShadow: '4px 4px 24px 0px #00000014 !important',
      borderRadius: '12px',
      '& .MuiAccordionSummary-content ': { margin: 0 },
      '& .MuiButtonBase-root': {
        minHeight: '59px',
        padding: 0,
        pl: '54px',
        pr: '20px',
        fontFamily: 'inherit'
      },
      '& .MuiAccordionDetails-root ': {
        padding: '16px 21px 20px 54px'
      }
    }}>
    <AccordionSummary
      expandIcon={<ChevronRight />}
      aria-controls="panel1-content"
      id="panel1-header"
      sx={{
        fontSize: 16,
        lineHeight: '30px',
        fontWeight: 400,
        color: '#000000CC'
      }}>
      {title}
    </AccordionSummary>
    <AccordionDetails
      sx={{
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 300,
        color: '#000000CC'
      }}>
      {summary}
    </AccordionDetails>
  </Accordion>
);

export default CustomAccordion;
