import React from 'react';
import './ReviewCollapsePanel.css';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const ReviewCollapsePanel = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className="review-collapse-panel-container">
        <div className="accordion-container">
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header">
              <Typography>Inclusions & Exclusions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet dicta nemo itaque
                quam asperiores officia fuga earum, nam illo, commodi iure, quaerat ipsam numquam
                excepturi quos beatae unde voluptatibus sapiente!
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2bh-content"
              id="panel2bh-header">
              <Typography>Frequently Asked Questions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet dicta nemo itaque
                quam asperiores officia fuga earum, nam illo, commodi iure, quaerat ipsam numquam
                excepturi quos beatae unde voluptatibus sapiente!
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel3bh-content"
              id="panel3bh-header">
              <Typography>Cancellation & Payment Terms</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet dicta nemo itaque
                quam asperiores officia fuga earum, nam illo, commodi iure, quaerat ipsam numquam
                excepturi quos beatae unde voluptatibus sapiente!
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel4bh-content"
              id="panel4bh-header">
              <Typography>Terms & Conditions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet dicta nemo itaque
                quam asperiores officia fuga earum, nam illo, commodi iure, quaerat ipsam numquam
                excepturi quos beatae unde voluptatibus sapiente!
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default ReviewCollapsePanel;
