import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import CustomCarousel from './CustomCarousel';
import './ReadyToBook.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import CustomButton from '../../common/Button';

const ReadyToBook = forwardRef(({ tourData, isLoader }, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  return (
    <Box
      ref={ref}
      textAlign="center"
      bgcolor="#FFFFFF"
      overflow="hidden"
      pt={{ md: '40px' }}
      pb={{ md: '40px' }}>
      <Typography
        fontSize={{ xs: 22, md: 32 }}
        fontFamily="gothamBold"
        lineHeight={{ xs: '30px', md: '40px' }}>
        Ready-to-Book Tours, {isMobile && <br />}specially
        {!isMobile && <br />} curated for you!
      </Typography>
      <Typography
        mt={{ xs: '6px', md: '12px' }}
        color="#00000080"
        fontFamily="gothamBook"
        fontSize={{ xs: 16, md: 20 }}>
        Unlock the Globe's Most Frequented Gems.
      </Typography>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        margin="auto"
        overflow="hidden">
        <CustomCarousel tourData={tourData} isLoader={isLoader} />
      </Box>
      <CustomButton
        className="TailoredAdventures_typo_btn"
        onClick={() => navigate('/explore')}
        showArrow={true}>
        <Typography fontFamily="gothamBold"> View Details</Typography>
      </CustomButton>
    </Box>
  );
});

ReadyToBook.displayName = 'ReadyToBook';

export default ReadyToBook;
