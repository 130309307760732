/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import './LocationTabs.css';
import {
  Box,
  Grid,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  styled
} from '@mui/material';
import { OpenInNewOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../APIs/api';
import { API_URL, BASE_URL } from '../../../APIs/endpoint';
import { capitalizeFirstLetter } from '../../../Helper/global';
import international_map from '../../../assets/Home/Travel_Life/international_map.webp';
import india_map from '../../../assets/Home/Travel_Life/india_map.webp';
import IdealTripModal from '../modal/IdealTripModal/IdealTripModal';

const TabsContainer = styled(Tabs)({
  display: 'flex',
  justifyContent: 'center',
  '& .MuiTabs-indicator': {
    backgroundColor: 'rgba(39, 48, 107, 1) !important'
  }
});

const TabRoot = styled(Tab)({
  display: 'flex',
  justifyContent: 'center',
  '&.Mui-selected .location_tab_text': {
    color: 'rgba(39, 48, 107, 1)',
    fontWeight: 700,
    filter: 'grayscale(0)'
  },
  '&.Mui-selected .location_tab_img_international': {
    filter: 'grayscale(0)'
  },
  '&.Mui-selected .location_tab_img_domestic': {
    filter: 'grayscale(0)'
  },
  '& .MuiTabs-flexContainer': {
    flexWrap: 'wrap'
  },
  '&.highlighted': {
    backgroundColor: 'rgba(39, 48, 107, 0.1)'
  }
});

const LocationTabs = ({ searchQuery, selectedTourPlace }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [currentCountryList, setCurrentCountryList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [countryData, setCountryData] = useState({});
  const [error, setError] = useState(null);

  const listItemRefs = useRef([]);

  const getWorldWisePlacesApi = async () => {
    try {
      const worldWisePlacesRes = await api.get(`${BASE_URL}${API_URL.WORLD_WISE_PLACES}`);
      setIsLoader(true);

      if (worldWisePlacesRes.status) {
        const allCountries = [];
        const internationalCountries = [];
        const domesticCountries = [];

        // Process International places
        worldWisePlacesRes.data?.International.forEach((place) => {
          const countryObj = { name: place.destination, tags: [] };
          internationalCountries.push(countryObj);
          allCountries.push(place.destination);
        });

        // Process Domestic places
        worldWisePlacesRes.data?.Domestic.forEach((place) => {
          const countryObj = { name: place.destination, tags: [] };
          domesticCountries.push(countryObj);
          allCountries.push(place.destination);
        });

        const uniqueAllCountries = [...new Set(allCountries)]
          .sort((a, b) => a.localeCompare(b))
          .map((country) => ({
            name: country,
            tags: []
          }));
        internationalCountries.sort((a, b) => a.name.localeCompare(b.name));
        domesticCountries.sort((a, b) => a.name.localeCompare(b.name));

        const updatedCountryData = {
          0: uniqueAllCountries,
          1: internationalCountries,
          2: domesticCountries
        };

        setCountryData(updatedCountryData);
        setIsLoader(false);
      } else {
        setError(worldWisePlacesRes.message);
        setIsLoader(false);
      }
    } catch (err) {
      setError(err.message);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getWorldWisePlacesApi();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const storedTripDetails = JSON.parse(sessionStorage.getItem('tripDetails'));
    if (selectedTourPlace) {
      const countryIndex = Object.values(countryData).findIndex((list) =>
        list.some((country) => {
          const countryNameLower = country?.name?.toLowerCase();
          const selectedPlaceLower = selectedTourPlace?.toLowerCase();
          return (
            selectedPlaceLower.includes(countryNameLower) ||
            countryNameLower.includes(selectedPlaceLower)
          );
        })
      );
      if (countryIndex !== -1) {
        setValue(countryIndex);
        setSelectedCountry(selectedTourPlace);
      }
    } else if (storedTripDetails && storedTripDetails.countryName) {
      const countryName = storedTripDetails.countryName;
      const countryIndex = Object.values(countryData).findIndex((list) =>
        list.some((country) => country.name === countryName)
      );
      if (countryIndex !== -1) {
        setValue(countryIndex);
        setSelectedCountry(countryName);
      }
    }
  }, [countryData]);

  useEffect(() => {
    if (countryData[value]) {
      const filteredList = countryData[value].filter(
        (country) =>
          country?.name && country?.name.toLowerCase().includes(searchQuery?.toLowerCase())
      );
      setCurrentCountryList(filteredList);
    }
  }, [value, countryData, searchQuery]);

  useEffect(() => {
    if (selectedCountry) {
      // Scroll to the selected country item
      const index = currentCountryList.findIndex((country) => country.name === selectedCountry);

      if (index !== -1 && listItemRefs.current[index]) {
        listItemRefs.current[index].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [selectedCountry, currentCountryList]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCountryClick = (country) => {
    setSelectedCountry(country.name);
    setOpenModal(true);
  };

  const handleTrip = (country) => {
    const tripDetails = {
      countryName: country.name,
      packageId: 'N/A'
    };
    setSelectedCountry(country.name);
    navigate('/vacation-spot', {
      state: {
        selectedCountry: country.name
      }
    });
    sessionStorage.setItem('tripDetails', JSON.stringify(tripDetails));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 80px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: '2%'
      }}
      className="location_tabs_main">
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          borderBottom: '3px solid rgba(0, 0, 0, 0.06)',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: 'white'
        }}>
        <Grid
          item
          sx={{
            width: '100%',
            display: 'flex !important',
            justifyContent: 'center !important'
          }}>
          <TabsContainer
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            sx={{ borderRadius: '5px' }}>
            <TabRoot
              value={0}
              label={<span className="location_tab_text">All</span>}
              className={`location_tab_text_1 ${value === 0 ? 'highlighted' : ''}`}
              sx={{ textTransform: 'none' }}
            />
            <TabRoot
              value={1}
              label={
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={international_map}
                    alt="international"
                    className="location_tab_img_international"
                  />
                  <span className="location_tab_text">International</span>
                </Box>
              }
              className={`location_tab_text_2 ${value === 1 ? 'highlighted' : ''}`}
              sx={{ textTransform: 'none' }}
            />
            <TabRoot
              value={2}
              label={
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={india_map} alt="international" className="location_tab_img_domestic" />
                  <span className="location_tab_text">Domestic</span>
                </Box>
              }
              className={`location_tab_text_3 ${value === 2 ? 'highlighted' : ''}`}
              sx={{ textTransform: 'none' }}
            />
          </TabsContainer>
        </Grid>
      </Grid>
      {currentCountryList.length > 0 && (
        <List className="countryList">
          {currentCountryList.map((country, index) => (
            <ListItem
              key={index}
              ref={(el) => (listItemRefs.current[index] = el)}
              className={`countryList ${
                selectedCountry === country?.name ||
                (selectedTourPlace &&
                  selectedTourPlace
                    .toLowerCase()
                    .split(' ')
                    .some((word) => country?.name.toLowerCase().includes(word)))
                  ? 'highlighted-country'
                  : ''
              }`}
              style={{ cursor: 'pointer' }}>
              <ListItemIcon>
                <OpenInNewOutlined onClick={() => handleCountryClick(country)} />
              </ListItemIcon>
              {isLoader ? (
                <Skeleton variant="text" width={200} height={30} />
              ) : (
                <ListItemText
                  primary={capitalizeFirstLetter(country.name)}
                  onClick={() => handleTrip(country)}
                  className="countryListText"
                />
              )}
              {country.tags && (
                <Grid container gap={10}>
                  {country.tags.map((tag, tagIndex) => (
                    <Grid
                      item
                      key={tagIndex}
                      className={`tag-badge ${tag.toLowerCase()}-tag`}
                      style={{ marginLeft: '1rem' }}>
                      {tag}
                    </Grid>
                  ))}
                </Grid>
              )}
            </ListItem>
          ))}
        </List>
      )}
      {openModal && (
        <IdealTripModal
          open={openModal}
          handleClose={handleCloseModal}
          selectedCountry={selectedCountry}
        />
      )}
    </Box>
  );
};

export default LocationTabs;
