import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loader = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }} pt={2}>
    <CircularProgress sx={{ zIndex: 999 }} disableShrink={true} />
  </Box>
);

export default Loader;
