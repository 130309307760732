import { Typography } from '@mui/material';
import React from 'react';

export const Title = ({ children, sx }, props) => (
  <Typography
    {...props}
    sx={{ ...sx }}
    fontWeight={{ xs: 700 }}
    fontSize={{ xs: 14, md: '20px' }}
    lineHeight={{ md: '24px' }}>
    {children}
  </Typography>
);
