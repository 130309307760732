/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './package-list.css';
import {
  Box,
  Pagination,
  Typography,
  useTheme,
  Tabs,
  Tab,
  useMediaQuery,
  Skeleton
} from '@mui/material';
import { api } from '../../APIs/api';
import { API_URL, BASE_URL } from '../../APIs/endpoint';
import {
  convertArrayToObjectArray,
  capitalizeFirstLetter,
  capitalizeFirstLetterWithAnd,
  formatThemePathSegmentsString,
  ConvertToRegionName,
  formatContinentName,
  ConvertSelectedBudgetsToNumbers,
  getUrlAfterDomain
  // MonthTabList
} from '../../Helper/global';
import {
  TourBudgetFilterINR,
  TourBudgetFilterUSD,
  TourBudgetFilterAED,
  TourBudgetFilterEUR,
  TourBudgetFilterAUD,
  TourBudgetFilterGBP,
  TourBudgetFilterSGD,
  TourBudgetFilterNZD,
  TourDurationFilter,
  AdventuresFilter,
  sortByFilterArray
} from '../../constants/package-listing';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchPackageListing } from '../../Redux/slices/packageListingSlice';
import { fetchMetaDataList } from '../../Redux/slices/metaDataSlice';
import { META_DATA_SLUG } from '../../APIs/metaDataSlug';
import { HelmetProvider } from 'react-helmet-async';
import moment from 'moment';
import CustomBreadcrumbs from '../../components/BreadCrumbs';
import CustomFilter from './CustomFilter';
import SideFilter from '../../components/SideFilter';
import ModeViewer from './ModeViewer';
import PackageCard from '../../components/PackageCard';
import Faq from '../../components/Faq';
import SliderRow from './SliderRow';
import ReviewCard from '../../components/home/ReviewCommonCard/ReviewCommonCard';
import HelmetComponent from '../../components/MetaDataComponent/Helmet';
import HomeIcon from '../../assets/common/breadcrumbs/home.svg';
import PackageCardSkeleton from '../../components/Loader/PackageCardSekeleton';

const PackageListing = () => {
  const prevPathSegments = useRef(null);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();
  const hasSetThemePathSegments = useRef(false);
  const { '*': dynamicPath } = useParams();
  const pathSegments = dynamicPath.split('/');
  const queryParams = new URLSearchParams(location.search);
  const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [tourCategory, setTourCategory] = useState('');
  const adventureTag =
    location?.state?.selectedAdventure || capitalizeFirstLetter(queryParams.get('adventure'));
  const [themeFilter, setThemeFilter] = useState([]);
  const dispatch = useDispatch();
  const defaultItemsPerPage = 10;
  const [selectedFilter, setSelectedFilter] = useState('');
  const [viewMode, setViewMode] = useState('list');
  // const [selectedTab, setSelectedTab] = useState(0);
  const [themeData, setThemeData] = useState();
  const [error, setError] = useState(null);
  const [sideFilterInitialItems, setSideFilterInitialItems] = useState({});
  const [reviewData, setReviewData] = useState();
  const [checkedItems, setCheckedItems] = useState({});
  const [paginatedPackages, setPaginatedPackages] = useState([]);
  const [delayedLabel, setDelayedLabel] = useState('');
  const [showDefault, setShowDefault] = useState(false);
  const [PackageListData, SetPackageListData] = useState([]);
  const packageListMetaData = useSelector((state) => state?.metaDataList?.metaDataList[0]);
  const [currentPage, setCurrentPage] = useState(() =>
    parseInt(sessionStorage.getItem('currentPage') || 1)
  );
  const [topDestinationData, setTopDestinationData] = useState([]);
  const [topLuxuryToursData, setTopLuxuryToursData] = useState([]);
  const [indianData, setIndianData] = useState([]);
  const [worldData, setWorldData] = useState([]);
  const [indianToursLabel, setIndianToursLabel] = useState('');
  const [worldToursLabel, setWorldToursLabel] = useState('');
  const [isFiltered, setIsFiltered] = useState(false);
  const [apiCallsMade, setApiCallsMade] = useState(false);
  const [destinationContinent, setDestinationContinent] = useState(null);
  // FAQ api data
  const [faqAccordionData, setFaqAccordionData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  // BreadCrumbs Click
  const [isBreadcrumbClicked, setIsBreadcrumbClicked] = useState(false);

  const budgetFilterMapping = {
    INR: TourBudgetFilterINR,
    USD: TourBudgetFilterUSD,
    AED: TourBudgetFilterAED,
    EUR: TourBudgetFilterEUR,
    AUD: TourBudgetFilterAUD,
    GBP: TourBudgetFilterGBP,
    SGD: TourBudgetFilterSGD,
    NZD: TourBudgetFilterNZD
  };

  // Convert various filter arrays to objects for easier processing
  const [tourBudgetData, setTourBudgetData] = useState(
    convertArrayToObjectArray(budgetFilterMapping['INR'])
  );
  const TourDurationData = convertArrayToObjectArray(TourDurationFilter);
  const AdventuresData = convertArrayToObjectArray(AdventuresFilter);
  const sortByFilterOptions = convertArrayToObjectArray(sortByFilterArray);

  // Loading states for each async task
  const [isPackagesLoading, setIsPackagesLoading] = useState(true);
  const [isThemeListLoading, setIsThemeListLoading] = useState(true);
  const [isReviewListLoading, setIsReviewListLoading] = useState(true);
  const [isTopDestinationLoading, setIsTopDestinationLoading] = useState(true);
  const [isTopLuxuryToursLoading, setIsTopLuxuryToursLoading] = useState(true);
  const [isContinentLoading, setIsContinentLoading] = useState(true);
  const [isMetaDataLoading, setIsMetaDataLoading] = useState(true);
  const [isFaqLoading, setIsFaqLoading] = useState(true);

  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [filteredPackages, setFilteredPackages] = useState([]);

  // Combined loading state
  const isLoader =
    isMetaDataLoading ||
    isPackagesLoading ||
    isThemeListLoading ||
    isReviewListLoading ||
    isTopDestinationLoading ||
    isTopLuxuryToursLoading ||
    isContinentLoading ||
    isFaqLoading;

  // START: Get & Set the choose currency from session storage
  const updateSelectedCurrency = () => {
    const currency = sessionStorage.getItem('selectedCurrency');
    if (currency) setSelectedCurrency(currency);
  };

  useEffect(() => {
    updateSelectedCurrency();
    const interval = setInterval(updateSelectedCurrency, 1000);
    return () => clearInterval(interval);
  }, []);
  // END:  Get & Set the choose currency from session storage

  // Update TourBudgetData whenever selectedCurrency changes
  useEffect(() => {
    if (selectedCurrency in budgetFilterMapping) {
      const newBudgetFilter = budgetFilterMapping[selectedCurrency];
      setTourBudgetData(convertArrayToObjectArray(newBudgetFilter));
    }
  }, [selectedCurrency]);

  const handleCheckedItemsChange = (items) => {
    setCheckedItems(items);
  };

  const getPackageListApi = async () => {
    try {
      setIsFiltered(true);
      const packagesListRes = await api.get(`${BASE_URL}${API_URL.GET_PACKAGE_LIST}`);
      const { data, message, status } = packagesListRes;
      if (status) {
        SetPackageListData(data);
      } else {
        setError(message);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const getThemeListApi = async () => {
    try {
      const themeRes = await api.get(`${BASE_URL}${API_URL.THEME_LIST}`);
      if (themeRes.status) {
        setThemeData(themeRes.data);
      } else {
        setError(themeRes.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsThemeListLoading(false);
    }
  };

  const getTopDestinationApi = async (continent) => {
    if (!continent) return;
    try {
      const topDestinationRes = await api.get(
        `${BASE_URL}${API_URL.GET_TOP_DESTINATION}?continent=${continent}`
      );
      if (topDestinationRes.status) {
        const destinationData = topDestinationRes.data;
        const modifiedDestinationData = {
          title: `Top ${formatContinentName(continent)} Destinations`,
          countries: destinationData.map((destination) => ({
            continent: destination?.continent,
            name: destination.country,
            img: destination.package_image
          }))
        };
        setTopDestinationData(modifiedDestinationData);
      } else {
        setError(topDestinationRes.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsTopDestinationLoading(false);
    }
  };

  const getTopLuxuryToursApi = async (continent) => {
    if (!continent) return;
    try {
      const topLuxuryToursRes = await api.get(
        `${BASE_URL}${API_URL.GET_LUXURY_TOURS}?continent=${continent}`
      );
      if (topLuxuryToursRes.status) {
        const LuxuryToursData = topLuxuryToursRes.data;
        const modifiedLuxuryTourData = {
          title: `Luxury ${formatContinentName(continent)} Tours`,
          countries: LuxuryToursData.map((tour) => ({
            continent: tour?.continent,
            name: tour.Country,
            img: tour.package_image
          }))
        };
        setTopLuxuryToursData(modifiedLuxuryTourData);
      } else {
        setError(topLuxuryToursRes.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsTopLuxuryToursLoading(false);
    }
  };

  const getContinentFilterListApi = async () => {
    try {
      const continentListRes = await api.get(`${BASE_URL}${API_URL.HEADER_MENU}`);
      if (continentListRes.status) {
        const { Domestic, International } = continentListRes && continentListRes.data;
        if (Domestic && Object.keys(Domestic).length > 0) {
          const stateNames = [];
          Object.keys(Domestic).forEach((region) => {
            Object.keys(Domestic[region]).forEach((state) => {
              stateNames.push(state);
            });
          });
          stateNames.sort();
          setIndianData(convertArrayToObjectArray(stateNames));
        }
        if (International && Object.keys(International).length > 0) {
          const continentsSet = new Set();
          const extractContinents = (data, set) => {
            Object.keys(data).forEach((continentKey) => {
              Object.values(data[continentKey]).forEach((country) => {
                if (country.continent) {
                  set.add(country.continent);
                }
              });
            });
          };
          extractContinents(International, continentsSet);
          const internationalArray = Array.from(continentsSet).sort();
          setWorldData(convertArrayToObjectArray(internationalArray));
        }
      } else {
        setError(continentListRes.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsContinentLoading(false);
    }
  };

  const getFqaListApi = async () => {
    try {
      setIsFaqLoading(true);
      const lastSegment = pathSegments.slice(-1)[0];
      const faqListRes = await api.get(
        `${BASE_URL}${API_URL.GET_FAQ_LIST}?destination=${
          capitalizeFirstLetterWithAnd(lastSegment) || 'Europe'
        }`
      );
      const { data, message, status } = faqListRes;
      if (status && Object.keys(data)?.length > 0) {
        const faqDestination = data?.Destination;
        const firstDestinationKey = Object.keys(faqDestination)[0];
        const QueAnsData = faqDestination[firstDestinationKey];

        if (QueAnsData?.length > 0) {
          const questionsAndAnswers = QueAnsData.map(({ question, answer }) => ({
            question,
            answer
          }));
          setFaqAccordionData(questionsAndAnswers);
        } else {
          setFaqAccordionData([]);
        }
      } else {
        setFaqAccordionData([]);
        setError(message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsFaqLoading(false);
    }
  };

  const handleViewModeChange = (event) => {
    setViewMode(event.target.value);
  };

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
  };

  // const handleTabChange = (event, newValue) => {
  //   setSelectedTab(newValue);
  // };

  const getReviewListApi = async () => {
    try {
      const reviewRes = await api.get(`${BASE_URL}${API_URL.REVIEW_LIST}`);
      if (reviewRes.status) {
        setReviewData(reviewRes.data);
      } else {
        setError(reviewRes.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsReviewListLoading(false);
    }
  };

  const setPathSegments = (pathSegments) => {
    if (pathSegments.length === 1) {
      setSelectedContinent('');
      setSelectedZone('');
      setSelectedState('');
      setSelectedCountry('');
      setSelectedCity('');
    } else if (pathSegments.length > 1 && pathSegments[0] === 'india') {
      setSelectedZone(ConvertToRegionName(pathSegments[1]));
      setSelectedState(capitalizeFirstLetterWithAnd(pathSegments[2]));
      setSelectedContinent('');
      setSelectedCountry('');
    } else if (pathSegments.length > 1 && pathSegments[0] === 'world') {
      setSelectedContinent(capitalizeFirstLetterWithAnd(pathSegments[1]));
      setSelectedCountry(capitalizeFirstLetterWithAnd(pathSegments[2]));
      setSelectedZone('');
      setSelectedState('');
    }
  };

  useEffect(() => {
    // Execute only if location.state is null and themeData is available
    if (location.state === null && themeData?.length > 0) {
      const lastSegment = formatThemePathSegmentsString(dynamicPath.split('/').pop());

      // Set themeFilterData based on the last segment of dynamicPath
      const themeFilterData = themeData.map((obj) => ({
        label: obj.theme,
        value: obj.theme,
        isSelected: obj.theme === lastSegment
      }));

      // Navigate to update the state with themeFilterData
      navigate(getUrlAfterDomain(dynamicPath), {
        state: { themeFilterData }
      });

      setThemePathSegments([dynamicPath.split('/')[0], lastSegment]);
      hasSetThemePathSegments.current = true; // Track that it has been set
    }
  }, [location.state, navigate, themeData, dynamicPath]);

  const setThemePathSegments = (pathSegments) => {
    if (pathSegments.length > 0 && pathSegments[0] === 'theme' && pathSegments[1]) {
      const formattedPathSegment = formatThemePathSegmentsString(pathSegments[1]);
      const updatedThemeFilter = themeData?.map((theme) => ({
        label: theme.theme,
        value: theme.theme,
        isSelected: theme.theme === formattedPathSegment
      }));

      setThemeFilter(updatedThemeFilter);
      setPathSegments([]);
    } else {
      const updatedThemeFilter = themeData?.map((theme) => ({
        label: theme.theme,
        value: theme.theme,
        isSelected: false
      }));
      setThemeFilter(updatedThemeFilter);
    }
  };

  useEffect(() => {
    dispatch(fetchMetaDataList({ slug: META_DATA_SLUG.PACKAGE_LITING_SLUG })).finally(() =>
      setIsMetaDataLoading(false)
    );
    getPackageListApi();
    // dispatch(fetchPackageListing()).finally(() => setIsPackagesLoading(false));
    getContinentFilterListApi();
    getThemeListApi();
    getReviewListApi();
    window.scrollTo(0, 0);
  }, [dispatch]);

  const getDestinationContinent = (checkedItems) => {
    const worldTours = checkedItems['World Tours'];
    if (!worldTours || typeof worldTours !== 'object') {
      return null;
    }
    const destinationContinent =
      Object.entries(worldTours).find(([continent, isChecked]) => isChecked)?.[0] || 'Asia';
    return destinationContinent;
  };

  useEffect(() => {
    const updatedDestinationContinent = getDestinationContinent(checkedItems);
    if (updatedDestinationContinent !== destinationContinent) {
      setDestinationContinent(updatedDestinationContinent);
      setApiCallsMade(false);
    }
    getFqaListApi();
  }, [checkedItems]);

  useEffect(() => {
    if (destinationContinent && !apiCallsMade) {
      getTopDestinationApi(destinationContinent);
      getTopLuxuryToursApi(destinationContinent);
      setApiCallsMade(true);
    }
  }, [destinationContinent, apiCallsMade, isFiltered]);

  useEffect(() => {
    if (prevPathSegments.current !== JSON.stringify(pathSegments)) {
      setPathSegments(pathSegments);
      setThemePathSegments(pathSegments);
      prevPathSegments.current = JSON.stringify(pathSegments);
    }

    // if (location.state === null) {
    //   setPathSegments(pathSegments);
    //   setThemePathSegments(pathSegments);
    // } else {
    if (location.state !== null) {
      if (location?.state?.continent) {
        setSelectedContinent(location.state.continent);
        setSelectedZone('');
        setSelectedState('');
      }
      if (location?.state?.country) {
        setSelectedCountry(location.state.country);
      }
      if (location?.state?.zone) {
        setSelectedZone(location.state.zone);
        setSelectedContinent('');
        setSelectedCountry('');
      }
      if (location?.state?.state) {
        setSelectedState(location.state.state);
      }
      if (location?.state?.city) {
        setSelectedCity(location.state.city);
      }
      if (location?.state?.tourCategory) {
        setTourCategory(location.state.tourCategory);
      }
      if (location?.state?.themeFilterData) {
        setThemeFilter(location?.state?.themeFilterData);
      }
    }

    if (adventureTag) {
      setPathSegments(pathSegments);
    }
    window.scrollTo(0, 0);
  }, [pathSegments, adventureTag]);

  const generateSideFilterInitialItems = (themeFilter) => {
    const initialItems = {
      'Tour Budget': {},
      'Tour Duration': {},
      'Indian Tours': {},
      'World Tours': {},
      'Tour Theme': {},
      Adventures: {}
    };
    tourBudgetData.forEach((item) => {
      initialItems['Tour Budget'][item.value] = false;
    });

    TourDurationData.forEach((item) => {
      initialItems['Tour Duration'][item.value] = false;
    });

    indianData?.forEach((item) => {
      initialItems['Indian Tours'][item.value] = false;
    });

    worldData?.forEach((item) => {
      initialItems['World Tours'][item.value] = false;
    });

    themeFilter?.forEach((item) => {
      initialItems['Tour Theme'][item.value] = false;
    });

    AdventuresData.forEach((item) => {
      initialItems['Adventures'][item.value] = false;
    });
    return initialItems;
  };

  useEffect(() => {
    setThemeFilter(
      themeData?.map((obj) => ({
        label: obj.theme,
        value: obj.theme,
        isSelected: false
      }))
    );
  }, [themeData]);

  useEffect(() => {
    if (themeFilter) {
      setSideFilterInitialItems(generateSideFilterInitialItems(themeFilter));
    }
  }, [themeFilter]);

  useEffect(() => {
    const indianTours = Object.keys(checkedItems['Indian Tours'] || {}).filter(
      (key) => checkedItems['Indian Tours'][key]
    );
    const worldTours = Object.keys(checkedItems['World Tours'] || {}).filter(
      (key) => checkedItems['World Tours'][key]
    );

    if (indianTours.length > 1) {
      setIndianToursLabel('Domestic');
    } else if (indianTours.length === 1) {
      setIndianToursLabel(indianTours[0]);
    } else {
      setIndianToursLabel('');
    }

    if (worldTours.length > 0) {
      setWorldToursLabel(worldTours[0]);
    } else {
      setWorldToursLabel('');
    }
  }, [checkedItems, location.state]);

  const packageBreadcrumbsData = [
    {
      label: <img src={HomeIcon} alt="home" width={16} height={16} />,
      path: '/'
    },
    {
      label:
        pathSegments.length === 1 && pathSegments[0] === ''
          ? 'World'
          : pathSegments.length === 1 && pathSegments[0] === 'india'
          ? 'India'
          : capitalizeFirstLetterWithAnd(pathSegments[0]),
      path: pathSegments[0] === 'india' ? '/explore/india' : '/explore'
    },
    ...(pathSegments.length > 1
      ? [
          {
            label: capitalizeFirstLetterWithAnd(pathSegments[1]),
            path:
              pathSegments.length > 2
                ? `/explore/${pathSegments[0]}/${pathSegments[1]}`
                : `/${pathSegments[0]}`
          }
        ]
      : []),
    ...(pathSegments.length > 2
      ? [
          {
            label:
              pathSegments[2] === 'united-states'
                ? 'United State of America'
                : capitalizeFirstLetterWithAnd(pathSegments[2])
                ? pathSegments[2] === 'united-states'
                  ? 'United State of America'
                  : capitalizeFirstLetterWithAnd(pathSegments[2])
                : indianToursLabel
                ? indianToursLabel
                : delayedLabel,
            path: `/${pathSegments.join('/')}`
          }
        ]
      : [])
  ];

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    sessionStorage.setItem('currentPage', page);
    window.scrollTo(0, 0);
  };

  // Filtered based on Theme
  const getSelectedTourThemes = useMemo(
    () =>
      Object.keys(checkedItems['Tour Theme'] || {}).filter(
        (key) => checkedItems['Tour Theme'][key]
      ),
    [checkedItems]
  );

  const filterPackagesByTheme = (packages, selectedThemes) => {
    if (!selectedThemes.length) return packages;
    return packages.filter((pkg) => selectedThemes.some((theme) => pkg?.theme?.includes(theme)));
  };

  // Filtered based on Tour Duration
  const getSelectedTourDurations = useMemo(
    () =>
      Object.keys(checkedItems['Tour Duration'] || {}).filter(
        (key) => checkedItems['Tour Duration'][key]
      ),
    [checkedItems]
  );

  const filterPackagesByDuration = (packages, selectedDurations) => {
    if (!selectedDurations.length) return packages;

    return packages.filter((pkg) => {
      const days = pkg?.number_of_days;
      if (days == null) return false;
      return selectedDurations.some((range) => {
        switch (range) {
          case '3 - 7 Days':
            return days >= 3 && days <= 7;
          case '7 - 11 Days':
            return days > 7 && days <= 11;
          case '11 - 15 Days':
            return days > 11 && days <= 15;
          case 'Above 15 Days':
            return days > 15;
          default:
            return false;
        }
      });
    });
  };

  // Filtered based on Tour Budget
  const getSelectedTourBudgets = useMemo(
    () =>
      Object.keys(checkedItems['Tour Budget'] || {}).filter(
        (key) => checkedItems['Tour Budget'][key]
      ),
    [checkedItems]
  );

  const filterPackagesByBudget = (packages, selectedBudgets, selectedCurrency) => {
    if (!selectedBudgets.length) return packages;
    const numericBudgets = ConvertSelectedBudgetsToNumbers(selectedBudgets);
    return packages.filter((pkg) => {
      const price = pkg[`${selectedCurrency.toLowerCase()}_price`];
      if (price == null) return false;

      return numericBudgets.some(({ min, max }) =>
        max === Infinity ? price > min : price >= min && price <= max
      );
    });
  };

  // Filtered based on Adventures
  const getSelectedAdventures = useMemo(
    () =>
      Object.keys(checkedItems['Adventures'] || {}).filter(
        (key) => checkedItems['Adventures'][key]
      ),
    [checkedItems]
  );

  const filterPackagesByAdventure = (packages, selectedAdventure) => {
    if (!selectedAdventure.length) return packages;
    return packages.filter((pkg) =>
      selectedAdventure.some((adventure) => pkg?.adventure_theme_tags?.includes(adventure))
    );
  };

  // Filtered based on India
  const getSelectedIndianState = useMemo(
    () =>
      Object.keys(checkedItems['Indian Tours'] || {}).filter(
        (key) => checkedItems['Indian Tours'][key]
      ),
    [checkedItems, location.state]
  );

  const filterPackagesByIndianState = (packages, filteredState) => {
    const indianPackages = packages.filter((pkg) => pkg.country === 'India');
    if (filteredState?.length === 1) {
      const isStateValid = selectedState === filteredState[0];
      let filteredByState;
      if (isStateValid) {
        filteredByState = indianPackages.filter((pkg) => pkg.state === selectedState);
        let filteredByCity = filteredByState;
        if (selectedCity !== '') {
          filteredByCity = filteredByState.filter((pkg) =>
            pkg.places.some((place) => place?.Place.toLowerCase() === selectedCity.toLowerCase())
          );
        }
        const uniquePackages = Array.from(
          new Map(filteredByCity.map((pkg) => [pkg._id.$oid, pkg])).values()
        );
        return uniquePackages;
      } else {
        // setSelectedState('');
        setSelectedCity('');
        filteredByState = indianPackages.filter((pkg) => pkg.state === filteredState[0]);
      }
      const uniquePackages = Array.from(
        new Map(filteredByState.map((pkg) => [pkg._id.$oid, pkg])).values()
      );
      return uniquePackages;
    } else {
      const filteredByStates = indianPackages.filter((pkg) => filteredState.includes(pkg.state));
      // setSelectedState('');
      setSelectedCity('');
      const uniquePackages = Array.from(
        new Map(filteredByStates.map((pkg) => [pkg._id.$oid, pkg])).values()
      );
      return uniquePackages;
    }
  };

  // Filter based on World
  const getSelectedWorldContinent = useMemo(
    () =>
      Object.keys(checkedItems['World Tours'] || {}).filter(
        (key) => checkedItems['World Tours'][key]
      ),
    [checkedItems]
  );

  const filterPackagesByWorldContinent = (packages, filteredContinent) => {
    let filteredByContinent;

    const travelledCountriesMap = packages.reduce((acc, pkg) => {
      if (pkg.travelled_countries) {
        const countries = pkg.travelled_countries
          .split(',')
          .map((country) => country.trim().toLowerCase());
        acc[pkg._id.$oid] = countries;
      }
      return acc;
    }, {});

    if (filteredContinent?.length > 0 && tourCategory === 'International') {
      filteredByContinent = packages?.filter(
        (pkg) => pkg?.tour_category === 'International' && pkg?.continent === filteredContinent[0]
      );
      const uniquePackages = Array.from(
        new Map(filteredByContinent.map((pkg) => [pkg._id.$oid, pkg])).values()
      );
      return uniquePackages;
    } else {
      const isContinentValid = selectedContinent === filteredContinent[0];
      if (isContinentValid) {
        filteredByContinent = packages?.filter((pkg) => pkg?.continent === filteredContinent[0]);
        let filteredByCountry = filteredByContinent;
        if (selectedCountry !== '') {
          filteredByCountry = filteredByContinent?.filter((pkg) => {
            const packageCountry = pkg?.country.toLowerCase();
            if (packageCountry === selectedCountry.toLowerCase()) {
              return true;
            }
            const travelledCountries = travelledCountriesMap[pkg._id.$oid] || [];
            return travelledCountries.includes(selectedCountry.toLowerCase());
          });
        }

        // if (selectedCountry !== '') {
        //   filteredByCountry = filteredByContinent?.filter(
        //     (pkg) => pkg?.country.toLowerCase() === selectedCountry.toLowerCase()
        //   );
        // }
        // let filteredByState = filteredByCountry;
        // if (selectedState !== '') {
        //   filteredByState = filteredByState?.filter(
        //     (pkg) => pkg?.state?.toLowerCase() === selectedState?.toLowerCase()
        //   );
        // }
        // let filteredByCity = filteredByState;
        // if (selectedCity !== '') {
        //   filteredByCity = filteredByState?.filter((pkg) =>
        //     pkg?.places?.some(
        //       (place) => place?.Place?.toLowerCase() === selectedCity?.toLowerCase()
        //     )
        //   );
        // }
        const uniquePackages = Array.from(
          new Map(
            (selectedCountry ? filteredByCountry : filteredByContinent)?.map((pkg) => [
              pkg._id.$oid,
              pkg
            ])
          ).values()
        );
        return uniquePackages;
      } else {
        //setSelectedCountry('');
        filteredByContinent = packages?.filter((pkg) => pkg?.continent === filteredContinent[0]);
      }
      const uniquePackages = Array.from(
        new Map(filteredByContinent.map((pkg) => [pkg._id.$oid, pkg])).values()
      );
      return uniquePackages;
    }
  };

  // Sort By selectedFilter
  const sortPackages = (packages) => {
    if (!Array.isArray(packages)) {
      return [];
    }
    if (!selectedFilter) return packages;

    const getPriceByCurrency = (pkg) => {
      const price = {
        INR: pkg.inr_price,
        USD: pkg.usd_price,
        EUR: pkg.eur_price,
        AED: pkg.aed_price,
        AUD: pkg.aud_price,
        GBP: pkg.gbp_price,
        SGD: pkg.sgd_price,
        NZD: pkg.nzd_price,
        default: pkg.minimum_price
      }[selectedCurrency];
      return price || pkg.minimum_price;
    };

    let sortedPackages;

    switch (selectedFilter) {
      case 'Price Low to High':
        sortedPackages = [...packages].sort(
          (a, b) => getPriceByCurrency(a) - getPriceByCurrency(b)
        );
        break;
      case 'Price High to Low':
        sortedPackages = [...packages].sort(
          (a, b) => getPriceByCurrency(b) - getPriceByCurrency(a)
        );
        break;
      case 'New Packages':
        sortedPackages = [...packages].sort((a, b) => new Date(b.modified) - new Date(a.modified));
        break;
      case 'Popular':
        sortedPackages = [...packages].sort((a, b) => b.popularity_score - a.popularity_score);
        break;
      default:
        return packages;
    }
    return sortedPackages;
  };

  const filterPackagesByValidity = (packages) => {
    const today = moment();
    return packages.filter((pkg) => {
      const validityTo = moment(pkg.validity_to, 'YYYY-MM-DD', true);
      if (!validityTo.isValid()) {
        return false;
      }
      return validityTo.isSameOrAfter(today, 'day');
    });
  };

  useEffect(() => {
    const filterPackages = () => {
      setIsFiltered(true);
      setTimeout(() => {
        let filteredByBudget =
          getSelectedTourBudgets.length > 0
            ? filterPackagesByBudget(PackageListData, getSelectedTourBudgets, selectedCurrency)
            : PackageListData;

        let filteredByDuration =
          getSelectedTourDurations.length > 0
            ? filterPackagesByDuration(filteredByBudget, getSelectedTourDurations)
            : filteredByBudget;

        let filteredByTheme =
          getSelectedTourThemes.length > 0
            ? filterPackagesByTheme(filteredByDuration, getSelectedTourThemes)
            : filteredByDuration;

        let filteredByAdventure =
          getSelectedAdventures.length > 0
            ? filterPackagesByAdventure(filteredByTheme, getSelectedAdventures)
            : filteredByTheme;

        let filteredByWorldContinent =
          getSelectedWorldContinent?.length > 0
            ? filterPackagesByWorldContinent(filteredByAdventure, getSelectedWorldContinent)
            : filteredByAdventure;

        let filteredByIndianState =
          getSelectedIndianState.length > 0
            ? filterPackagesByIndianState(filteredByWorldContinent, getSelectedIndianState)
            : filteredByWorldContinent;

        let filteredByValidity = filterPackagesByValidity(filteredByIndianState);
        setFilteredPackages(sortPackages(filteredByValidity));
      }, 800);
    };
    filterPackages();
  }, [
    PackageListData,
    getSelectedTourThemes,
    getSelectedTourDurations,
    getSelectedTourBudgets,
    getSelectedAdventures,
    getSelectedIndianState,
    getSelectedWorldContinent,
    selectedFilter,
    checkedItems,
    selectedCurrency,
    selectedContinent,
    selectedCountry,
    selectedState
  ]);

  useEffect(() => {
    setPaginatedPackages([]);
    let sortedPackages = [];
    if (selectedFilter) {
      sortedPackages = filteredPackages;
    } else {
      sortedPackages = [...filteredPackages].sort((a, b) =>
        a.package_name.localeCompare(b.package_name)
      );
    }
    const start = (currentPage - 1) * defaultItemsPerPage;
    const end = currentPage * defaultItemsPerPage;
    setPaginatedPackages(sortedPackages.slice(start, end));
    setIsPackagesLoading(false);
    setIsFiltered(false);
  }, [filteredPackages, currentPage, defaultItemsPerPage]);

  useEffect(() => {
    if (!paginatedPackages || paginatedPackages.length === 0) {
      const timer = setTimeout(() => {
        setShowNoDataMessage(true);
      }, 1500);
      return () => clearTimeout(timer);
    } else {
      setShowNoDataMessage(false);
    }
  }, [paginatedPackages]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!worldToursLabel && !indianToursLabel) {
        setDelayedLabel('All');
        setShowDefault(true);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [location.state, worldToursLabel, indianToursLabel]);

  return (
    <Box className="package-list-container">
      <HelmetProvider>
        <HelmetComponent metaData={packageListMetaData} />
      </HelmetProvider>
      <Box
        sx={{
          bgcolor: '#F5F5F5',
          overflow: 'hidden',
          padding: { xs: 2, lg: 0 }
        }}>
        <Box mt={{ md: 5 }} maxWidth="1196px" mx="auto" pb={4}>
          <CustomBreadcrumbs
            breadcrumbsData={packageBreadcrumbsData}
            onBreadcrumbClick={() => setIsBreadcrumbClicked(true)}
          />
          <Box mt={{ lg: '32px' }}>
            <Box
              width="100%"
              display={{ sm: 'flex', md: 'flex' }}
              alignItems="center"
              justifyContent="space-between">
              <Typography
                color="#000000E5"
                lineHeight="26px"
                fontFamily="gothamBold"
                fontSize="18px"
                mt={{ xs: 3 }}>
                {packageBreadcrumbsData.length > 0 ? (
                  `${packageBreadcrumbsData[packageBreadcrumbsData.length - 1].label} Tour Packages`
                ) : showDefault ? (
                  'All Tour Packages'
                ) : (
                  <Skeleton width={200} />
                )}
              </Typography>
              <Box display="flex" alignItems="center" gap="6px">
                <CustomFilter
                  sortByFilterOptions={sortByFilterOptions}
                  onChange={handleFilterChange}
                />
                <ModeViewer viewMode={viewMode} handleViewModeChange={handleViewModeChange} />
              </Box>
            </Box>
            {/* <Box
              className="calender-tabs"
              sx={{
                width: '100%',
                bgcolor: '#fff',
                borderRadius: '8px',
                mt: { xs: 1, md: 2 },
                display: { xs: 'none', lg: 'block' },
                '& .Mui-selected': {
                  color: '#27306B !important',
                  fontWeight: 600
                },
                '& .MuiTabs-flexContainer': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }
              }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="month tabs"
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: 1,
                  maxWidth: '900px',
                  ml: 'auto',
                  pl: '20px'
                }}>
                {MonthTabList.map((month, index) => (
                  <Tab
                    key={index}
                    label={month.title}
                    sx={{
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: '16px',
                      fontFamily: 'inherit',
                      minWidth: '70px',
                      padding: 0,
                      color: '#00000080 !important',
                      '&.Mui-selected': {
                        borderBottom: '1px solid #27306B !important'
                      }
                    }}
                  />
                ))}
              </Tabs>
            </Box> */}

            <Box alignItems="start" gap="26px" sx={{ display: { md: 'flex' }, mt: { xs: 3 } }}>
              <SideFilter
                tourBudget={tourBudgetData}
                tourDuration={TourDurationData}
                indianData={indianData}
                worldData={worldData}
                themeFilter={themeFilter}
                adventureTag={adventureTag}
                adventuresData={AdventuresData}
                choosedCurrency={selectedCurrency}
                selectedCountry={selectedCountry}
                sideFilterInitialItems={sideFilterInitialItems}
                selectedContinent={capitalizeFirstLetter(selectedContinent)}
                selectedZone={capitalizeFirstLetter(selectedZone)}
                selectedState={capitalizeFirstLetterWithAnd(selectedState)}
                tourCategory={tourCategory}
                isIndianCheckbox={true}
                isThemeCheckbox={false}
                isAdventureCheckbox={false}
                onCheckedItemsChange={handleCheckedItemsChange}
                isBreadcrumbClicked={isBreadcrumbClicked}
                setIsBreadcrumbClicked={setIsBreadcrumbClicked}
                pathSegments={pathSegments}
              />

              <Box
                height="100%"
                display="flex"
                gap="26px"
                flexWrap="wrap"
                flex={1}
                mt={0}
                justifyContent={
                  showNoDataMessage && paginatedPackages?.length === 0 && !isFiltered
                    ? 'center'
                    : 'flex-start'
                }>
                {isFiltered
                  ? Array.from({ length: 4 }).map((_, key) => <PackageCardSkeleton key={key} />)
                  : paginatedPackages?.map((packageData, index) => (
                      <PackageCard
                        key={index}
                        mode={viewMode}
                        tourPackage={packageData}
                        isLoader={isFiltered}
                        selectedCurrency={selectedCurrency}
                      />
                    ))}
                {showNoDataMessage && paginatedPackages?.length === 0 && !isFiltered && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height={400}
                    sx={{ mt: 5 }}>
                    <Typography
                      variant="h6"
                      component="span"
                      color="#00000080"
                      fontWeight={500}
                      textAlign="center">
                      {'Coming Soon...'}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            {filteredPackages?.length > 10 && (
              <Box mt={3} display="flex" width="100%" justifyContent="end">
                <Pagination
                  count={Math.ceil(filteredPackages?.length / defaultItemsPerPage) || 0}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                  disabled={filteredPackages.length < defaultItemsPerPage}
                />
              </Box>
            )}
          </Box>
          {/* {topDestinationData?.countries?.length > 0 && (
            <Box
              mt={{ xs: 4.5, lg: 10 }}
              display="flex"
              flexDirection="column"
              gap={{ xs: '28px', lg: 7 }}
              sx={{
                '& .slick-track': { pb: '5px' }
              }}>
              {isTopDestinationLoading ? (
                <Box display="flex" gap={2}>
                  {[...Array(4)].map((_, index) => (
                    <Skeleton key={index} variant="rectangular" width={245} height={230} />
                  ))}
                </Box>
              ) : (
                topDestinationData &&
                Object.keys(topDestinationData).length > 0 && (
                  <Box className="card-image-corner-container">
                    <SliderRow
                      data={topDestinationData?.countries}
                      title={topDestinationData?.title}
                    />
                  </Box>
                )
              )}
            </Box>
          )}

          {topLuxuryToursData?.countries?.length > 0 && (
            <Box
              mt={{ xs: 4.5, lg: 10 }}
              display="flex"
              flexDirection="column"
              gap={{ xs: '28px', lg: 7 }}
              sx={{
                '& .slick-track': { pb: '5px' }
              }}>
              {isTopLuxuryToursLoading ? (
                <Box display="flex" gap={2}>
                  {[...Array(4)].map((_, index) => (
                    <Skeleton key={index} variant="rectangular" width={245} height={230} />
                  ))}
                </Box>
              ) : (
                topLuxuryToursData &&
                Object.keys(topLuxuryToursData).length > 0 && (
                  <Box className="card-image-corner-container">
                    <SliderRow
                      data={topLuxuryToursData?.countries}
                      title={topLuxuryToursData?.title}
                    />
                  </Box>
                )
              )}
            </Box>
          )} */}

          {reviewData?.length > 0 && (
            <Box className="package-list-review-section" sx={{ width: '100%' }} mt={6}>
              <ReviewCard reviewData={reviewData} isLoader={isReviewListLoading} isListing={true} />
            </Box>
          )}

          {faqAccordionData?.length > 0 && (
            <Box display="grid" gap="8px" mt="20px" justifyContent="center">
              <Typography
                fontFamily="gothamBold"
                sx={{ pb: { xs: '22px', lg: '32px' } }}
                fontSize={{ xs: '18px', lg: '28px' }}
                lineHeight={{ xs: '26px', lg: '62px' }}
                textAlign={{ lg: 'center' }}>
                {!isLargeScreen
                  ? `${
                      capitalizeFirstLetterWithAnd(pathSegments.slice(-1)[0]) || 'Europe'
                    } Frequently Asked Questions`
                  : `${capitalizeFirstLetterWithAnd(pathSegments.slice(-1)[0]) || 'Europe'} FAQ`}
              </Typography>
              <Box className="accordion-container faq-accordion">
                {faqAccordionData.map((data, index) => (
                  <Faq key={index} id={index} title={data.question} content={data.answer} />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PackageListing;
