/* eslint-disable no-unused-vars */
import DessertIcon from '../assets/Home/Tailored_Adventures/dessert.webp';
import HillStationIcon from '../assets/Home/Tailored_Adventures/hill_station.webp';
import TrekkingIcon from '../assets/Home/Tailored_Adventures/trekking.webp';
import BeachIcon from '../assets/Home/Tailored_Adventures/Beach.webp';
import RiverCruiseIcon from '../assets/Home/Tailored_Adventures/river_cruise.webp';
import SafariIcon from '../assets/Home/Tailored_Adventures/Safari.webp';
import SufringIcon from '../assets/Home/Tailored_Adventures/Sufring.webp';
import SailingIcon from '../assets/Home/Tailored_Adventures/Sailing.webp';

export const AdventuresTabMenu = [
  {
    tab_img: DessertIcon,
    tab_txt: 'Desert'
  },
  {
    tab_img: HillStationIcon,
    tab_txt: 'Hill Stations'
  },
  // {
  //   tab_img: TrekkingIcon,
  //   tab_txt: 'Hiking & Trekking'
  // },
  {
    tab_img: BeachIcon,
    tab_txt: 'Beach'
  },
  // {
  //   tab_img: RiverCruiseIcon,
  //   tab_txt: 'River Cruise'
  // },
  {
    tab_img: SafariIcon,
    tab_txt: 'Safari'
  }
  // {
  //   tab_img: SufringIcon,
  //   tab_txt: 'Surfing'
  // },
  // {
  //   tab_img: SailingIcon,
  //   tab_txt: 'Sailing'
  // }
];
