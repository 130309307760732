import React from 'react';
import { Icon, MenuItem, Select } from '@mui/material';
import { GridViewRounded, ListRounded } from '@mui/icons-material';

const ModeViewer = ({ handleViewModeChange, viewMode }) => (
  <Select
    sx={{
      display: { xs: 'none', md: 'flex' },
      color: '#27306B',
      height: '14px',
      '& .MuiOutlinedInput-input': {
        border: 'none'
      },
      '& .MuiSelect-icon': {
        display: 'none'
      },
      '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' }
    }}
    startAdornment={
      <Icon>{viewMode === 'list' ? <ListRounded /> : <GridViewRounded fontSize="small" />}</Icon>
    }
    value={viewMode}
    onChange={handleViewModeChange}>
    <MenuItem
      value="list"
      sx={{
        fontSize: '14px',
        fontFamily: 'gothamBook',
        lineHeight: '16px',
        color: '#27306B'
      }}>
      Grid View
    </MenuItem>
    <MenuItem
      value="grid"
      sx={{
        fontSize: '14px',
        fontFamily: 'gothamBook',
        lineHeight: '16px',
        color: '#27306B'
      }}>
      List View
    </MenuItem>
  </Select>
);

export default ModeViewer;
